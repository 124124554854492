// src/context/ReservationContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

// Define the Reservation interface
export interface CheckInItem {
  reservationId: string;
  userName: string;
  userId: string;
  noOfPeople: number;
}

// Define the context properties
interface CheckInItemContextProps {
  checkIns: CheckInItem[];
  setCheckIns: (checkIns: CheckInItem[]) => void;
  lastRefreshTime?: string;
  setLastRefreshTime: (refreshTime: string) => void;
  isRefreshing: boolean;
  setIsRefreshing: (isRefreshing: boolean) => void;
}

export const CheckInItemContext = createContext<
  CheckInItemContextProps | undefined
>(undefined);

interface CheckInItemProviderProps {
  children: ReactNode;
}

export const CheckInItemProvider: React.FC<CheckInItemProviderProps> = ({
  children,
}) => {
  const [checkIns, setCheckIns] = useState<CheckInItem[]>([
    // Add initial sample data as needed
  ]);
  const [lastRefreshTime, setLastRefreshTime] = useState<string | undefined>(
    undefined,
  );
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

  const value = {
    checkIns,
    setCheckIns,
    lastRefreshTime,
    setLastRefreshTime,
    isRefreshing,
    setIsRefreshing,
  };

  return (
    <CheckInItemContext.Provider value={value}>
      {children}
    </CheckInItemContext.Provider>
  );
};
