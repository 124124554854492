import React from 'react';

import ValetParkingPanel from '../components/ValetParking';
import { RestaurantHeader } from '../components/Hamburger/RestaurantHeader';

const ValetListing: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      {/* Header */}
      <RestaurantHeader />
      {/* Content */}
      <ValetParkingPanel />
    </div>
  );
};

export default ValetListing;
