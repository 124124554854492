// src/context/TableContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import { AuthContext } from './AuthContext';
import { API_GATEWAY_ENDPOINT, RESTAURANT_ROLE } from '../utilities/Constants';

// Define types for the table data
export interface Table {
  tableId: string;
  minimumPeopleAllowed: number;
  maximumPeopleAllowed: number;
}

interface TableContextProps {
  availableTables: Table[];
  setAvailableTables: (tables: Table[]) => void;
  addTable: (table: Table) => void;
  lastRefreshTime?: string;
  isRefreshing: boolean;
}

export const TableContext = createContext<TableContextProps | undefined>(
  undefined,
);

interface TableProviderProps {
  children: ReactNode;
}

export const TableProvider: React.FC<TableProviderProps> = ({ children }) => {
  const [availableTables, setAvailableTables] = useState<Table[]>([]);
  const [lastRefreshTime, setLastRefreshTime] = useState<string | undefined>(
    undefined,
  );
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

  const authContext = useContext(AuthContext);

  const addTable = (table: Table) => {
    setAvailableTables([table, ...availableTables]);
  };

  const getAvailableTables = async () => {
    setIsRefreshing(true); // Show loading indicator

    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllAvailableTables`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch tables.');
      }

      const tableList = (await response.json()).tableList;

      setAvailableTables(tableList);
    } catch (error) {
      console.error('Error fetching tables:', error);
    } finally {
      setIsRefreshing(false); // Hide loading indicator
      setLastRefreshTime(new Date().toLocaleTimeString()); // Update last refresh time
    }
  };

  useEffect(() => {
    if (
      authContext?.isAuthenticated &&
      authContext.user?.userType === RESTAURANT_ROLE
    ) {
      // Fetch available tables initially
      getAvailableTables();

      // Set an interval to fetch tables every 5 seconds
      const interval = setInterval(() => {
        getAvailableTables();
      }, 5000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [authContext?.isAuthenticated]);

  const value = {
    availableTables,
    setAvailableTables,
    addTable,
    lastRefreshTime,
    isRefreshing,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};
