import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { AuthContext } from './AuthContext';
import { RestaurantContext } from './RestaurantContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';

// Define the Reservation interface
export interface ValetParking {
  restaurantId: string;
  customerId: string;
  status: string;
  createdAt: string;
  uniqueValetParkingCode: string;
  restaurantName: string;
  customerName: string;
}

export interface PastValetParking {
  restaurantId: string;
  createdAt: string;
  updatedAt: string;
  restaurantName: string;
  customerName: string;
  acceptValetAccountId: string;
  acceptValetName: string;
  releaseValetAccountId: string;
  releaseValetName: string;
}

// Define the context properties
interface ValetParkingContextProps {
  valetParkings: ValetParking[];
  setValetParkings: (valetParkings: ValetParking[]) => void;
  lastRefershTime?: string;
  isRefreshing: boolean;
  pastValetParkings: PastValetParking[]; // Add pastValetParkings to the context
}

export const ValetParkingContext = createContext<
  ValetParkingContextProps | undefined
>(undefined);

interface ValetParkingsProviderProps {
  children: ReactNode;
}

export const ValetParkingProvider: React.FC<ValetParkingsProviderProps> = ({
  children,
}) => {
  const [valetParkings, setValetParkings] = useState<ValetParking[]>([
    // Add initial sample data as needed
  ]);
  const [lastRefershTime, setLastRefershTime] = useState<string | undefined>(
    undefined,
  );
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

  const authContext = useContext(AuthContext);
  const restaurantContext = useContext(RestaurantContext);
  const [pastValetParkings, setPastValetParkings] = useState<
    PastValetParking[]
  >([]);

  useEffect(() => {
    if (authContext?.isAuthenticated && restaurantContext?.restaurant) {
      // Fetch reservations initially
      getValetParkings();
      fetchPastValetParkings(); // Initial fetch of past valet parkings

      // Set an interval to fetch reservations every 5 seconds
      const interval = setInterval(() => {
        getValetParkings();
        fetchPastValetParkings(); // Refresh past valet parkings every 5 seconds
      }, 5000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [authContext?.isAuthenticated, restaurantContext?.restaurant]);

  const getValetParkings = async () => {
    setIsRefreshing(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchValetParkingInfo `,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch valet parkings list');
      }

      const valetParkingsList = (await response.json()).valetParkingList;
      setValetParkings(valetParkingsList);
    } catch (error) {
      console.error('Error fetching valet parkings:', error);
    } finally {
      setIsRefreshing(false); // Hide loading indicator
      setLastRefershTime(new Date().toLocaleTimeString()); // Update last refresh time
    }
  };

  const fetchPastValetParkings = async () => {
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllPastValetParking`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch past valet parkings');
      }

      const data = await response.json();
      setPastValetParkings(data.pastValetParkingList);
    } catch (error) {
      console.error('Error fetching past valet parkings:', error);
    }
  };

  const value = {
    valetParkings,
    setValetParkings,
    lastRefershTime,
    isRefreshing,
    pastValetParkings, // Include pastValetParkings in the context value
  };

  return (
    <ValetParkingContext.Provider value={value}>
      {children}
    </ValetParkingContext.Provider>
  );
};
