import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_GATEWAY_ENDPOINT } from '../../../utilities/Constants';
import {
  Restaurant,
  RestaurantContext,
} from '../../../context/RestaurantContext';
import { AuthContext } from '../../../context/AuthContext';
import merchantlogo from '../../../assets/merchantLogo.png';
import RestaurantMenuLayout from '../../../layout/RestaurantMenuLayout';
import { useNavigate } from 'react-router-dom';

// ------------------------------
// COMPONENT
// ------------------------------
const RestaurantProfileForm: React.FC = () => {
  const restaurantContext = useContext(RestaurantContext);
  const authContext = useContext(AuthContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localRestaurant, setLocalRestaurant] = useState<Restaurant | null>(
    restaurantContext?.restaurant || null,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (restaurantContext?.restaurant) {
      setLocalRestaurant({ ...restaurantContext.restaurant });
    }
  }, [restaurantContext?.restaurant]);

  async function handleSaveChanges(restaurant: Restaurant) {
    if (!restaurant) {
      restaurant = localRestaurant!;
    }
    if (restaurant.maxRetries < 0 || restaurant.maxWaitingTimeInMinutes < 0) {
      toast.error('Invalid number submission for form fields.');
      return;
    }
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateRestaurantInfo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: `Bearer ${authContext?.user?.authToken || ''}`,
          },
          body: JSON.stringify({ ...restaurant }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to update restaurant info');
      }

      toast.success('Restaurant information updated successfully!');
      restaurantContext?.setRestaurant(restaurant);
    } catch (error) {
      console.error(
        'An error occurred while updating restaurant info: ',
        error,
      );
      toast.error('An error occurred while updating restaurant info.');
    }
  }

  // ---------- Field & Toggle Handlers ----------
  const handleFieldChange = (
    field: keyof Restaurant,
    value: number | string,
  ) => {
    if (!localRestaurant) return;
    setLocalRestaurant((prev) => ({ ...prev!, [field]: value }));
  };

  const handleStatusToggle = () => {
    if (!localRestaurant) return;
    const newStatus = !localRestaurant.isRestaurantOpen;
    const newRestaurant = { ...localRestaurant, isRestaurantOpen: newStatus };
    setLocalRestaurant(newRestaurant);
    handleSaveChanges(newRestaurant);
  };

  const handleOnlineToggle = () => {
    if (!localRestaurant) return;
    const newStatus = !localRestaurant.takesOnlineReservations;
    const newRestaurant = {
      ...localRestaurant,
      takesOnlineReservations: newStatus,
    };
    setLocalRestaurant(newRestaurant);
    handleSaveChanges(newRestaurant);
  };

  // ---------- RENDER ----------
  if (!localRestaurant) {
    return (
      <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500" />
    );
  }

  return (
    <>
      <RestaurantMenuLayout />

      <img
        src={merchantlogo}
        className="size-56 self-center max-sm:size-36"
        alt="Merchant Logo"
      />
      <div className="mt-24 text-4xl font-bold">Restaurant Profile</div>
      <div className="mt-8 space-y-6">
        {/* ---------- Status Toggle ----------- */}
        <div className="flex items-center">
          <label
            htmlFor="waiting-toggle"
            className="flex w-full cursor-pointer items-center justify-between"
          >
            <span className="mr-2 text-lg font-bold">Status</span>
            <div className="flex items-center">
              <span className="mr-2 text-lg">Closed</span>
              <div className="relative">
                <input
                  id="waiting-toggle"
                  type="checkbox"
                  className="sr-only"
                  checked={localRestaurant.isRestaurantOpen}
                  onChange={handleStatusToggle}
                />
                <div
                  className={`block h-6 w-10 rounded-full ${
                    localRestaurant.isRestaurantOpen
                      ? 'bg-green-500'
                      : 'bg-gray-400'
                  }`}
                ></div>
                <div
                  className={`absolute left-0.5 top-0.5 size-5 rounded-full bg-white transition-transform ${
                    localRestaurant.isRestaurantOpen ? 'translate-x-4' : ''
                  }`}
                ></div>
              </div>
              <span className="pl-2 text-lg">Open</span>
            </div>
          </label>
        </div>

        {/* ---------- Online Reservations Toggle ----------- */}
        <div className="flex items-center">
          <label
            htmlFor="enabled-toggle"
            className="flex w-full cursor-pointer items-center justify-between"
          >
            <span className="mr-2 text-lg font-bold">Online Reservations</span>
            <div className="flex items-center">
              <span className="mr-2 text-lg">Closed</span>
              <div className="relative">
                <input
                  id="enabled-toggle"
                  type="checkbox"
                  className="sr-only"
                  checked={localRestaurant.takesOnlineReservations}
                  onChange={handleOnlineToggle}
                />
                <div
                  className={`block h-6 w-10 rounded-full ${
                    localRestaurant.takesOnlineReservations
                      ? 'bg-green-500'
                      : 'bg-gray-400'
                  }`}
                ></div>
                <div
                  className={`absolute left-0.5 top-0.5 size-5 rounded-full bg-white transition-transform ${
                    localRestaurant.takesOnlineReservations
                      ? 'translate-x-4'
                      : ''
                  }`}
                ></div>
              </div>
              <span className="pl-2 text-lg">Open</span>
            </div>
          </label>
        </div>

        <div>
          <button
            onClick={() => navigate('/edit_online_timeslots')}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 ease-in-out
              hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            WORKING HOURS
          </button>
          <div className="w-24"></div>
          <button
            onClick={() => navigate('/edit_online_tables')}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 ease-in-out
              hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            ONLINE TABLE AVAILIBILITY
          </button>
        </div>

        {/* ---------- Name, Phone, Retries, etc. ----------- */}
        <div>
          <label
            htmlFor="restaurantName"
            className="mb-2 block font-semibold text-white"
          >
            Name
          </label>
          <input
            type="text"
            id="restaurantName"
            name="restaurantName"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm
              transition duration-200 ease-in-out placeholder:text-gray-300
              focus:outline-none focus:ring-2 focus:ring-pink-500 disabled:opacity-50"
            value={localRestaurant.restaurantName}
            onChange={(e) =>
              handleFieldChange('restaurantName', e.target.value)
            }
          />
        </div>

        <div>
          <label
            htmlFor="phone"
            className="mb-2 block font-semibold text-white"
          >
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="9876543210"
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm transition
              duration-200 ease-in-out placeholder:text-gray-300 focus:outline-none
              focus:ring-2 focus:ring-pink-500"
            value={authContext?.user?.username?.replace('+91', '') || ''}
            readOnly
          />
        </div>

        <div>
          <label
            htmlFor="maxRetries"
            className="mb-2 block font-semibold text-white"
          >
            Max Retries
          </label>
          <input
            type="number"
            id="maxRetries"
            name="maxRetries"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm
              transition duration-200 ease-in-out placeholder:text-gray-300
              focus:outline-none focus:ring-2 focus:ring-pink-500 disabled:opacity-50"
            value={localRestaurant.maxRetries}
            onChange={(e) =>
              handleFieldChange('maxRetries', Number(e.target.value))
            }
          />
        </div>

        <div>
          <label
            htmlFor="maxWaitingTimeInMinutes"
            className="mb-2 block font-semibold text-white"
          >
            Max Waiting Time (Minutes)
          </label>
          <input
            type="number"
            id="maxWaitingTimeInMinutes"
            name="maxWaitingTimeInMinutes"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm
              transition duration-200 ease-in-out placeholder:text-gray-300
              focus:outline-none focus:ring-2 focus:ring-pink-500 disabled:opacity-50"
            value={localRestaurant.maxWaitingTimeInMinutes}
            onChange={(e) =>
              handleFieldChange(
                'maxWaitingTimeInMinutes',
                Number(e.target.value),
              )
            }
          />
        </div>

        <div>
          <label
            htmlFor="maxGroupSize"
            className="mb-2 block font-semibold text-white"
          >
            Max Group Size for Advance Reservation
          </label>
          <input
            type="number"
            id="maxGroupSize"
            name="maxGroupSize"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm
              transition duration-200 ease-in-out placeholder:text-gray-300
              focus:outline-none focus:ring-2 focus:ring-pink-500 disabled:opacity-50"
            value={localRestaurant.maxGroupSize}
            onChange={(e) =>
              handleFieldChange('maxGroupSize', Number(e.target.value))
            }
          />
        </div>

        <div>
          <label
            htmlFor="slotDurationInMinutes"
            className="mb-2 block font-semibold text-white"
          >
            Booking Slot Duration
          </label>
          <select
            id="slotDurationInMinutes"
            name="slotDurationInMinutes"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-500 disabled:opacity-50"
            value={localRestaurant.slotDurationInMinutes}
            onChange={(e) =>
              handleFieldChange('slotDurationInMinutes', Number(e.target.value))
            }
          >
            <option value={15} className="text-black">
              15 min
            </option>
            <option value={20} className="text-black">
              20 min
            </option>
            <option value={45} className="text-black">
              45 min
            </option>
            <option value={60} className="text-black">
              1 hour
            </option>
            <option value={90} className="text-black">
              1 hour 30 min
            </option>
            <option value={120} className="text-black">
              2 hour{' '}
            </option>
            <option value={150} className="text-black">
              2 hour 30 min
            </option>
            <option value={180} className="text-black">
              3 hour
            </option>
            <option value={240} className="text-black">
              3 hour 30 min
            </option>
            <option value={240} className="text-black">
              4 hour
            </option>
          </select>
        </div>

        {/* ---------- EDIT / SAVE ---------- */}
        {!isEditing ? (
          <button
            onClick={() => setIsEditing(true)}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 ease-in-out
              hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            EDIT
          </button>
        ) : (
          <button
            onClick={async () => {
              // Save general restaurant info
              await handleSaveChanges(localRestaurant!);
              // Exit edit mode
              setIsEditing(false);
            }}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 ease-in-out
              hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            SAVE CHANGES
          </button>
        )}
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* TIME SLOT SECTION                                                      */}
      {/* ---------------------------------------------------------------------- */}

      <div className="mt-4 text-center">Powered by Quforia</div>
    </>
  );
};

export default RestaurantProfileForm;
