// src/context/ReservationContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { Restaurant, RestaurantContext } from './RestaurantContext';
import { CheckInItemContext } from './CheckInContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { AuthContext } from './AuthContext';

// Define the Reservation interface
export interface Reservation {
  reservationId: string;
  restaurantId: string;
  restaurantName: string;
  status: string;
  userName: string;
  userId: string;
  noOfPeople: number;
  peopleAhead: number;
  isEligibleForCheckIn: boolean;
  createdAt: string;
}

// Define the context properties
interface ReservationContextProps {
  reservations: Reservation[];
  pastReservations: Reservation[];
  setReservations: (reservations: Reservation[]) => void;
  lastRefreshTime?: string;
  isRefreshing: boolean;
}

export const ReservationContext = createContext<
  ReservationContextProps | undefined
>(undefined);

interface ReservationProviderProps {
  children: ReactNode;
}

export const ReservationProvider: React.FC<ReservationProviderProps> = ({
  children,
}) => {
  const [reservations, setReservations] = useState<Reservation[]>([
    // Add initial sample data as needed
  ]);
  const [pastReservations, setPastReservations] = useState<Reservation[]>([
    // Add initial sample data as needed
  ]);
  const [lastRefreshTime, setLastRefreshTime] = useState<string | undefined>(
    undefined,
  );
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

  const authContext = useContext(AuthContext);
  const restaurantContext = useContext(RestaurantContext);
  const checkInsContext = useContext(CheckInItemContext);

  useEffect(() => {
    if (!authContext?.isAuthenticated || !restaurantContext?.restaurant) {
      return; // Do nothing if authentication is missing or restaurant is not set
    }

    // Fetch reservations initially
    getReservations(restaurantContext.restaurant);

    // Set an interval to fetch reservations every 5 seconds
    const interval = setInterval(() => {
      if (!authContext?.isAuthenticated || !restaurantContext?.restaurant) {
        return; // Do nothing if authentication is missing or restaurant is not set
      }
      getReservations(restaurantContext.restaurant);
    }, 5000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [authContext?.isAuthenticated, restaurantContext?.restaurant]);

  const getReservations = async (restaurant: Restaurant) => {
    setIsRefreshing(true); // Show loading indicator
    checkInsContext?.setIsRefreshing(true); // Show loading indicator
    const overrideCheckInAll = !restaurant.isWaitingEnabled;

    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllReservations`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch reservations.');
      }

      const responseReservationsList = (await response.json()).reservationList;

      setReservations(
        responseReservationsList.filter(
          (reserve: { isEligibleForCheckIn: boolean; status: string }) =>
            reserve.status === 'Pending',
        ),
      );

      // Update check-in context
      const checkInsAvailable = responseReservationsList
        .filter(
          (reserve: { isEligibleForCheckIn: boolean; status: string }) =>
            reserve.status === 'Pending' &&
            (overrideCheckInAll || reserve.isEligibleForCheckIn),
        )
        .map(
          (reserve: {
            userName: string;
            userId: string;
            noOfPeople: number;
            reservationId: string;
          }) => ({
            userName: reserve.userName,
            userId: reserve.userId,
            noOfPeople: reserve.noOfPeople,
            reservationId: reserve.reservationId,
          }),
        );

      checkInsContext!.setCheckIns(checkInsAvailable);

      const pastResponse = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllPastReservations`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!pastResponse.ok) {
        throw new Error('Failed to fetch reservations.');
      }

      setPastReservations((await pastResponse.json()).pastReservationList);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    } finally {
      setIsRefreshing(false); // Hide loading indicator
      setLastRefreshTime(new Date().toLocaleTimeString()); // Update last refresh time
      checkInsContext?.setIsRefreshing(false);
      checkInsContext?.setLastRefreshTime(new Date().toLocaleTimeString());
    }
  };

  const value = {
    reservations,
    pastReservations,
    setReservations,
    lastRefreshTime,
    isRefreshing,
  };

  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  );
};
