import React, { useContext } from 'react';
import {
  PastValetParking,
  ValetParkingContext,
} from '../context/ValetParkingContext';
import { useNavigate } from 'react-router-dom';

const ValetParkingPanel: React.FC = () => {
  const { valetParkings, isRefreshing, lastRefershTime, pastValetParkings } =
    useContext(ValetParkingContext)!;
  const navigate = useNavigate();

  const orderEntries = (a: PastValetParking, b: PastValetParking) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    // Sort in descending order
    return dateB - dateA;
  };

  // Filter valetParkings based on the status
  const submittedParkings = valetParkings?.filter(
    (entry) => entry.status.toUpperCase() === 'SUBMITTED',
  );
  const readyForPickupParkings = valetParkings?.filter(
    (entry) => entry.status.toUpperCase() === 'READYTOPICKUP',
  );

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    ); // Convert UTC to local time

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Ensures AM/PM format
    });

    return formatter.format(localDate);
  };

  const filterTodaysEntries = (entry: PastValetParking) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day

    const entryDate = new Date(entry.createdAt);
    entryDate.setHours(0, 0, 0, 0); // Reset time to start of the day
    return entryDate.getTime() === today.getTime();
  };

  return (
    <div className="h-full rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md">
      <div className="mb-2 flex items-center justify-between">
        <div className="text-2xl font-bold max-sm:text-lg">Valet</div>
        {/* Loading or Last Refresh Indicator */}
        {isRefreshing ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <div className="text-sm text-gray-300 max-md:text-xs">
            Updated: {lastRefershTime}
          </div>
        )}
      </div>
      <div className="overflow-auto">
        {/* Table for READYFORPICKUP */}
        <div className="my-4">
          <div className="flex items-center justify-between">
            <div className="mb-2 text-lg font-semibold">Ready for Pickup</div>
            <div>
              Total: <b>{readyForPickupParkings.length}</b>
            </div>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-white/20 max-sm:text-sm">
                <th className="px-4 py-2 text-left font-semibold">Name</th>
                <th className="px-4 py-2 text-left font-semibold">Phone</th>
                <th className="px-4 py-2 text-left font-semibold">Token</th>
                <th className="px-4 py-2 text-left font-semibold">Status</th>
                <th className="px-4 py-2 text-left font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="max-sm:text-sm">
              {readyForPickupParkings?.map((entry, index) => (
                <tr
                  key={entry.customerId}
                  className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                >
                  <td className="px-4 py-2">{entry.customerName}</td>
                  <td className="px-4 py-2">{entry.customerId}</td>
                  <td className="px-4 py-2">{entry.uniqueValetParkingCode}</td>
                  <td className="px-4 py-2">{entry.status}</td>
                  <td className="px-4 py-2">
                    <button
                      className="text-green-400 underline transition hover:text-green-500"
                      onClick={() => navigate('/scanner')}
                    >
                      Release
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Table for SUBMITTED */}
        <div className="mb-6 mt-4">
          <div className="flex items-center justify-between">
            <div className="mb-2 text-lg font-semibold">Submitted</div>
            <div>
              Total: <b>{submittedParkings.length}</b>
            </div>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-white/20 max-sm:text-sm">
                <th className="px-4 py-2 text-left font-semibold">Name</th>
                <th className="px-4 py-2 text-left font-semibold">Phone</th>
                <th className="px-4 py-2 text-left font-semibold">Token</th>
                <th className="px-4 py-2 text-left font-semibold">Status</th>
              </tr>
            </thead>
            <tbody className="max-sm:text-sm">
              {submittedParkings?.map((entry, index) => (
                <tr
                  key={entry.customerId}
                  className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                >
                  <td className="px-4 py-2">{entry.customerName}</td>
                  <td className="px-4 py-2">{entry.customerId}</td>
                  <td className="px-4 py-2">{entry.uniqueValetParkingCode}</td>
                  <td className="px-4 py-2">{entry.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Table for Past Valet Parkings */}
        <div className="mb-6 mt-4">
          <div className="flex flex-row items-center justify-between">
            <div className="mb-2 text-lg font-semibold">
              Past Valet Parkings
            </div>
            <div>
              Total: <b>{readyForPickupParkings.length}</b>
            </div>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-white/20 max-sm:text-sm">
                <th className="px-4 py-2 text-left font-semibold">
                  Customer Name
                </th>
                <th className="px-4 py-2 text-left font-semibold">
                  Accepted By
                </th>
                <th className="px-4 py-2 text-left font-semibold">
                  Released By
                </th>
                <th className="px-4 py-2 text-left font-semibold">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody className="max-sm:text-sm">
              {pastValetParkings
                ?.filter(filterTodaysEntries)
                .sort(orderEntries)
                .map((entry, index) => (
                  <tr
                    key={index} //  index as key is generally discouraged, a unique ID from the API response would be better.
                    className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                  >
                    <td className="px-4 py-2">{entry.customerName}</td>
                    <td className="px-4 py-2">{entry.acceptValetName}</td>
                    <td className="px-4 py-2">{entry.releaseValetName}</td>
                    <td className="px-4 py-2">{formatDate(entry.createdAt)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ValetParkingPanel;
