import React, { useContext } from 'react';
import { Reservation, ReservationContext } from '../context/ReservationContext';
import { RestaurantContext } from '../context/RestaurantContext';

const Reservations: React.FC = () => {
  const restauarnt = useContext(RestaurantContext);

  const { reservations, isRefreshing, lastRefreshTime, pastReservations } =
    useContext(ReservationContext)!;

  const orderReservations = (a: Reservation, b: Reservation) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    // Sort in descending order
    return dateB - dateA;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    ); // Convert UTC to local time

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Ensures AM/PM format
    });

    return formatter.format(localDate);
  };

  const overrideCheckedIn = restauarnt?.restaurant?.isWaitingEnabled;
  const reservationsToShow = reservations.filter(
    (reserve) => !(!overrideCheckedIn || reserve.isEligibleForCheckIn),
  );

  return (
    <div className="min-md:px-6 relative h-full grow rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md max-sm:p-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="text-2xl font-bold max-sm:text-lg">Reservations</div>
        {/* Loading or Last Refresh Indicator */}
        {isRefreshing ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <div className="text-sm text-gray-300 max-md:text-xs">
            Updated: {lastRefreshTime}
          </div>
        )}
        <div>
          Total:{' '}
          <b>
            {
              reservationsToShow.filter(
                (reserve) => reserve.status === 'Pending',
              ).length
            }
          </b>
        </div>
      </div>

      {/* Reservation List */}
      <div className="mb-2 max-h-48 overflow-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-white/20 max-sm:text-sm">
              <th className="px-4 py-2 text-left font-semibold">Name</th>
              <th className="px-4 py-2 text-left font-semibold">Phone</th>
              <th className="px-4 py-2 text-left font-semibold">Guests</th>
              <th className="px-4 py-2 text-left font-semibold">
                Position in Q
              </th>
            </tr>
          </thead>
          <tbody className="max-sm:text-sm">
            {reservationsToShow &&
              reservationsToShow
                .filter((reserve) => reserve.status === 'Pending')
                .map((reservation, index) => (
                  <tr
                    key={reservation.userId}
                    className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                  >
                    <td className="px-4 py-2">{reservation.userName}</td>
                    <td className="px-4 py-2">{reservation.userId}</td>
                    <td className="px-4 py-2">{reservation.noOfPeople}</td>
                    <td className="px-4 py-2">{reservation.peopleAhead}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="mb-2 mt-4 flex items-center justify-between">
        <div className="text-2xl font-bold max-sm:text-lg">
          Past Reservations
        </div>
        {/* Loading or Last Refresh Indicator */}
        {isRefreshing ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <div className="text-sm text-gray-300 max-md:text-xs">
            Updated: {lastRefreshTime}
          </div>
        )}
        <div>
          Total: <b>{pastReservations.length}</b>
        </div>
      </div>

      {/* Past Reservation List */}
      <div className="mb-2 max-h-48 overflow-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-white/20 max-sm:text-sm">
              <th className="px-4 py-2 text-left font-semibold">Name</th>
              <th className="px-4 py-2 text-left font-semibold">Phone</th>
              <th className="px-4 py-2 text-left font-semibold">Guests</th>
              <th className="px-4 py-2 text-left font-semibold">Status</th>
              <th className="px-4 py-2 text-left font-semibold">Date</th>
            </tr>
          </thead>
          <tbody className="max-sm:text-sm">
            {pastReservations &&
              pastReservations
                .sort(orderReservations)
                .map((reservation, index) => (
                  <tr
                    key={reservation.userId}
                    className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                  >
                    <td className="px-4 py-2">{reservation.userName}</td>
                    <td className="px-4 py-2">{reservation.userId}</td>
                    <td className="px-4 py-2">{reservation.noOfPeople}</td>
                    <td className="px-4 py-2">{reservation.status}</td>
                    <td className="px-4 py-2">
                      {formatDate(reservation.createdAt)}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reservations;
