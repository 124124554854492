import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Reservations from '../components/Reservations';
import { RestaurantContext } from '../context/RestaurantContext';

const PANEL = {
  HOME: 'Home',
  RESERVATIONS: 'Reservations',
  CHECK_IN: 'Check-In',
  ADD_TABLE: 'Add Table',
  AVAILABLE_TABLES: 'Available Tables',
  VALET_PARKING: 'Valet',
  MY_PROFILE: 'My Profile',
  ANALYTICS: 'Analytics',
  STAFF: 'Staff',
  LOG_OUT: 'Log Out',
} as const;

type PanelKeys = keyof typeof PANEL;

const RestaurantMenuLayout: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWaitingEnabled, setIsWaitingEnabled] = useState(false);
  const restaurantContext = useContext(RestaurantContext);
  const restaurantInfoRef = useRef(restaurantContext?.restaurant); // State to toggle the menu
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const userSubRole = authContext?.user?.subRole;

  useEffect(() => {
    restaurantInfoRef.current = restaurantContext?.restaurant;
    if (
      restaurantContext?.restaurant &&
      restaurantContext.restaurant.isWaitingEnabled
    ) {
      setIsWaitingEnabled(true);
    } else {
      setIsWaitingEnabled(false);
    }
    console.log('Called');
  }, [setIsWaitingEnabled, restaurantContext?.restaurant]);

  // Determine allowed menu items based on subRole
  const getVisiblePanels = (): PanelKeys[] => {
    console.log('Calledr' + isWaitingEnabled);

    switch (userSubRole) {
      case 'Admin':
        if (!isWaitingEnabled) {
          return Object.keys(PANEL).filter(
            (key) => key !== 'AVAILABLE_TABLES' && key !== 'ADD_TABLE',
          ) as PanelKeys[];
        }
        // Admin can access all panels
        return Object.keys(PANEL) as PanelKeys[];

      case 'Reception':
        // Reception can access all except VALET_PARKING and STAFF

        if (!isWaitingEnabled) {
          return Object.keys(PANEL).filter(
            (key) =>
              key !== 'VALET_PARKING' && key !== 'STAFF' && key !== 'ANALYTICS',
          ) as PanelKeys[];
        }

        return Object.keys(PANEL).filter(
          (key) =>
            key !== 'VALET_PARKING' &&
            key !== 'AVAILABLE_TABLES' &&
            key !== 'ADD_TABLE' &&
            key !== 'STAFF',
        ) as PanelKeys[];

      case 'Valet':
        // Valet can only access HOME, VALET_PARKING, and LOG_OUT
        return ['HOME', 'VALET_PARKING', 'LOG_OUT'];

      default:
        // Default to an empty menu if no subRole is found
        return [];
    }
  };

  const visiblePanels = getVisiblePanels();

  const handleLogout = () => {
    authContext?.logout();
    window.location.reload(); // Refresh the page to reset the state
  };

  const renderPanel = (panel: PanelKeys) => {
    switch (panel) {
      case 'HOME':
        return navigate('/restaurant_dashboard');
      case 'RESERVATIONS':
        return navigate('/reservations');
      case 'CHECK_IN':
        return navigate('/checkIns');
      case 'ADD_TABLE':
        return navigate('/newTable');
      case 'AVAILABLE_TABLES':
        return navigate('/availableTables');
      case 'VALET_PARKING':
        return navigate('/valet');
      case 'MY_PROFILE':
        return navigate('/profile');
      case 'ANALYTICS':
        return navigate('/analytics');
      case 'STAFF':
        return navigate('/staff');
      case 'LOG_OUT':
        handleLogout();
        return;
      default:
        return <Reservations />;
    }
  };

  return (
    <>
      {/* Header with Menu Button */}
      <div className="block text-white focus:outline-none">
        {/* Hamburger Menu for Mobile */}
        <button
          className="float-right block text-white focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'
              }
            />
          </svg>
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute left-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center space-y-6 bg-purple-800/90">
            {/* Cross Button */}
            <button
              className="absolute right-4 top-4 text-white focus:outline-none"
              onClick={() => setIsMenuOpen(false)}
              aria-label="Close Menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Render Menu Items */}
            {visiblePanels.map((key) => (
              <button
                key={key}
                className="text-2xl font-bold text-white"
                onClick={() => {
                  renderPanel(key);
                  setIsMenuOpen(false);
                }}
              >
                {PANEL[key]}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default RestaurantMenuLayout;
