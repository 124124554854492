import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FooterTab {
  label: string;
  path: string;
  onClick?: () => void;
}

interface FooterTabsContextType {
  footerTabs: FooterTab[];
  setFooterTabs: (tabs: FooterTab[]) => void;
}

const FooterTabsContext = createContext<FooterTabsContextType | undefined>(
  undefined,
);

export const FooterTabsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [footerTabs, setFooterTabs] = useState<FooterTab[]>([]);

  return (
    <FooterTabsContext.Provider value={{ footerTabs, setFooterTabs }}>
      {children}
    </FooterTabsContext.Provider>
  );
};

export const useFooterTabs = (): FooterTabsContextType => {
  const context = useContext(FooterTabsContext);
  if (!context) {
    throw new Error('useFooterTabs must be used within a FooterTabsProvider');
  }
  console.log('Reservations: useEffect', context.footerTabs);
  return context;
};
