import Roastery_Hyd from '../assets/roastery_banjara_hyd.jpg';
import Harsha_Kitchen from '../assets/merchantLogo.png';
// import Seven_Sisters_Flyer_Sq from '../assets/7_Sisters_hyd_flyer_sq.jpg';
// import Seven_Sisters_Hyd from '../assets/teokbokki_hyd.jpg';
import Tteokbokki_Hyd from '../assets/teokbokki_hyd.jpg';
import Seven_Sisters_Hitec_Hyd from '../assets/seven_sisters_hitec_hyd_logo.jpg';
// import MerchantLogo from '../assets/merchantLogo.png'

export const API_GATEWAY_ENDPOINT: string =
  'https://0tevl0ibh4.execute-api.ap-south-1.amazonaws.com/prod';
export const INVALID_CODE_TOAST_ID: string = 'INVALID_CODE_ERROR_TOAST';
export const CUSTOMER_ROLE: string = 'Customer';
export const RESTAURANT_ROLE: string = 'Restaurant';

interface RestaurantSelect {
  name: string;
  image: string;
  isComingSoon?: boolean;
}

export const RESTAURANT_MAPPING: { [key: string]: RestaurantSelect } = {
  '+919988776655': {
    name: 'Harsha Kitchen',
    image: Harsha_Kitchen,
  },
  '+917671913062': {
    name: 'Tteokbokki Hyderabad',
    image: Tteokbokki_Hyd,
  },
  '+918334068484': {
    name: 'Seven Sisters - Hitex',
    image: Seven_Sisters_Hitec_Hyd,
  },
  '+914433221100': {
    name: 'Roastery Banjara Hills',
    image: Roastery_Hyd,
    isComingSoon: true,
  },
  // '+919064528862': {
  //   name: 'Roastery Hyderabad',
  //   image: Roastery_Hyd,
  // },
};
