import React from 'react';
import { Navigate } from 'react-router-dom';
import DesktopDashboard from '../screens/DesktopDashboard';

// PrivateRoute Component
export const DashboardRoute: React.FC = () => {
  return window.innerWidth > 768 ? (
    <DesktopDashboard />
  ) : (
    <Navigate to="/reservations" replace />
  );
};
