import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../../../utilities/Constants';
import {
  Restaurant,
  RestaurantContext,
} from '../../../context/RestaurantContext';
import { AuthContext } from '../../../context/AuthContext';

// ------------------------------
// TYPES & HELPERS
// ------------------------------
type WeekdayAbbrev = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

interface TimeRange {
  open: number; // e.g. 3214 seconds
  close: number; // e.g. 4215 seconds
}

interface WeekGroup {
  id: number;
  name: string;
  days: WeekdayAbbrev[];
  timeRanges: TimeRange[];
}

const ORDERED_DAYS: WeekdayAbbrev[] = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];

// Map from day-of-week (backend string) -> abbreviations
function mapBackendDayToAbbrev(day: string): WeekdayAbbrev | null {
  switch (day.toLowerCase()) {
    case 'monday':
      return 'Mon';
    case 'tuesday':
      return 'Tue';
    case 'wednesday':
      return 'Wed';
    case 'thursday':
      return 'Thu';
    case 'friday':
      return 'Fri';
    case 'saturday':
      return 'Sat';
    case 'sunday':
      return 'Sun';
    default:
      return null;
  }
}

// Reverse mapping for final save
function mapAbbrevToBackendDay(d: WeekdayAbbrev) {
  switch (d) {
    case 'Mon':
      return 'Monday';
    case 'Tue':
      return 'Tuesday';
    case 'Wed':
      return 'Wednesday';
    case 'Thu':
      return 'Thursday';
    case 'Fri':
      return 'Friday';
    case 'Sat':
      return 'Saturday';
    case 'Sun':
      return 'Sunday';
  }
}

// For naming default groups if they exactly match
function autoNameGroup(days: WeekdayAbbrev[]): string {
  const sorted = [...days].sort(
    (a, b) => ORDERED_DAYS.indexOf(a) - ORDERED_DAYS.indexOf(b),
  );
  if (sorted.join(',') === ['Mon', 'Tue', 'Wed', 'Thu'].join(',')) {
    return 'Weekdays (Mon-Thu)';
  }
  if (sorted.join(',') === ['Fri', 'Sat', 'Sun'].join(',')) {
    return 'Weekends (Fri-Sun)';
  }
  // Otherwise generate "Mon, Tue & Wed" style name
  if (sorted.length === 1) return sorted[0];
  if (sorted.length === 2) return sorted.join(' & ');
  const last = sorted.pop();
  return `${sorted.join(', ')} & ${last}`;
}

// Convert list of timeRanges into a consistent string for grouping
function serializeTimeRanges(ranges: TimeRange[]): string {
  const s = [...ranges].sort((a, b) => a.open - b.open);
  return s.map((r) => `${r.open}-${r.close}`).join(';');
}

// Merge overlapping time ranges
function mergeTimeRanges(ranges: TimeRange[]): TimeRange[] {
  if (!ranges.length) return [];
  const sorted = [...ranges].sort((a, b) => a.open - b.open);
  const merged: TimeRange[] = [];
  let current: TimeRange | null = null;

  for (const r of sorted) {
    if (!current) {
      current = { ...r };
      continue;
    }
    if (r.open <= current.close) {
      current.close = Math.max(current.close, r.close);
    } else {
      merged.push(current);
      current = { ...r };
    }
  }
  if (current) merged.push(current);
  return merged;
}

// Format seconds -> "HH:MM AM/PM" (in 15-min increments)
function formatSeconds(seconds: number): string {
  // Clamp
  let s = Math.max(0, Math.min(86400, seconds));
  // Round to nearest 15 mins (900s)
  s = Math.round(s / 900) * 900;

  const hour24 = Math.floor(s / 3600);
  const mins = Math.floor((s % 3600) / 60);
  const suffix = hour24 < 12 ? 'AM' : 'PM';
  let hour12 = hour24 % 12;
  if (hour12 === 0) hour12 = 12;
  return `${hour12}:${mins.toString().padStart(2, '0')} ${suffix}`;
}

/**
 * Quantize a given second to the nearest 15-min block
 */
function snapTo15Min(seconds: number): number {
  const raw = Math.max(0, Math.min(86400, seconds));
  // Round to nearest 900
  return Math.round(raw / 900) * 900;
}

/**
 * For each TimeRange, get the intersection with all other ranges in the same group
 * so we can highlight it in red.
 *
 * If there's no overlap allowed, the "blocked region" for a given range
 * is basically the union of all other ranges. We can highlight that overlap in red.
 */
function computeBlockedIntervals(
  current: TimeRange,
  otherRanges: TimeRange[],
): TimeRange[] {
  // Merge all other ranges
  const mergedOthers = mergeTimeRanges(otherRanges);
  // Intersect with `current` to see the blocked portion
  const blocked: TimeRange[] = [];
  mergedOthers.forEach((o) => {
    const overlapOpen = Math.max(current.open, o.open);
    const overlapClose = Math.min(current.close, o.close);
    if (overlapClose > overlapOpen) {
      blocked.push({ open: overlapOpen, close: overlapClose });
    }
  });
  return blocked;
}

const TimeSlotForm: React.FC = () => {
  const restaurantContext = useContext(RestaurantContext);
  const authContext = useContext(AuthContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localRestaurant, setLocalRestaurant] = useState<Restaurant | null>(
    restaurantContext?.restaurant || null,
  );
  // TIME SLOT STATE
  const [weekGroups, setWeekGroups] = useState<WeekGroup[]>([]);
  const [selectedDaysForNewGroup, setSelectedDaysForNewGroup] = useState<
    WeekdayAbbrev[]
  >([]);

  // 1. Fetch existing timeslots on mount
  useEffect(() => {
    fetchTimesFromBackend();
  }, []);

  useEffect(() => {
    if (restaurantContext?.restaurant) {
      setLocalRestaurant({ ...restaurantContext.restaurant });
    }
  }, [restaurantContext?.restaurant]);

  function createNewWeekGroup() {
    if (selectedDaysForNewGroup.length === 0) return;
    // Remove these days from existing groups
    const updated = weekGroups
      .map((g) => {
        const filtered = g.days.filter(
          (d) => !selectedDaysForNewGroup.includes(d),
        );
        return { ...g, days: filtered };
      })
      .filter((g) => g.days.length > 0);

    // New group
    const newId = updated.reduce((max, g) => Math.max(max, g.id), 0) + 1;
    const newName = autoNameGroup(selectedDaysForNewGroup);
    updated.push({
      id: newId,
      name: newName,
      days: [...selectedDaysForNewGroup],
      timeRanges: [{ open: 0, close: 86400 }],
    });

    setWeekGroups(updated);
    setSelectedDaysForNewGroup([]);
  }

  function handleDeleteGroup(groupId: number) {
    // That group is removed => those days are effectively closed
    setWeekGroups((prev) => prev.filter((g) => g.id !== groupId));
  }

  async function fetchTimesFromBackend() {
    try {
      const response = await fetch(
        'https://0tevl0ibh4.execute-api.ap-south-1.amazonaws.com/prod/fetchRestaurantOperations',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: `Bearer ${authContext?.user?.authToken || ''}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch restaurant operations');
      }
      const data = await response.json();
      const weeklyOps = data.weeklyOperatingHours || {};
      // weeklyOps might be like:
      // { "Monday": [ { OpenTime:3214, CloseTime:4215 }, ... ], "Tuesday": [...], ... }

      // Build day->ranges
      const dayMap: Record<WeekdayAbbrev, TimeRange[]> = {
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
        Sun: [],
      };

      for (const dayName in weeklyOps) {
        const abbr = mapBackendDayToAbbrev(dayName);
        if (!abbr) continue;
        const ranges = weeklyOps[dayName] as {
          OpenTime: number;
          CloseTime: number;
        }[];
        dayMap[abbr] = ranges.map((r) => ({
          open: r.OpenTime,
          close: r.CloseTime,
        }));
      }

      // Group days by identical sets of ranges
      const groupingMap: Record<string, WeekdayAbbrev[]> = {};
      ORDERED_DAYS.forEach((d) => {
        const serial = serializeTimeRanges(dayMap[d]);
        if (!groupingMap[serial]) groupingMap[serial] = [];
        groupingMap[serial].push(d);
      });

      // Build the final list
      const newGroups: WeekGroup[] = [];
      let groupId = 1;
      for (const serial of Object.keys(groupingMap)) {
        const theseDays = groupingMap[serial];
        // All have the same timeRanges
        const timeRanges = dayMap[theseDays[0]];
        const name = autoNameGroup(theseDays);
        newGroups.push({
          id: groupId++,
          name,
          days: theseDays,
          timeRanges: [...timeRanges],
        });
      }
      setWeekGroups(newGroups);
    } catch (err) {
      console.error(err);
      toast.error('Error fetching time slot configuration.');
    }
  }

  // 2. Save logic: must do 7 requests in parallel
  async function handleSaveAllTimeSlots() {
    // Decompose each group to day-based structure
    const dayToRanges: Record<WeekdayAbbrev, TimeRange[]> = {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    };
    weekGroups.forEach((g) => {
      g.days.forEach((d) => {
        dayToRanges[d] = g.timeRanges; // No overlap allowed
      });
    });

    // Build array of requests
    const requests = ORDERED_DAYS.map((d) => {
      const dayName = mapAbbrevToBackendDay(d);
      const operatingHours = dayToRanges[d].map((r) => ({
        OpenTime: r.open,
        CloseTime: r.close,
      }));
      const bodyData = {
        dayOfWeek: dayName,
        operatingHours,
      };
      // Make a request for this single day
      return fetch(`${API_GATEWAY_ENDPOINT}/updateRestaurantOperations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          role: 'Restaurant',
          Authorization: `Bearer ${authContext?.user?.authToken || ''}`,
        },
        body: JSON.stringify(bodyData),
      });
    });

    try {
      const results = await Promise.all(requests);
      // Check for any failure
      const anyFailure = results.some((r) => !r.ok);
      if (anyFailure) {
        throw new Error('One or more days failed to save');
      }
      toast.success('All time slots saved successfully!');
    } catch (err) {
      console.error(err);
      toast.error('Error saving time slots');
    }
  }

  // ---------- Group / DayChip Editing ----------
  function toggleDaySelection(day: WeekdayAbbrev) {
    if (selectedDaysForNewGroup.includes(day)) {
      // remove
      setSelectedDaysForNewGroup((prev) => prev.filter((d) => d !== day));
    } else {
      // add
      setSelectedDaysForNewGroup((prev) => [...prev, day]);
    }
  }

  // ---------- TimeRange Handlers & Dragging ----------
  // For the simplified approach, we handle "dragging" left/right handles.
  // We'll store any active drag in a ref or local state.
  interface DragInfo {
    groupId: number;
    rangeIndex: number;
    handle: 'left' | 'right';
    startX: number; // initial mouse X
    startValue: number; // initial open/close
  }

  const [dragInfo, setDragInfo] = useState<DragInfo | null>(null);

  function onMouseDownHandle(
    e: React.MouseEvent<HTMLDivElement>,
    groupId: number,
    rangeIndex: number,
    handle: 'left' | 'right',
    initialValue: number,
  ) {
    e.stopPropagation();
    e.preventDefault();
    setDragInfo({
      groupId,
      rangeIndex,
      handle,
      startX: e.clientX,
      startValue: initialValue,
    });
  }

  function onMouseMove(e: MouseEvent) {
    if (!dragInfo) return;
    e.preventDefault();

    const { groupId, rangeIndex, handle, startX, startValue } = dragInfo;
    const deltaX = e.clientX - startX;
    // Let's define: the full bar is, say, 600px wide representing 86400 seconds => 86400 / 600 = 144 sec per px
    // But in real usage, you might measure the container ref. For simplicity here, let's assume a scale factor of ~144 sec/px.
    // Then clamp & snap to 15-min increments.
    const pxToSec = 86400 / 600; // Example ratio
    let newSec = startValue + deltaX * pxToSec;
    newSec = snapTo15Min(newSec); // quantize to 15-min steps
    newSec = Math.max(0, Math.min(86400, newSec)); // clamp

    setWeekGroups((prev) => {
      return prev.map((g) => {
        if (g.id !== groupId) return g;
        const newRanges = [...g.timeRanges];
        const r = { ...newRanges[rangeIndex] };
        if (handle === 'left') {
          r.open = newSec;
          // Ensure open <= close
          if (r.open > r.close) {
            r.open = r.close;
          }
        } else {
          r.close = newSec;
          if (r.close < r.open) {
            r.close = r.open;
          }
        }
        // No overlap allowed => if we cause overlap, let's either clamp or allow overlap but highlight
        // For simplicity, let's just keep the user-chosen value but we highlight overlap in red.
        newRanges[rangeIndex] = r;
        return { ...g, timeRanges: newRanges };
      });
    });
  }

  function onMouseUp() {
    if (!dragInfo) return;
    setDragInfo(null);
  }

  useEffect(() => {
    // Attach global mousemove/mouseup
    function handleMove(e: MouseEvent) {
      onMouseMove(e);
    }
    function handleUp() {
      onMouseUp();
    }
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleUp);
    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleUp);
    };
  }, [dragInfo]);

  // If user is not actively dragging, we can still update open/close from a <select> or <input>
  function handleTimeRangeChange(
    groupId: number,
    rangeIndex: number,
    field: 'open' | 'close',
    val: number,
  ) {
    setWeekGroups((prev) => {
      return prev.map((g) => {
        if (g.id !== groupId) return g;
        const newRanges = [...g.timeRanges];
        const r = { ...newRanges[rangeIndex] };
        if (field === 'open') {
          r.open = snapTo15Min(val);
          if (r.open > r.close) r.open = r.close;
        } else {
          r.close = snapTo15Min(val);
          if (r.close < r.open) r.close = r.open;
        }
        newRanges[rangeIndex] = r;
        return { ...g, timeRanges: newRanges };
      });
    });
  }

  // Add new range row
  function handleAddTimeRange(groupId: number) {
    setWeekGroups((prev) => {
      return prev.map((g) => {
        if (g.id !== groupId) return g;
        return {
          ...g,
          timeRanges: [...g.timeRanges, { open: 0, close: 86400 }],
        };
      });
    });
  }

  // ---------- RENDER ----------
  if (!localRestaurant) {
    return (
      <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500" />
    );
  }

  return (
    <div className="px-8 pt-4 text-white">
      <div className="mt-8 flex-1 rounded-md border border-white/30 bg-white/20 p-4 shadow-xl backdrop-blur-md">
        <h2 className="mb-2 text-xl font-semibold text-gray-100">
          Operating Hours
        </h2>

        {!isEditing && (
          <p className="mb-4 text-sm text-gray-200">
            View-only mode. Below is the current configuration of operating
            hours.
          </p>
        )}
        {isEditing && (
          <p className="mb-4 text-sm text-gray-200">
            Drag the left/right handles to adjust times in 15-min steps.
            Overlapping intervals are shown in red. Select multiple days to
            create a new group, or delete entire groups.
          </p>
        )}

        {/* If editing, multi-day selection for new group */}
        {isEditing && (
          <div className="mb-4 flex flex-wrap gap-2">
            {ORDERED_DAYS.map((day) => {
              const selected = selectedDaysForNewGroup.includes(day);
              return (
                <button
                  key={day}
                  onClick={() => toggleDaySelection(day)}
                  className={`rounded-md border border-white/30 px-2 py-1 text-sm font-medium
              ${
                selected
                  ? 'bg-pink-600 text-white'
                  : 'bg-white/10 text-gray-100 hover:bg-white/20'
              }`}
                >
                  {day}
                </button>
              );
            })}
            {selectedDaysForNewGroup.length > 0 && (
              <button
                onClick={createNewWeekGroup}
                className="rounded-md border border-white/30 bg-white/10 px-2 py-1 text-sm font-medium text-gray-100 hover:bg-white/20"
              >
                Create Group
              </button>
            )}
          </div>
        )}

        {/* Render each group */}
        {weekGroups.map((group) => {
          // Cumulative "union" bar
          const mergedRanges = mergeTimeRanges(group.timeRanges);

          return (
            <div
              key={group.id}
              className="mb-6 rounded-md border border-white/30 bg-white/10 p-4 shadow-md"
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <h3 className="mb-2 text-lg font-semibold text-gray-200 sm:mb-0">
                  {group.name}
                </h3>
                <div className="text-sm text-gray-200">
                  <span className="font-semibold">Days:</span>{' '}
                  {group.days.join(', ')}
                </div>
              </div>

              {isEditing && (
                <button
                  onClick={() => handleDeleteGroup(group.id)}
                  className="mt-2 rounded-md bg-red-500 px-2 py-1 text-sm font-medium text-white hover:bg-red-600"
                >
                  Delete this Group
                </button>
              )}

              {/* VIEW-ONLY MODE: Just list ranges */}
              {!isEditing && (
                <div className="mt-4 text-sm text-gray-100">
                  {group.timeRanges.length === 0 && (
                    <div>This group is closed for these days.</div>
                  )}
                  {group.timeRanges.map((r, idx) => (
                    <div key={idx}>
                      {formatSeconds(r.open)} - {formatSeconds(r.close)}
                    </div>
                  ))}

                  {/* Also show cumulative coverage in a small bar */}
                  {mergedRanges.length > 0 && (
                    <div className="mt-2">
                      <div className="mb-1 text-xs text-white/80">Coverage</div>
                      <div className="relative h-3 w-full rounded-full bg-white/20">
                        {mergedRanges.map((mr, i) => (
                          <div
                            key={i}
                            className="absolute inset-y-0 rounded-full bg-orange-400/60"
                            style={{
                              left: `${(mr.open / 86400) * 100}%`,
                              width: `${((mr.close - mr.open) / 86400) * 100}%`,
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* EDIT MODE: Show drag bars etc. */}
              {isEditing && (
                <div className="mt-4 space-y-6">
                  {group.timeRanges.map((range, rangeIdx) => {
                    // Figure out blocked intervals (overlaps) relative to other bars in the same group
                    const otherRanges = group.timeRanges.filter(
                      (_, i) => i !== rangeIdx,
                    );
                    const blocked = computeBlockedIntervals(range, otherRanges);

                    return (
                      <div key={rangeIdx}>
                        {/* Heading: "12:00 AM - 1:00 AM" etc. */}
                        <div className="mb-1 text-xs text-white/80">
                          {formatSeconds(range.open)} -{' '}
                          {formatSeconds(range.close)}
                        </div>
                        {/* The main bar with overlap in red */}
                        <div className="relative h-4 w-full rounded-full bg-white/20">
                          {/* The "active" portion in pink */}
                          <div
                            className="absolute inset-y-0 rounded-full bg-pink-500/60"
                            style={{
                              left: `${(range.open / 86400) * 100}%`,
                              width: `${((range.close - range.open) / 86400) * 100}%`,
                            }}
                          ></div>
                          {/* Overlapping portion in red */}
                          {blocked.map((blk, i) => (
                            <div
                              key={i}
                              className="absolute inset-y-0 rounded-full bg-red-600/80"
                              style={{
                                left: `${(blk.open / 86400) * 100}%`,
                                width: `${((blk.close - blk.open) / 86400) * 100}%`,
                              }}
                            ></div>
                          ))}

                          {/* Left handle */}
                          <div
                            onMouseDown={(e) =>
                              onMouseDownHandle(
                                e,
                                group.id,
                                rangeIdx,
                                'left',
                                range.open,
                              )
                            }
                            className="absolute inset-y-0 -left-2 z-20 w-4 cursor-col-resize"
                            style={{
                              marginLeft: `${(range.open / 86400) * 100}%`,
                            }}
                          >
                            <div className="size-full rounded-full bg-pink-600/80 hover:bg-pink-600"></div>
                          </div>

                          {/* Right handle */}
                          <div
                            onMouseDown={(e) =>
                              onMouseDownHandle(
                                e,
                                group.id,
                                rangeIdx,
                                'right',
                                range.close,
                              )
                            }
                            className="absolute inset-y-0 -right-2 z-20 w-4 cursor-col-resize"
                            style={{
                              marginLeft: `${(range.close / 86400) * 100}%`,
                            }}
                          >
                            <div className="size-full rounded-full bg-pink-600/80 hover:bg-pink-600"></div>
                          </div>
                        </div>

                        {/* For direct numeric/manual selection in 15-min steps, we might show a dropdown or input fields */}
                        <div className="mt-2 flex items-center space-x-2 text-sm text-white">
                          <label>Open:</label>
                          <select
                            value={range.open}
                            onChange={(e) =>
                              handleTimeRangeChange(
                                group.id,
                                rangeIdx,
                                'open',
                                Number(e.target.value),
                              )
                            }
                            className="rounded-md border border-white/20 bg-black/30 px-2 py-1"
                          >
                            {generate15MinOptions().map((opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>

                          <label>Close:</label>
                          <select
                            value={range.close}
                            onChange={(e) =>
                              handleTimeRangeChange(
                                group.id,
                                rangeIdx,
                                'close',
                                Number(e.target.value),
                              )
                            }
                            className="rounded-md border border-white/20 bg-black/30 px-2 py-1"
                          >
                            {generate15MinOptions().map((opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  })}

                  {/* Add new time range */}
                  <button
                    onClick={() => handleAddTimeRange(group.id)}
                    className="mt-2 rounded-md bg-white/10 px-2 py-1 text-sm font-medium text-gray-100 hover:bg-white/20"
                  >
                    + Add Another Time Range
                  </button>

                  {/* Cumulative bar for the entire group */}
                  <div className="mt-4">
                    <div className="mb-1 text-xs text-white/80">
                      Cumulative Coverage
                    </div>
                    <div className="relative h-3 w-full rounded-full bg-white/20">
                      {mergedRanges.map((mr, i) => (
                        <div
                          key={i}
                          className="absolute inset-y-0 rounded-full bg-orange-400/60"
                          style={{
                            left: `${(mr.open / 86400) * 100}%`,
                            width: `${((mr.close - mr.open) / 86400) * 100}%`,
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/* ---------- EDIT / SAVE ---------- */}
      {!isEditing ? (
        <button
          onClick={() => setIsEditing(true)}
          className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
      font-bold text-white shadow-md transition duration-200 ease-in-out
      hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
        >
          EDIT
        </button>
      ) : (
        <button
          onClick={async () => {
            // Save the timeslots (7 parallel requests)
            await handleSaveAllTimeSlots();
            // Exit edit mode
            setIsEditing(false);
          }}
          className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
      font-bold text-white shadow-md transition duration-200 ease-in-out
      hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
        >
          SAVE CHANGES
        </button>
      )}

      <div className="mt-4 text-center">Powered by Quforia</div>
    </div>
  );
};

// Helper to build <option> for all 15-min increments
function generate15MinOptions() {
  // 86400 seconds in a day, step by 900
  const opts = [];
  for (let sec = 0; sec <= 86400; sec += 900) {
    opts.push({
      value: sec,
      label: formatSeconds(sec), // "12:00 AM", "12:15 AM", etc.
    });
  }
  return opts;
}

export default TimeSlotForm;
