// helper.
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { CUSTOMER_ROLE, RESTAURANT_ROLE } from '../utilities/Constants';

const useAuthRedirect = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const from = location.state?.from || '';
  const queryString = location.state?.queryString || '';

  if (!authContext) {
    throw new Error('Login must be used within an AuthProvider');
  }
  const navigate = useNavigate();

  // Check for JWT in localStorage
  useEffect(() => {
    console.log(from);
    if (authContext.isAuthenticated && authContext.user != null) {
      if (authContext.user.userType === CUSTOMER_ROLE) {
        const url =
          from === null || from.trim() === ''
            ? '/customer_dashboard'
            : `${from}?${queryString.replace(/ /g, '&').replace(/\+/g, '%2B')}`;
        console.log(url);
        navigate(url);
      } else if (authContext.user.userType === RESTAURANT_ROLE) {
        navigate('/restaurant_dashboard');
      }
    }
  }, [navigate, authContext.isAuthenticated, authContext.user?.userType]);
};

export default useAuthRedirect;
