import React from 'react';
import { RestaurantHeader } from '../components/Hamburger/RestaurantHeader';
import Analytics from './Analytics';

function AnalyticsListing() {
  return (
    <div className="bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      {/* Header */}
      <RestaurantHeader />
      {/* Content */}
      <Analytics />
    </div>
  );
}

export default AnalyticsListing;
