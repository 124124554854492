import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  API_GATEWAY_ENDPOINT,
  RESTAURANT_MAPPING,
} from '../utilities/Constants';
import QRCode from 'react-qr-code';
import { AuthContext } from '../context/AuthContext';
import ValetParking from '../components/ValetParking';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // For making API calls
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import merchantLogo from '../assets/merchantLogo.png';
import useAuthRedirect from '../hooks/AuthRedirect';
import { Restaurant } from '../context/RestaurantContext';
import { Reservation } from '../context/ReservationContext';
import {
  CheckIcon,
  PencilSquareIcon,
  StopCircleIcon,
} from '@heroicons/react/24/outline';

interface ValetParking {
  restaurantId: string;
  customerId: string;
  status: string;
  createdAt: string;
  uniqueValetParkingCode: string;
  restaurantName: string;
  customerName: string;
  valetParkingToken: string;
}

interface RestaurantInfoResponseItem {
  restaurantId: string;
  restaurantName: string;
  isWaitingEnabled: boolean;
  isRestaurantOpen: boolean;
  takesOnlineReservations: boolean;
}

const CustomerDashboard: React.FC = () => {
  useAuthRedirect();
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('Login must be used within an AuthProvider');
  }

  const [hotels, setHotels] = useState<Restaurant[]>([]); // State to store available hotels
  const navigate = useNavigate();
  const [valetSecretToken, setValetSecretToken] = useState<string | null>(null);
  const [valetParkingList, setValetParkingList] = useState<
    ValetParking[] | null
  >(null);
  const [showValetModal, setShowValetModal] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [releasingVehicle, setReleasingVehicle] = useState(false);
  const [currentReservations, setCurrentReservations] = useState<Reservation[]>(
    [],
  );
  const [isCancelCTALoading, setIsCancelCTALoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<Reservation | undefined>(
    undefined,
  );
  const [selectedReservation, setSelectedReservation] = useState<
    Reservation | undefined
  >(undefined);
  const [isEditingReservation, setIsEditingReservation] =
    useState<boolean>(false);
  const [isUpdatingReservation, setIsUpdatingReservation] =
    useState<boolean>(false);
  const [updatedGuestCount, setUpdatedGuestCount] = useState<number>(0);
  const [restaurantDropDownOpen, setRestaurantDropDownOpen] =
    useState<boolean>(false);
  const [selectedHotel, setSelectedHotel] = useState<Restaurant | undefined>(
    undefined,
  ); // State to track selected hotel

  const editReservation = (reservation: Reservation) => {
    if (isEditingReservation) {
      toast.info(
        'You can only edit one reservation at once, please finish editing for your reservation at : ' +
          selectedReservation?.restaurantName,
      );
      return;
    }
    setUpdatedGuestCount(reservation.noOfPeople);
    setSelectedReservation(reservation);
    setIsEditingReservation(true);
  };

  const updateReservationGuestCount = async (
    event: React.FormEvent<HTMLInputElement> | null,
  ) => {
    if (event) event.preventDefault();
    try {
      setIsUpdatingReservation(true);
      if (!isEditingReservation || !selectedReservation) {
        toast.error(
          'An error occurred, please try again after refreshing the page.',
        );
        return;
      }
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateReservationInfo`,
        {
          method: 'POST',
          body: JSON.stringify({
            reservationId: selectedReservation.reservationId,
            noOfPeople: updatedGuestCount,
          }),
          headers: {
            'Content-Type': 'application/json',
            role: 'Customer',
            Authorization: 'Bearer ' + authContext.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      // Update the currentReservations array
      setCurrentReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation.reservationId === selectedReservation.reservationId
            ? { ...reservation, noOfPeople: updatedGuestCount }
            : reservation,
        ),
      );

      toast.success('Updated guest count successfully!');
    } catch (err) {
      console.error(
        'An error occurred while trying to update the customer reservation: ',
        err,
      );
      toast.error('An unexpected error occurred, please try again!');
    } finally {
      setIsEditingReservation(false);
      setIsUpdatingReservation(false);
      setSelectedReservation(undefined);
      setUpdatedGuestCount(0);
    }
  };

  useEffect(() => {
    const storedCustomerId = authContext.user?.username;
    if (storedCustomerId) setPhone(storedCustomerId);
    fetchValetParkingInfo();
    fetchHotels();
  }, []);

  useEffect(() => {
    const vehicleInterval = setInterval(fetchValetParkingInfo, 5000);
    return () => clearInterval(vehicleInterval);
  }, []);

  useEffect(() => {
    // Fetch current reservations data
    const fetchCurrentReservations = async () => {
      try {
        const response = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchAllReservations`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.user?.authToken}`,
              role: 'Customer',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch reservations');
        }

        const data = await response.json();
        setCurrentReservations(data.reservationList);
      } catch (error) {
        console.error('An error occured trying to fetch reservations: ', error);
        toast.error('An error occurred while fetching reservations.');
      }
    };

    fetchCurrentReservations();
  }, [authContext]);

  // Fetch list of hotels from API
  const fetchHotels = async () => {
    try {
      //API Call here
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllRestaurant`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (authContext?.user?.authToken || ''),
            role: 'Customer',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to release valet parking');
      }
      const data = await response.json();
      // Transform the list to attach image or default
      const updatedHotels: Restaurant[] = data.restaurantInfoList.map(
        (hotel: RestaurantInfoResponseItem) => {
          const mappedRestaurant = RESTAURANT_MAPPING[hotel.restaurantId];
          return {
            ...hotel,
            // If found in RESTAURANT_MAPPING, use its `image`, otherwise fallback
            image: mappedRestaurant?.image || merchantLogo,
            // Optionally, also override name if you want the mapped name
            restaurantName: mappedRestaurant?.name || hotel.restaurantName,
          };
        },
      );
      // Now set the updated list to state
      setHotels(
        updatedHotels.sort(
          (x: Restaurant, y: Restaurant) =>
            (y.isRestaurantOpen === true ? 1 : 0) -
            (x.isRestaurantOpen === true ? 1 : 0),
        ),
      );
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  };

  const handleReserveTable = () => {
    if (selectedHotel) {
      navigate(
        `/make_reservation?restaurantId=${encodeURIComponent(selectedHotel.restaurantId)}`,
      ); // Navigate with hotel name in query
    }
  };

  const updateValetParking = async () => {
    try {
      setReleasingVehicle(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateValetParking`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (authContext?.user?.authToken || ''),
            role: 'Customer',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to release valet parking');
      }
      const { valetParkingToken } = await response.json();
      toast.success('Valet parking released successfully!');
      setValetParkingList((prev) => {
        if (!prev) return prev;
        return prev.map((v) =>
          v.uniqueValetParkingCode === valetParkingToken
            ? {
                ...v,
                status: 'ReadyForPickup',
              }
            : v,
        );
      });
      setValetSecretToken(valetParkingToken);
    } catch (error) {
      console.error(
        'An error occurred during CustomerUpdateValetParking action: ',
        error,
      );
      toast.error('Error releasing valet parking');
    } finally {
      setReleasingVehicle(false);
    }
  };

  const fetchValetParkingInfo = async () => {
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchValetParkingInfo`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authContext.user?.authToken,
            role: 'Customer',
          },
        },
      );
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      const { valetParkingList } = data;
      if (valetParkingList && valetParkingList.length > 0) {
        setValetParkingList(valetParkingList);
        setValetSecretToken(valetParkingList[0].valetParkingToken);
        setReleasingVehicle(false);
      } else {
        setValetParkingList([]);
        setShowValetModal(false);
        setValetSecretToken(null);
      }
    } catch (error) {
      setValetSecretToken(null);
      console.error('Failed to fetch valet parking info:', error);
    }
  };

  const renderCancelModal = () => {
    if (!isModalOpen) return null;
    return ReactDOM.createPortal(
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
          {/* Close Button */}
          <button
            onClick={() => closeModal()}
            className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 focus:outline-none"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h3 className="mb-4 text-xl font-semibold text-black">
            Cancel Reservation
          </h3>
          <p className="mb-4 text-gray-800">
            Are you sure you want to cancel the reservation for{' '}
            <span className="font-bold">{selectedEntry?.restaurantName}</span>?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
              onClick={closeModal}
              disabled={isCancelCTALoading}
            >
              No
            </button>
            <button
              className="rounded-md bg-red-500 px-4 py-2 text-white"
              onClick={cancelCustomerReservation}
              disabled={isCancelCTALoading}
            >
              {isCancelCTALoading ? 'Deleting...' : 'Yes'}
            </button>
          </div>
        </div>
      </div>,
      document.body,
    );
  };

  const handleHotelChange = (hotel: Restaurant) => {
    setSelectedHotel(hotel); // Update the selected hotel
    setRestaurantDropDownOpen(false);
  };

  const openModal = (entry: Reservation) => {
    setSelectedEntry(entry);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEntry(undefined);
    setIsModalOpen(false);
  };

  const cancelCustomerReservation = async () => {
    try {
      setIsCancelCTALoading(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateReservation/${selectedEntry?.restaurantId}/${selectedEntry?.reservationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Customer',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        toast.error(
          'An unknown error happened while removing the reservation, Please try again later.',
        );
        return;
      }

      setCurrentReservations((currentReservations) =>
        currentReservations.filter(
          (r) => r.reservationId != selectedEntry?.reservationId,
        ),
      );

      toast.success('Successfully removed reservation.');
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsCancelCTALoading(false);
    }
  };

  useEffect(() => {
    const vehicleInterval = setInterval(fetchValetParkingInfo, 5000);
    return () => clearInterval(vehicleInterval);
  }, []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setRestaurantDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <LoopedBackground />
      <div className="absolute left-1/2 top-0 flex h-screen w-screen -translate-x-1/2 flex-col">
        <img
          src={merchantLogo}
          className="mt-12 size-40 self-center max-sm:size-36"
          alt="Merchant Logo"
        />
        <div className="mx-8 mt-8 flex-1 rounded-md border border-white/30 bg-white/20 p-4 shadow-xl backdrop-blur-md">
          <h2 className="mb-2 text-xl font-semibold text-gray-100">
            Available Restaurants
          </h2>
          <div className="relative" ref={dropdownRef}>
            {/* Trigger Button */}
            <button
              type="button"
              onClick={() => setRestaurantDropDownOpen(!restaurantDropDownOpen)}
              className="flex w-full items-center justify-between rounded-md border border-white/30 px-4 py-3 text-gray-100 transition hover:bg-white/30 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <div className="flex items-center">
                <img
                  src={
                    selectedHotel
                      ? RESTAURANT_MAPPING[selectedHotel.restaurantId].image ||
                        merchantLogo
                      : merchantLogo
                  }
                  alt={
                    selectedHotel
                      ? selectedHotel.restaurantName
                      : 'merchant logo'
                  }
                  className="mr-3 size-8 rounded-full border border-white/20 object-cover shadow-sm"
                />
                <span className="font-medium">
                  {selectedHotel
                    ? selectedHotel.restaurantName
                    : '== Select a restaurant =='}
                </span>
              </div>
              <svg
                className={`ml-2 size-5 transition-transform duration-200${restaurantDropDownOpen ? 'rotate-180' : 'rotate-0'}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {/* Dropdown Menu */}
            {restaurantDropDownOpen && (
              <ul
                className="absolute z-50 mt-1 w-full rounded-md border border-white/30 bg-white/20 shadow-xl backdrop-blur-md"
                role="listbox"
              >
                {hotels.map((hotel) => (
                  <li
                    key={hotel.restaurantId}
                    onClick={() =>
                      hotel.isRestaurantOpen
                        ? handleHotelChange(hotel)
                        : toast.info(
                            'The selected restaurant is closed at the moment, please try again later',
                          )
                    }
                    className={
                      hotel.isRestaurantOpen
                        ? 'flex cursor-pointer items-center bg-white/90 px-4 py-2 text-black hover:bg-white/95'
                        : 'flex cursor-pointer items-center bg-gray-700/95 px-4 py-2 text-black'
                    }
                  >
                    <img
                      src={
                        RESTAURANT_MAPPING[hotel.restaurantId].image ||
                        merchantLogo
                      }
                      alt={hotel.restaurantName}
                      className="mr-3 size-6 rounded-full border border-white/20 object-cover shadow-sm"
                    />
                    <span
                      className={
                        hotel.isRestaurantOpen
                          ? 'font-medium'
                          : 'font-medium text-white'
                      }
                    >
                      {hotel.restaurantName}
                    </span>
                    {!hotel.isRestaurantOpen ? (
                      <div className="pl-4 font-semibold text-red-500">
                        {'[CLOSED]'}
                      </div>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {selectedHotel && (
            <div className="m-2 flex items-center justify-center align-middle">
              {/* Example "Reserve a Table" button */}
              <button
                onClick={handleReserveTable}
                className="mt-4 inline-flex items-center justify-center rounded-lg bg-gradient-to-r from-orange-400 to-orange-500 px-4 py-2 font-medium text-white shadow-md transition duration-150 ease-in-out hover:from-orange-500 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                Reserve a Table
              </button>
              {RESTAURANT_MAPPING[selectedHotel.restaurantId].isComingSoon ?? (
                <div className="w-4"></div>
              )}
              {RESTAURANT_MAPPING[selectedHotel.restaurantId].isComingSoon ?? (
                <button
                  onClick={() =>
                    navigate(
                      `/menu/${encodeURIComponent(selectedHotel.restaurantId)}`,
                    )
                  }
                  className="mt-4 inline-flex items-center justify-center rounded-lg bg-gradient-to-r from-pink-400 to-pink-500 px-4 py-2 font-medium text-white shadow-md transition duration-150 ease-in-out hover:from-pink-500 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  See Menu
                </button>
              )}
            </div>
          )}
        </div>
        <div style={{ flex: 1, padding: '20px', borderRadius: '4px' }}>
          <div className="min-lg:mt-8 flex flex-col items-center">
            <div className="my-4 flex flex-col items-center justify-center rounded-2xl bg-white p-4 align-middle">
              <QRCode
                size={256}
                style={{ height: 'auto' }}
                viewBox={`0 0 256 256`}
                level={'M'}
                fgColor={'#D1303D'}
                value={encodeURIComponent(
                  `https://link.quforia.com?customerId=%2B${phone.replace('+', '')}&action=CustomerID&source=QR`,
                )}
              />
              <span className="pt-3 font-semibold text-[#D1303D]">
                {' '}
                Quforia Unique Profile Code{' '}
              </span>
              {/* TODO: ADD EMOTICON */}
            </div>
            <div className="font-bold text-gray-300">
              Registered as: {authContext.user?.username}
            </div>
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => (window.location.href = '/user_reservations')}
                className="rounded-full border border-gray-300 px-6 py-2 font-semibold text-blue-600 hover:bg-gray-100"
              >
                My Bookings
              </button>

              {valetParkingList && valetParkingList.length > 0 ? (
                <button
                  onClick={() => setShowValetModal(true)}
                  className="rounded-full border border-gray-300 px-6 py-2 font-semibold text-blue-600 hover:bg-gray-100"
                >
                  My Valet
                </button>
              ) : (
                <button
                  disabled
                  className="cursor-not-allowed rounded-full border border-gray-300 px-6 py-2 font-semibold text-gray-400"
                >
                  My Valet
                </button>
              )}
            </div>
          </div>
          {/* 
                    <div className='max-md:hidden'>
                <img src={LinthImage} alt='decorative' style={{height: '80vh'}}/>
            </div> */}

          <div className="mt-10">
            <h2 className="mb-4 text-3xl font-bold text-white">
              Current Reservations
            </h2>

            {currentReservations.length > 0 ? (
              currentReservations.map((reservation, index) => (
                <div
                  key={index}
                  className={`mb-4 rounded-lg p-6 shadow ${
                    index % 2 === 0 ? 'bg-white/20' : 'bg-white/10'
                  } flex flex-col`}
                >
                  {/* Card Heading: Name + Mobile */}
                  <div className="mb-2 flex items-center justify-between">
                    <h3 className="text-lg font-bold text-white">
                      {reservation.userName}
                    </h3>
                    <span className="text-sm text-gray-300">
                      {reservation.userId}
                    </span>
                  </div>
                  <div className="mb-3 flex items-center justify-start align-middle">
                    {/* Guests & Restaurant */}
                    {!isEditingReservation && !isUpdatingReservation && (
                      <>
                        <p className="text-sm text-gray-300">
                          {reservation.noOfPeople} Guest
                          {reservation.noOfPeople > 1 ? 's' : ''} at{' '}
                          {reservation.restaurantName}
                        </p>
                        <PencilSquareIcon
                          className="ml-4"
                          onClick={() => editReservation(reservation)}
                          height={24}
                          color="pink"
                        />
                      </>
                    )}
                    {isEditingReservation && (
                      <>
                        <div>
                          <input
                            className="
                            w-full rounded-md bg-white/20
                            p-1 text-white shadow-sm
                            transition duration-200
                            ease-in-out placeholder:text-gray-300 focus:outline-none
                            focus:ring-2 focus:ring-pink-500"
                            type="number"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const inputValue = e.target?.value;
                              // Remove non-numeric characters
                              const numericValue = inputValue.replace(
                                /[^0-9]/g,
                                '',
                              );
                              // Convert to a number and update state
                              setUpdatedGuestCount(
                                numericValue ? parseInt(numericValue, 10) : 0,
                              );
                            }}
                            onSubmit={(e: React.FormEvent<HTMLInputElement>) =>
                              updateReservationGuestCount(e)
                            }
                            disabled={isUpdatingReservation}
                          />
                        </div>
                        {!isUpdatingReservation ? (
                          <CheckIcon
                            className="ml-1"
                            onClick={() => updateReservationGuestCount(null)}
                            height={24}
                            color="white"
                          />
                        ) : (
                          <StopCircleIcon height={24} color="white" />
                        )}
                      </>
                    )}
                  </div>

                  {/* Queue Wait & Check-In */}
                  <h4
                    className={`mb-4 flex items-center justify-between text-sm font-semibold ${
                      reservation.isEligibleForCheckIn
                        ? 'text-green-500'
                        : 'text-orange-500'
                    }`}
                  >
                    {/* Left Text */}
                    <span>
                      {reservation.isEligibleForCheckIn
                        ? 'Please proceed to the restaurant for Check-In'
                        : 'Please wait for Check-In'}
                    </span>

                    {/* Right-Aligned Text */}
                    <span className="text-rose-400">
                      {!reservation.isEligibleForCheckIn
                        ? reservation.peopleAhead > 0
                          ? `${reservation.peopleAhead} people ahead of you`
                          : 'Calculating Queue number...'
                        : ''}
                    </span>
                  </h4>

                  {/* Creation Date */}
                  <p className="mb-4 text-xs text-gray-400">
                    Created on:{' '}
                    {new Date(reservation.createdAt).toLocaleDateString()}
                  </p>

                  {/* Buttons Container */}
                  <div className="mt-auto flex space-x-3">
                    {RESTAURANT_MAPPING[reservation.restaurantId]
                      .isComingSoon ?? (
                      <button
                        onClick={() =>
                          navigate(
                            `/menu/${encodeURIComponent(reservation.restaurantId)}`,
                            { replace: true },
                          )
                        }
                        className="flex-1 rounded-full border border-pink-600 px-4 py-2 font-semibold text-pink-600 transition hover:bg-pink-600 hover:text-white"
                      >
                        See the Menu
                      </button>
                    )}
                    <button
                      onClick={() => openModal(reservation)}
                      className="flex-1 rounded-full border border-red-600 px-4 py-2 font-semibold text-red-600 transition hover:bg-red-600 hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-gray-400">
                No current reservations available.
              </div>
            )}
          </div>

          {showValetModal && valetParkingList && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/90">
              <div className="relative w-11/12 max-w-md rounded-lg bg-white p-6">
                {/* Close Button */}
                <button
                  onClick={() => setShowValetModal(false)}
                  className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <h2 className="mb-4 text-xl font-bold text-black">My Valet</h2>
                {valetParkingList.map((valet) => (
                  <div
                    key={valet.uniqueValetParkingCode}
                    className="mb-4 rounded-md border p-4 text-black"
                    style={{ color: 'black !important' }}
                  >
                    <p>
                      <strong>Restaurant Name:</strong> {valet.restaurantName}
                    </p>
                    <p>
                      <strong>Customer Name:</strong> {valet.customerName}
                    </p>
                    <p>
                      <strong>Status:</strong> {valet.status}
                    </p>
                    <p>
                      <strong>Valet Code:</strong>{' '}
                      {valet.uniqueValetParkingCode}
                    </p>
                    {valet.status.toUpperCase() === 'SUBMITTED' && (
                      <button
                        onClick={updateValetParking}
                        disabled={releasingVehicle}
                        className={`mt-2 rounded-md px-4 py-2 font-semibold ${releasingVehicle ? 'cursor-not-allowed bg-gray-400 text-gray-700' : 'bg-yellow-500 text-black hover:bg-yellow-600'}`}
                      >
                        Release Vehicle
                      </button>
                    )}
                  </div>
                ))}
                {valetSecretToken && (
                  <div className="flex flex-col items-center justify-center align-middle">
                    <QRCode
                      size={256}
                      style={{ height: 'auto' }}
                      viewBox={`0 0 256 256`}
                      level={'L'}
                      fgColor={'#F47304'}
                      value={encodeURIComponent(
                        `https://link.quforia.com?customerId=%2B${phone.replace('+', '')}&action=ReleaseVehicle&secretToken=${valetSecretToken}&parkingToken=${valetParkingList![0].uniqueValetParkingCode}&source=QR`,
                      )}
                    />
                    <span className="pt-3 font-semibold text-[#F47304]">
                      Show this code to the valet to get your vehicle
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {renderCancelModal()}:
    </div>
  );
};

export default CustomerDashboard;
