import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RestaurantContext } from '../../context/RestaurantContext';
import { API_GATEWAY_ENDPOINT } from '../../utilities/Constants';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { QrCodeIcon, UserIcon } from '@heroicons/react/24/outline';
import RestaurantMenuLayout from '../../layout/RestaurantMenuLayout';
import ReactGA from 'react-ga4';

export const RestaurantHeader: React.FC = () => {
  const [isWaitingEnabled, setIsWaitingEnabled] = useState<boolean>(false);
  const [isToggleLoading, setIsToggleLoading] = useState<boolean>(false);
  const [showProfileInfo, setShowProfileInfo] = useState<boolean>(false);

  const authContext = useContext(AuthContext);
  const restaurantContext = useContext(RestaurantContext);
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const restaurantInfoRef = useRef(restaurantContext?.restaurant);
  const restInfo = restaurantContext?.restaurant;

  useEffect(() => {
    // Update ref whenever restaurantContext.restaurant changes
    restaurantInfoRef.current = restaurantContext?.restaurant;
    if (restaurantContext?.restaurant) {
      setIsWaitingEnabled(restaurantContext.restaurant.isWaitingEnabled);
      getUserInfo();
    }
  }, [restaurantContext?.restaurant]);

  const toggleWaitingFeature = async (val: boolean) => {
    if (!restaurantContext?.restaurant || isToggleLoading) return;
    setIsWaitingEnabled(val);
    setIsToggleLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateRestaurantInfo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: `Bearer ${authContext?.user?.authToken}`,
          },
          body: JSON.stringify({
            ...restaurantContext?.restaurant,
            isWaitingEnabled: val,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to toggle waiting feature.');
      }

      restaurantContext.setRestaurant({
        ...restaurantContext.restaurant,
        isWaitingEnabled: val,
      });

      // Log the initiation of the waiting feature toggle
      ReactGA.event({
        category: 'Restaurant Header',
        action: 'Toggle Waiting Feature Initiated',
        label: `User toggled waiting feature to ${val ? 'Enabled' : 'Disabled'}`,
      });
    } catch (error) {
      console.error('Error toggling waiting feature:', error);
      toast.error(
        'An error occurred changing this status, please refresh and try again!',
      );
      setIsWaitingEnabled(!val);
    } finally {
      setIsToggleLoading(false);
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchRestaurantAccountInfo`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext?.user?.authToken}`,
            role: 'Customer',
          },
        },
      );

      const data = await response.json();
      setName(
        data.restaurantName !== 'Unknown'
          ? data.restaurantAccountIdName
          : data.restaurantName,
      );
    } catch {
      console.error('Error loading user details for restaurant');
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex items-center justify-between p-[2%] max-md:mb-[3%] max-md:px-[3%] sm:p-0">
        {/* Left Section */}
        <div className="flex items-center p-[2%] md:px-[3%]">
          {/* Example: RestaurantMenuLayout on the left */}
          <RestaurantMenuLayout />

          <div
            className={`flex items-center justify-center ${
              window.innerWidth > 768 ? 'flex-row' : 'flex-col'
            }`}
          >
            {/* Add more space between RestaurantMenuLayout & waiting toggle */}
            {restInfo && authContext?.user?.subRole !== 'Valet' && (
              <div className="ml-[15%] flex items-center">
                <label
                  htmlFor="waiting-toggle"
                  className="flex cursor-pointer items-center px-[5%]"
                >
                  <div className="relative">
                    <input
                      id="waiting-toggle"
                      type="checkbox"
                      className="sr-only"
                      checked={isWaitingEnabled}
                      onChange={() => toggleWaitingFeature(!isWaitingEnabled)}
                    />
                    <div
                      className={`block h-6 w-10 rounded-full ${
                        isWaitingEnabled ? 'bg-green-500' : 'bg-gray-400'
                      }`}
                    ></div>
                    <div
                      className={`absolute left-0.5 top-0.5 size-5 rounded-full bg-white transition-transform ${
                        isWaitingEnabled ? 'translate-x-4' : ''
                      }`}
                    ></div>
                  </div>
                  <span className="ml-[3%] text-base">Waiting</span>
                </label>
              </div>
            )}
          </div>
        </div>

        {/* Right Section: QR Scanner + Profile Icon */}
        <div className="relative flex items-center space-x-[8px] pr-8 max-md:pr-4">
          {/* QR Scanner Icon Button */}
          <button
            onClick={() => {
              ReactGA.event({
                category: 'Restaurant Header',
                action: 'QR Scanner Opened',
                label: 'User clicked QR Scanner icon',
              });
              navigate('/scanner');
            }}
            className="rounded-full p-[6px] text-white transition duration-300 hover:bg-white/10 focus:outline-none"
            title="Open QR Scanner"
          >
            <QrCodeIcon className="size-12" />
          </button>

          {/* Profile Icon (no image) - show info on hover */}
          <div
            className="relative"
            onMouseEnter={() => {
              ReactGA.event({
                category: 'Restaurant Header',
                action: 'Profile Popover Displayed',
                label: 'User hovered over profile icon to display profile info',
              });
              setShowProfileInfo(true);
            }}
            onMouseLeave={() => setShowProfileInfo(false)}
          >
            {/* Circular icon button */}
            <button
              className="flex size-8 items-center justify-center
                   rounded-full bg-gray-500 transition duration-300 hover:bg-gray-600
                   focus:outline-none"
              title="User Profile"
            >
              <UserIcon className="size-5 text-white" />
            </button>

            {/* Popover / Dropdown for Profile Info on hover */}
            {showProfileInfo && (
              <div className="absolute right-0 z-10 mt-2 w-48 rounded-md border border-gray-200 bg-white/90 p-3 text-black shadow-lg backdrop-blur-sm">
                {/* User Name */}
                <p className="truncate text-lg font-semibold text-gray-800">
                  {name}
                </p>

                {/* Restaurant Name */}
                <p className="mt-1 truncate text-sm italic text-gray-500">
                  {restaurantContext?.restaurant?.restaurantName}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
