import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_GATEWAY_ENDPOINT } from '../../utilities/Constants';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import CustomerMenuLayout from '../../layout/CustomerMenuLayout';

const UserProfileForm: React.FC = () => {
  const authContext = useContext(AuthContext);
  const user = authContext?.user;

  const [customerName, setCustomerName] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user info on component load
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchCustomerInfo`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.authToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        setCustomerName(data.customerName);
        setCustomerEmail(data.customerEmail);
      } catch {
        toast.error('An error occurred while fetching user info.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSaveChanges = async () => {
    if (!customerName || !customerEmail) {
      toast.error('Please provide valid details.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/updateCustomerInfo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.authToken}`,
          },
          body: JSON.stringify({ customerName, customerEmail }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to update user info');
      }

      toast.success('User information updated successfully!');
      setIsEditing(false);
    } catch {
      toast.error('An error occurred while updating user info.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500" />
    );
  }

  return (
    <>
      <CustomerMenuLayout />

      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="absolute left-6 top-6 z-50 flex items-center rounded-lg bg-gray-800/90 px-4 py-2 text-sm font-semibold text-white shadow-lg transition duration-200 hover:bg-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="mr-2 size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>
      <div className="mt-24 text-4xl font-bold">User Profile</div>

      <div className="mt-8 space-y-6">
        <div>
          <label htmlFor="name" className="mb-2 block font-semibold text-white">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm 
              transition duration-200 ease-in-out
              placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500
              disabled:opacity-50"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>

        {/* Phone Number Field */}
        <div>
          <label
            htmlFor="phone"
            className="mb-2 block font-semibold text-white"
          >
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="9876543210"
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm transition
      duration-200 ease-in-out placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500"
            value={authContext?.user?.username?.replace('+91', '') || ''}
            readOnly
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="mb-2 block font-semibold text-white"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            disabled={!isEditing}
            className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm 
              transition duration-200 ease-in-out 
              placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500
              disabled:opacity-50"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
          />
        </div>

        {isEditing ? (
          <button
            onClick={handleSaveChanges}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 
              ease-in-out hover:from-pink-600 hover:to-orange-600 
              focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            SAVE CHANGES
          </button>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
              font-bold text-white shadow-md transition duration-200 
              ease-in-out hover:from-pink-600 hover:to-orange-600 
              focus:outline-none focus:ring-2 focus:ring-pink-500"
          >
            EDIT
          </button>
        )}
      </div>
      <div className="mt-4 text-center">Powered by Quforia</div>
    </>
  );
};

export default UserProfileForm;
