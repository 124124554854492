import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingSplashScreen from '../screens/LoadingSplashScreen';
import { CUSTOMER_ROLE } from '../utilities/Constants';

interface CustomerPrivateRouteProps {
  children: JSX.Element;
}

// CustomerPrivateRoute Component
export const CustomerPrivateRoute: React.FC<CustomerPrivateRouteProps> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  if (!authContext) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  const { isAuthenticated, user, isLoading } = authContext;

  let isCustomer = false;
  if (user != null && user?.userType === CUSTOMER_ROLE) {
    isCustomer = true;
  }

  const queryParams = new URLSearchParams(location.search);
  let formattedString = '';
  if (Array.from(queryParams.entries()).length > 0) {
    const redirectsToMap = Array.from(queryParams.entries()).find(
      ([key, value]) => key === 'source' && value === 'menu',
    );
    if (redirectsToMap) return <Navigate to="/menu" />;
    formattedString = Array.from(queryParams.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join(' ');
  }

  console.log('Route: ' + queryParams + ' ' + isCustomer);

  return isLoading ? (
    <LoadingSplashScreen />
  ) : isAuthenticated ? (
    isCustomer ? (
      children
    ) : (
      <Navigate to="/restaurant_dashboard" replace />
    )
  ) : (
    <Navigate
      to="/login"
      state={{ from: location, queryString: formattedString }}
      replace
    />
  );
};
