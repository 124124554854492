import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  API_GATEWAY_ENDPOINT,
  RESTAURANT_MAPPING,
} from '../../utilities/Constants';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Reservation } from '../../context/ReservationContext';
import QRCode from 'react-qr-code';
import ReactGA from 'react-ga4';

const UserReservations: React.FC = () => {
  const authContext = useContext(AuthContext);
  const user = authContext?.user;

  const [loading, setLoading] = useState<boolean>(true);

  const [currentReservations, setCurrentReservations] = useState<Reservation[]>(
    [],
  );
  const [pastReservations, setPastReservations] = useState<Reservation[]>([]);
  const [isCancelCTALoading, setIsCancelCTALoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<Reservation | undefined>(
    undefined,
  );

  // New state for past reservations tab
  const [selectedTab, setSelectedTab] = useState<
    'CheckedIn' | 'Expired' | 'Cancelled'
  >('CheckedIn');

  const navigate = useNavigate();

  const renderCancelModal = () => {
    if (!isModalOpen) return null;
    return ReactDOM.createPortal(
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
          {/* Close Button */}
          <button
            onClick={() => closeModal()}
            className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 focus:outline-none"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h3 className="mb-4 text-xl font-semibold text-black">
            Cancel Reservation
          </h3>
          <p className="mb-4 text-gray-800">
            Are you sure you want to cancel the reservation for{' '}
            <span className="font-bold">{selectedEntry?.restaurantName}</span>?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
              onClick={closeModal}
              disabled={isCancelCTALoading}
            >
              No
            </button>
            <button
              className="rounded-md bg-red-500 px-4 py-2 text-white"
              onClick={cancelCustomerReservation}
              disabled={isCancelCTALoading}
            >
              {isCancelCTALoading ? 'Deleting...' : 'Yes'}
            </button>
          </div>
        </div>
      </div>,
      document.body,
    );
  };

  const openModal = (entry: Reservation) => {
    setSelectedEntry(entry);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEntry(undefined);
    setIsModalOpen(false);
  };

  const cancelCustomerReservation = async () => {
    try {
      setIsCancelCTALoading(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/cancelCustomerReservation/${selectedEntry?.restaurantId}/${selectedEntry?.reservationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Customer',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        toast.error(
          'An unknown error happened while removing the reservation, Please try again later.',
        );
        return;
      }

      setCurrentReservations((currentReservations) =>
        currentReservations.filter(
          (r) => r.reservationId != selectedEntry?.reservationId,
        ),
      );

      toast.success('Successfully removed reservation.');
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsCancelCTALoading(false);
    }
  };

  useEffect(() => {
    // Fetch past reservations data
    const fetchPastReservations = async () => {
      try {
        const response = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchAllPastReservations`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.authToken}`,
              role: 'Customer',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch reservations');
        }

        const data = await response.json();
        setLoading(false);
        setPastReservations(data.pastReservationList);
      } catch (error) {
        console.error(
          'An error occured while trying to fetch past reservations.',
          error,
        );
        ReactGA.event({
          category: 'Error',
          action: 'Fetch Past Reservations',
          label: 'UserReservations',
        });
        toast.error('An error occurred while fetching reservations.');
      }
    };

    // Fetch current reservations data
    const fetchCurrentReservations = async () => {
      try {
        const response = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchAllReservations`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.authToken}`,
              role: 'Customer',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch reservations');
        }

        const data = await response.json();
        setCurrentReservations(data.reservationList);
      } catch (error) {
        console.error(
          'An error occured while trying to fetch current reservations.',
          error,
        );
        ReactGA.event({
          category: 'Error',
          action: 'Fetch Current Reservations',
          label: 'UserReservations',
        });
        toast.error('An error occurred while fetching reservations.');
      }
    };

    fetchCurrentReservations();
    fetchPastReservations();
  }, [user]);

  if (loading) {
    return (
      <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500" />
    );
  }

  const orderReservations = (a: Reservation, b: Reservation) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    // Sort in descending order
    return dateB - dateA;
  };

  let filteredPastReservations: Reservation[] = [];

  // Filtered reservations based on status
  if (selectedTab === 'CheckedIn')
    filteredPastReservations = pastReservations
      .filter((res) => res.status === 'CheckedIn')
      .sort(orderReservations);
  else if (selectedTab === 'Expired')
    filteredPastReservations = pastReservations
      .filter((res) => res.status === 'Expired')
      .sort(orderReservations);
  else if (selectedTab === 'Cancelled')
    filteredPastReservations = pastReservations
      .filter((res) => res.status === 'Cancelled')
      .sort(orderReservations);

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="absolute left-6 top-6 z-50 flex items-center rounded-lg bg-gray-800/90 px-4 py-2 text-sm font-semibold text-white shadow-lg transition duration-200 hover:bg-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="mr-2 size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>

      <div className="mt-24 text-4xl font-bold text-white">My Reservations</div>

      {/* Current Reservations */}
      <div className="mt-10">
        <h2 className="mb-4 text-3xl font-bold text-white">
          Current Reservations
        </h2>

        {currentReservations.length > 0 ? (
          currentReservations.map((reservation, index) => (
            <div
              key={index}
              className={`mb-4 rounded-lg p-6 shadow ${
                index % 2 === 0 ? 'bg-white/20' : 'bg-white/10'
              } flex flex-col`}
            >
              {/* Card Heading: Name + Mobile */}
              <div className="mb-2 flex items-center justify-between">
                <h3 className="text-lg font-bold text-white">
                  {reservation.userName}
                </h3>
                <span className="text-sm text-gray-300">
                  {reservation.userId}
                </span>
              </div>

              {/* Guests & Restaurant */}
              <p className="mb-3 text-sm text-gray-300">
                {reservation.noOfPeople} Guest
                {reservation.noOfPeople > 1 ? 's' : ''} at{' '}
                {reservation.restaurantName}
              </p>

              {/* Queue Wait & Check-In */}
              <h4
                className={`mb-4 flex items-center justify-between text-sm font-semibold ${
                  reservation.isEligibleForCheckIn
                    ? 'text-green-500'
                    : 'text-orange-500'
                }`}
              >
                {/* Left Text */}
                <span>
                  {reservation.isEligibleForCheckIn
                    ? 'Please proceed to the restaurant for Check-In'
                    : 'Please wait for Check-In'}
                </span>

                {/* Right-Aligned Text */}
                <span className="text-rose-400">
                  {!reservation.isEligibleForCheckIn
                    ? reservation.peopleAhead > 0
                      ? `${reservation.peopleAhead} people ahead of you`
                      : 'Calculating Queue number...'
                    : ''}
                </span>
              </h4>

              {/* Display QR Code for Check-In Eligibility */}
              {reservation.isEligibleForCheckIn && (
                <div className="mt-4 flex flex-col items-center justify-center">
                  {/* QR Code */}
                  <div className="p-4">
                    <QRCode
                      size={256} // This sets the QR code dimensions explicitly (256x256)
                      style={{ height: '256px', width: '256px' }} // Fix the size using explicit height and width
                      viewBox="0 0 256 256"
                      level="M"
                      fgColor="#D1303D"
                      value={encodeURIComponent(
                        `https://link.quforia.com?customerId=%2B${reservation.userId.replace('+', '')}&action=CustomerID&source=QR`,
                      )}
                    />
                  </div>

                  {/* Instructional Text */}
                  <p className="mt-2 text-center font-medium text-white">
                    Show this QR at the restaurant
                  </p>
                </div>
              )}

              {/* Creation Date */}
              <p className="mb-4 text-xs text-gray-400">
                Created on:{' '}
                {new Date(reservation.createdAt).toLocaleDateString()}
              </p>

              {/* Buttons Container */}
              <div className="mt-auto flex space-x-3">
                {RESTAURANT_MAPPING[reservation.restaurantId].isComingSoon ?? (
                  <button
                    onClick={() =>
                      navigate(
                        `/menu/${encodeURIComponent('tteokbokki hyderabad')}`,
                        { replace: true },
                      )
                    }
                    className="flex-1 rounded-full border border-pink-600 px-4 py-2 font-semibold text-pink-600 transition hover:bg-pink-600 hover:text-white"
                  >
                    See the Menu
                  </button>
                )}
                <button
                  onClick={() => openModal(reservation)}
                  className="flex-1 rounded-full border border-red-600 px-4 py-2 font-semibold text-red-600 transition hover:bg-red-600 hover:text-white"
                >
                  Cancel
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-gray-400">
            No current reservations available.
          </div>
        )}
      </div>

      {/* Past Reservations */}
      <div className="mt-10">
        <h2 className="mb-4 text-3xl font-bold text-gray-300">
          Past Reservations
        </h2>

        {/* Tab Selector */}
        <div className="mb-4 flex space-x-4">
          <button
            onClick={() => setSelectedTab('CheckedIn')}
            className={`rounded-lg px-4 py-2 text-sm font-semibold transition duration-200 ${
              selectedTab === 'CheckedIn'
                ? 'bg-gray-300 text-gray-800'
                : 'bg-white/10 text-gray-300 hover:bg-white/20'
            }`}
          >
            Checked In
          </button>
          <button
            onClick={() => setSelectedTab('Expired')}
            className={`rounded-lg px-4 py-2 text-sm font-semibold transition duration-200 ${
              selectedTab === 'Expired'
                ? 'bg-gray-300 text-gray-800'
                : 'bg-white/10 text-gray-300 hover:bg-white/20'
            }`}
          >
            Expired
          </button>
          <button
            onClick={() => setSelectedTab('Cancelled')}
            className={`rounded-lg px-4 py-2 text-sm font-semibold transition duration-200 ${
              selectedTab === 'Cancelled'
                ? 'bg-gray-300 text-gray-800'
                : 'bg-white/10 text-gray-300 hover:bg-white/20'
            }`}
          >
            Cancelled
          </button>
        </div>

        {/* Card Container */}
        <div>
          {filteredPastReservations.length > 0 ? (
            filteredPastReservations.map((reservation, index) => (
              <div
                key={index}
                className={`mb-4 rounded-lg p-4 ${
                  index % 2 === 0 ? 'bg-white/20' : 'bg-white/10'
                }`}
              >
                {/* Heading: Name + Mobile */}
                <div className="mb-1 flex items-center justify-between">
                  <h3 className="text-base font-bold text-gray-100">
                    {reservation.userName}
                  </h3>
                  <span className="text-sm text-gray-400">
                    {reservation.userId}
                  </span>
                </div>

                {/* Guest & Restaurant info */}
                <p className="mb-1 text-sm text-gray-300">
                  {reservation.noOfPeople} Guests at{' '}
                  {reservation.restaurantName}
                </p>
                {/* Completed Date */}
                <p className="text-xs text-gray-400">
                  Completed on:{' '}
                  {new Date(reservation.createdAt).toLocaleDateString()}
                </p>
              </div>
            ))
          ) : (
            <div className="text-gray-400">No reservations available.</div>
          )}
        </div>
      </div>

      <div className="mt-4 text-center text-gray-400">Powered by Quforia</div>
      {renderCancelModal()}
    </div>
  );
};

export default UserReservations;
