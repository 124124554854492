// src/context/ReservationContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { AuthContext } from './AuthContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';

// Define the Reservation interface
export interface Restaurant {
  restaurantId: string;
  restaurantName: string;
  image: string;
  maxRetries: number;
  maxWaitingTimeInMinutes: number;
  subscriptionEndDate: string;
  isWaitingEnabled: boolean;
  isRestaurantOpen: boolean;
  takesOnlineReservations: boolean;
  maxGroupSize: number;
  slotDurationInMinutes: number;
}

// Define the context properties
interface RestaurantContextProps {
  restaurant?: Restaurant;
  setRestaurant: (restaurant: Restaurant) => void;
}

export const RestaurantContext = createContext<
  RestaurantContextProps | undefined
>(undefined);

interface RestaurantProviderProps {
  children: ReactNode;
}

export const RestaurantProvider: React.FC<RestaurantProviderProps> = ({
  children,
}) => {
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    // Silently keep reloading
    // Fetch reservations initially
    if (
      authContext?.isAuthenticated &&
      authContext.user?.userType === 'Restaurant'
    ) {
      getRestaurantInfo();

      // Set an interval to fetch reservations every 5 seconds
      const interval = setInterval(() => {
        getRestaurantInfo();
      }, 5000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [authContext?.isAuthenticated]);

  const getRestaurantInfo = async () => {
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchRestaurantInfo`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch RestaurantInfo.');
      }

      setRestaurant(await response.json());
    } catch (err) {
      console.error('Error getting restaurant info: ', err);
    }
  };

  const value = {
    restaurant,
    setRestaurant,
  };

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
