import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  API_GATEWAY_ENDPOINT,
  RESTAURANT_MAPPING,
} from '../utilities/Constants';
import { toast } from 'react-toastify';
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import { CheckIcon } from '@heroicons/react/24/outline';
import LinthImage from '../assets/Linth.png';
import Seven_Sisters_Hyd from '../assets/teokbokki_hyd.jpg';

const CustomerReservationForm: React.FC = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('Login must be used within an AuthProvider');
  }

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const hotel = queryParams.get('restaurantId');
  const source = queryParams.get('source');

  const [phone, setPhone] = useState<string>('');
  const [overridenCheckIn, setOverridenCheckIn] = useState<boolean>(false);
  const [restaurantIdForCustomer, setRestaurantIdForCustomer] = useState('');
  const [username, setUsername] = useState<string>('');
  const [tableFor, setTableFor] = useState<string>('4');
  const [isSuccessfulReservation, setIsSuccessfulReservation] =
    useState<boolean>(false);
  const [isRepeatedReservation, setIsRepeatedReservation] =
    useState<boolean>(false);
  const [currentWaitingTime, setCurrentWaitingTime] = useState<number>(-1);
  const [peopleInQueue, setPeopleInQueue] = useState<number>(-1);

  // Create refs
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const guestsSelectRef = useRef<HTMLSelectElement | null>(null);

  const getAvgWaitingTime = async (restaurantId: string) => {
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchRestaurantCurrentWaitingTime/${restaurantId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Customer',
            Authorization: 'Bearer ' + authContext.user?.authToken,
          },
        },
      );
      const { currentWaitingTime, peopleInQueue } = await response.json();
      setCurrentWaitingTime(currentWaitingTime);
      setPeopleInQueue(peopleInQueue);
    } catch (err) {
      console.error('Error getting avg wait time from the server: ', err);
    }
  };

  useEffect(() => {
    if (source === 'menu' && hotel) {
      navigate(`/menu/${encodeURIComponent(hotel)}`, { replace: true });
      return;
    }
    const storedCustomerId = authContext.user?.username;
    if (storedCustomerId) setPhone(storedCustomerId);
    if (hotel) setRestaurantIdForCustomer(hotel);
    else navigate('/customer_dashboard');
    getAvgWaitingTime(hotel!);
  }, []);

  const reserve = async (token: string) => {
    if (username.length === 0) {
      toast.error('Please enter your name to proceed!');
      return;
    }
    const makeReservationResponse = await fetch(
      `${API_GATEWAY_ENDPOINT}/makeReservation`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          role: 'Customer',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          restaurantId: restaurantIdForCustomer,
          customerName: username,
          numberOfPeople: tableFor,
          source: source === 'qrcode' ? 'qrcode' : 'online',
        }),
      },
    );

    if (!makeReservationResponse.ok) {
      if (makeReservationResponse.status === 403) {
        toast.info('You are already in the Q!', {
          position: 'bottom-center',
          delay: 3000,
        });
        setIsSuccessfulReservation(true);
        setIsRepeatedReservation(true);
      } else {
        toast.error('Error making reservation, please check the QR scanned.');
      }
    } else {
      const data = await makeReservationResponse.json();
      const { overrideCheckedIn } = data;
      if (overrideCheckedIn) {
        toast.success('🎉 Prioritised your booking! 🏃 Proceed to the table', {
          position: 'bottom-center',
          delay: 2000,
        });
      } else {
        toast.success('Reservation made successfully!', {
          position: 'bottom-center',
          delay: 2000,
        });
      }
      setOverridenCheckIn(overrideCheckedIn);
      setIsSuccessfulReservation(true);
    }
  };

  return (
    <>
      <LoopedBackground />
      <div className="relative md:flex md:flex-row md:items-center md:justify-between md:align-middle">
        {/* Back/Home Button */}
        <button onClick={() => navigate('/customer_dashboard')} /* ...etc... */>
          {/* SVG + label */}
        </button>

        <div className="w-full flex-1">
          <div className="flex w-full flex-col items-center justify-center align-middle">
            {restaurantIdForCustomer && (
              <img
                src={
                  RESTAURANT_MAPPING[restaurantIdForCustomer]?.image ??
                  Seven_Sisters_Hyd
                }
                className="mt-12 size-56 self-center max-sm:size-36"
                alt="Merchant Logo"
              />
            )}

            {/* 
              --- ADD THE MARQUEE HERE ---
              We'll show it only if we have valid metrics 
            */}
            {(currentWaitingTime >= 0 || peopleInQueue >= 0) && (
              <div className="marquee-container my-6 w-full px-4">
                <div className="marquee-content">
                  {`Waiting time: ${
                    currentWaitingTime >= 0 ? currentWaitingTime : 'N/A'
                  } mins | People in Q: ${
                    peopleInQueue >= 0 ? peopleInQueue : 'N/A'
                  }`}
                </div>
              </div>
            )}

            {/* If not successful, show the form */}
            {!isSuccessfulReservation && !isRepeatedReservation ? (
              <div className="mt-4 w-full space-y-6 px-12">
                {/* Name Field */}
                <div>
                  <label
                    htmlFor="name"
                    className="mb-2 block font-semibold text-white"
                  >
                    Name
                  </label>
                  <input
                    ref={nameInputRef}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm
                                            transition duration-200 ease-in-out placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500"
                    onChange={(e) => setUsername(e.target.value)}
                    // When user presses Enter on mobile, move to # of guests
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        guestsSelectRef.current?.focus();
                      }
                    }}
                  />
                </div>

                {/* Phone Number Field */}
                <div>
                  <label
                    htmlFor="phone"
                    className="mb-2 block font-semibold text-white"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="9876543210"
                    className="w-full rounded-md bg-white/20 px-4 py-3 text-white shadow-sm transition
                                            duration-200 ease-in-out placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500"
                    value={phone.replace('+91', '')}
                    onChange={(e) => setPhone(e.target.value)}
                    readOnly={authContext.isAuthenticated}
                  />
                </div>

                {/* Guests Selection */}
                <div>
                  <label
                    htmlFor="guests"
                    className="mb-2 block font-semibold text-white"
                  >
                    # of Guests
                  </label>
                  <div className="relative">
                    <select
                      ref={guestsSelectRef}
                      id="guests"
                      name="guests"
                      className="w-full appearance-none rounded-md bg-white/20 px-4 py-3 text-white
                                                shadow-sm transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-500"
                      value={tableFor}
                      onChange={(e) => setTableFor(e.target.value)}
                    >
                      {Array.from({ length: 25 }, (_, i) => i + 1).map((n) => (
                        <option
                          value={n.toString()}
                          key={n}
                          className="bg-white text-black"
                        >
                          {n}
                        </option>
                      ))}
                    </select>
                    {/* arrow icon */}
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <svg
                        className="size-4 fill-current text-white"
                        viewBox="0 0 20 20"
                      >
                        <path d="M5.23 7.21a.75.75 0 00-1.06 1.06L9.485 13.6a.75.75 0 001.06 0L15.83 8.27a.75.75 0 00-1.06-1.06L10 11.02 5.23 7.21z" />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                {authContext.user?.authToken && (
                  <div className="mb-8 flex justify-end">
                    <button
                      type="submit"
                      onClick={() =>
                        authContext.user?.authToken &&
                        reserve(authContext.user.authToken)
                      }
                      className="rounded-md bg-pink-500 px-6 py-3 font-semibold text-white shadow-sm
                                            transition duration-200 ease-in-out hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
                    >
                      Reserve Table
                    </button>
                  </div>
                )}
              </div>
            ) : (
              /* The success / repeated-reservation state */
              <div className="flex flex-col items-center justify-center px-8 text-center align-middle text-white">
                <CheckIcon className="size-16 font-bold" strokeWidth={5} />
                <div className="mt-2 text-2xl font-semibold">
                  {isRepeatedReservation
                    ? 'We already have your reservation'
                    : 'Your reservation has been taken!'}
                </div>
                {!overridenCheckIn && (
                  <div className="pt-8">
                    Go to{' '}
                    <button
                      onClick={() =>
                        navigate('/user_reservations', { replace: true })
                      }
                      className="mx-2 rounded-full border border-gray-300 px-4 py-1 font-semibold text-blue-600 hover:bg-gray-100"
                    >
                      My Bookings
                    </button>{' '}
                    to check the status of reservation
                  </div>
                )}
                {!overridenCheckIn &&
                  !RESTAURANT_MAPPING[restaurantIdForCustomer]
                    ?.isComingSoon && (
                    <div className="pt-12">
                      Browse the{' '}
                      <button
                        onClick={() =>
                          navigate(
                            `/menu/${encodeURIComponent(restaurantIdForCustomer)}`,
                            { replace: true },
                          )
                        }
                        className="mx-2 rounded-full border border-gray-300 px-4 py-1 font-semibold text-pink-600 hover:bg-gray-100"
                      >
                        Menu
                      </button>{' '}
                      while you wait for your turn!
                    </div>
                  )}
                {overridenCheckIn &&
                  !RESTAURANT_MAPPING[restaurantIdForCustomer]
                    ?.isComingSoon && (
                    <div className="pt-12">
                      {' '}
                      🎉🎉 We prioritised your booking! 🏃🏃 Proceed to check-in{' '}
                      <button
                        onClick={() =>
                          navigate(
                            `/menu/${encodeURIComponent(restaurantIdForCustomer)}`,
                            { replace: true },
                          )
                        }
                        className="mx-2 rounded-full border border-gray-300 px-4 py-1 font-semibold text-pink-600 hover:bg-gray-100"
                      >
                        Menu
                      </button>{' '}
                      while you wait for your turn!
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>

        {/* Right-side decorative image */}
        <div className="flex flex-1 items-center justify-center align-middle max-md:hidden">
          <img src={LinthImage} alt="decorative" style={{ height: '80vh' }} />
        </div>
      </div>
    </>
  );
};

export default CustomerReservationForm;
