// src/components/NewTableForm.tsx
import React, { useState, useContext, useEffect } from 'react';
import { TableContext } from '../context/TableContext';
import { AuthContext, User } from '../context/AuthContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { toast } from 'react-toastify';
import { RestaurantContext } from '../context/RestaurantContext';

interface NewTableFormProps {
  user?: User;
}

const NewTableForm: React.FC<NewTableFormProps> = () => {
  const context = useContext(TableContext);
  const authContext = useContext(AuthContext);
  const restaurantContext = useContext(RestaurantContext);

  if (!context) {
    throw new Error('NewTableForm must be used within a TableProvider');
  }

  const [isWaitingDisabled, setIsWaitingDisabled] = useState(false);

  useEffect(() => {
    if (
      restaurantContext?.restaurant &&
      restaurantContext.restaurant.isWaitingEnabled
    ) {
      setIsWaitingDisabled(false);
    } else {
      setIsWaitingDisabled(true);
    }
  }, [restaurantContext?.restaurant]);

  const { addTable } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [minCapacity, setMinCapacity] = useState<string>('');
  const [maxCapacity, setMaxCapacity] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const minCapacityNumber = parseInt(minCapacity, 10);
    if (isNaN(minCapacityNumber) || minCapacityNumber <= 0) {
      alert('Please enter a valid number for min capacity.');
      setLoading(false);
      return;
    }

    const maxCapacityNumber = parseInt(maxCapacity, 10);
    if (isNaN(maxCapacityNumber) || maxCapacityNumber <= 0) {
      alert('Please enter a valid number for max capacity.');
      setLoading(false);
      return;
    }

    if (minCapacityNumber > maxCapacityNumber) {
      toast.error('Error: max is less than min Capacity');
      setLoading(false);
      return;
    }

    const response = await fetch(
      `${API_GATEWAY_ENDPOINT}/createAvailableTable`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          role: 'Restaurant',
          Authorization: 'Bearer ' + authContext?.user?.authToken,
        },
        body: JSON.stringify({
          minimumPeopleAllowed: minCapacityNumber,
          maximumPeopleAllowed: maxCapacityNumber,
        }),
      },
    );

    setLoading(false);

    if (!response.ok) {
      toast.error(
        'An unknown error happened while creating a table, Please try again later.',
      );
      return;
    }

    toast.success('Successfully added table.');
    addTable({
      tableId: 'UUID',
      minimumPeopleAllowed: maxCapacityNumber,
      maximumPeopleAllowed: maxCapacityNumber,
    });
    setMinCapacity('');
    setMaxCapacity('');
  };

  return (
    <div className="h-full rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md">
      <h2 className="mb-4 text-2xl font-bold max-sm:text-base">
        Add New Table
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="mb-1 block font-semibold">Min Capacity</label>
          <input
            type="number"
            value={minCapacity}
            onChange={(e) => setMinCapacity(e.target.value)}
            placeholder="Enter Min Capacity"
            className={`w-full rounded-md bg-white/20 px-4 py-2 text-white placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 ${
              isWaitingDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            required
            disabled={isWaitingDisabled || loading}
          />
        </div>
        <div>
          <label className="mb-1 block font-semibold">Max Capacity</label>
          <input
            type="number"
            value={maxCapacity}
            onChange={(e) => setMaxCapacity(e.target.value)}
            placeholder="Enter Max Capacity"
            className={`w-full rounded-md bg-white/20 px-4 py-2 text-white placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 ${
              isWaitingDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            required
            disabled={isWaitingDisabled || loading}
          />
        </div>
        {loading ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <button
            type="submit"
            className={`rounded-md bg-gradient-to-r from-pink-500 to-orange-500 px-4 py-2 font-bold text-white shadow-md hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-pink-500 ${
              isWaitingDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            disabled={isWaitingDisabled || loading}
          >
            Add Table
          </button>
        )}
      </form>
    </div>
  );
};

export default NewTableForm;
