import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_GATEWAY_ENDPOINT, CUSTOMER_ROLE } from '../utilities/Constants';
import merchantlogo from '../assets/merchantLogo.png';
import useAuthRedirect from '../hooks/AuthRedirect';
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import LinthImage from '../assets/Linth.png';

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';
  const queryString = location.state?.queryString || '';

  useAuthRedirect();

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setPhoneNumber(event.target.value);
    setErrorMessage('');
  };

  const handleLogin = async () => {
    if (!phoneNumber) {
      toast.error('Please enter a phone number.');
      return;
    }
    let phoneNumberToSend = phoneNumber.replace(/\D/g, '');
    if (phoneNumberToSend.length !== 10) {
      if (
        phoneNumberToSend.length === 12 &&
        phoneNumberToSend.startsWith('91')
      ) {
        phoneNumberToSend = phoneNumber.slice(2);
      } else if (
        phoneNumberToSend.length === 13 &&
        phoneNumberToSend.startsWith('+91')
      ) {
        phoneNumberToSend = phoneNumber.slice(3);
      } else {
        toast.error('Please enter a valid 10 digit mobile number');
        return;
      }
    }

    phoneNumberToSend = '+91' + phoneNumberToSend;
    setLoading(true);

    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/validateRestaurant/${phoneNumberToSend}`,
        {
          method: 'GET',
        },
      );

      if (response.ok) {
        // If phone number exists, navigate to the login option screen
        navigate('/login_options', {
          state: { phoneNumber: phoneNumberToSend.slice(3), from, queryString },
        });
      } else {
        // If phone number does not exist, navigate to OTP verification page
        navigate('/verify_otp', {
          state: {
            phoneNumber: phoneNumberToSend.slice(3),
            role: CUSTOMER_ROLE,
            from,
            queryString,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error occured while trying to login: ', error);
      setErrorMessage(
        `An error occurred while processing your request. Please try again. ${error}`,
      );
      setLoading(false);
    }
  };

  return (
    <>
      <LoopedBackground />
      <div className="relative w-full md:flex md:flex-row md:items-center md:justify-between md:align-middle">
        <div className="flex flex-col md:mr-32">
          <img
            src={merchantlogo}
            className="mb-16 size-56 self-center max-sm:size-44"
            alt="Merchant Logo"
          />
          <div className="min-lg:mt-24 mt-12 text-4xl font-bold">Welcome</div>
          <div className="mt-8 text-sm">
            Enter your details to get en’queued for your meal at our restaurant.
          </div>
          <div className="space-y-6">
            <form
              style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}
              onSubmit={() => handleLogin()}
            >
              <label
                htmlFor="phone"
                className="mb-2 block font-semibold text-white"
              >
                Phone Number
              </label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter your phone number"
                className="
                                w-full rounded-md bg-white/20
                                px-4 py-3 text-white
                                shadow-sm transition
                                duration-200 ease-in-out placeholder:text-gray-300
                                focus:outline-none focus:ring-2 focus:ring-pink-500"
                disabled={loading} // Disable input while loading
              />
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <button
                onClick={handleLogin}
                className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
                                        font-bold text-white shadow-md transition duration-200
                                        ease-in-out hover:from-pink-600 hover:to-orange-600
                                        focus:outline-none focus:ring-2 focus:ring-pink-500"
                disabled={loading} // Disable button while loading
              >
                {loading ? 'Loading...' : 'Continue'}
              </button>
              {loading && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <span>Loading, please wait...</span>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="max-md:hidden md:ml-32">
          <img src={LinthImage} alt="decorative" style={{ height: '80vh' }} />
        </div>
      </div>
    </>
  );
};

export default Login;
