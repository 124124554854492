import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import useAuthRedirect from '../hooks/AuthRedirect';
import merchantlogo from '../assets/merchantLogo.png';
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import LinthImage from '../assets/Linth.png';

const VerifyOTP: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [otp, setOtp] = useState<string>(''); // Store OTP as a string

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  useAuthRedirect(); // Redirect if already authenticated

  // Retrieve the phone number and other info from the previous page (passed via state)
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;
  const role = location.state?.role;
  const from = location.state?.from || '/';
  const queryString = location.state?.queryString || '';
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('Login must be used within an AuthProvider');
  }

  useEffect(() => {
    if (!location.state?.phoneNumber || !location.state?.role) {
      navigate('/');
    } else {
      sendOTP();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // If timer is running, set up interval
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1 && timerRef.current) {
            clearInterval(timerRef.current);
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [timeLeft]);

  const sendOTP = async () => {
    if (role !== 'Customer' && role !== 'Restaurant') {
      toast.error(
        'Some error has occurred, please scan the QR and start over.',
      );
      return;
    }
    if (!phoneNumber) {
      toast.error('Please enter a phone number.');
      return;
    }
    if (phoneNumber.length !== 10) {
      toast.error('Please enter a valid 10 digit mobile number');
      return;
    }

    const phoneNumberToSend = '+91' + phoneNumber;
    setLoading(true);

    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/sendOTP/${phoneNumberToSend}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }

      toast.success('OTP sent successfully!');
      setLoading(false);
      setTimeLeft(30); // Reset countdown
    } catch (error) {
      toast.error(
        `An internal error occurred, please try again later ${error}`,
      );
      setLoading(false);
    }
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 6); // Allow only digits and limit to 6 chars
    setOtp(value);

    // If OTP is 6 digits, try verifying it automatically
    if (value.length === 6) {
      verifyOtp(value);
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length < 6) {
      toast.error('Please enter the complete 6-digit OTP');
      return;
    }
    verifyOtp(otp);
  };

  const verifyOtp = async (otp: string) => {
    const phoneNumberToSend = '+91' + phoneNumber;
    setLoading(true);

    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/verifyOTP/${phoneNumberToSend}/${otp}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: role,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Incorrect OTP, please try again.');
      }

      setLoading(false);
      const data = await response.json();
      const { token } = data;

      if (response.ok) {
        authContext.login(phoneNumberToSend, token, role);
        const url = `${from}?${queryString.replace(/ /g, '&')}`;
        navigate(url, { replace: true });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Incorrect OTP. Please try again.');
    }
  };

  return (
    <>
      <LoopedBackground />
      <div className="md:flex md:flex-row md:items-center md:justify-around md:align-middle">
        {/* Back Button */}
        <div>
          <button
            onClick={() => navigate(-1)}
            className="absolute left-6 top-6 z-50 flex items-center rounded-lg bg-gray-800/90 px-4
                                   py-2 text-sm font-semibold text-white shadow-lg
                                   transition duration-200 hover:bg-gray-700 max-md:left-2 max-md:top-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="mr-2 size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back
          </button>

          <div className="mt-8 flex flex-col items-center">
            <img
              src={merchantlogo}
              className="mb-16 size-56 self-center max-sm:size-44"
              alt="Merchant Logo"
            />

            <div className="mb-4 text-center text-lg">
              Enter the 6-digit OTP sent to {phoneNumber}
            </div>

            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              maxLength={6}
              inputMode="numeric"
              pattern="[0-9]*"
              className="h-12 w-48 rounded-md bg-white/20 text-center text-xl text-white shadow-sm transition duration-200 ease-in-out placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500"
              placeholder="Enter OTP"
            />

            {/* Verify Button */}
            <button
              onClick={handleVerifyOtp}
              disabled={loading}
              className="mt-6 w-full rounded-md bg-gradient-to-r from-pink-500 to-orange-500 py-3
                                       font-bold text-white shadow-md 
                                       transition duration-200
                                       ease-in-out hover:from-pink-600 hover:to-orange-600
                                       focus:outline-none focus:ring-2 focus:ring-pink-500
                                       disabled:cursor-not-allowed disabled:opacity-50"
            >
              VERIFY
            </button>

            {/* Resend OTP logic */}
            <div className="mt-4 flex flex-col items-center">
              {timeLeft > 0 ? (
                <div className="text-sm text-gray-200">
                  Resend OTP in <span className="font-bold">{timeLeft}s</span>
                </div>
              ) : (
                <button
                  onClick={sendOTP}
                  disabled={loading}
                  className="mt-2 rounded-md bg-gradient-to-r from-gray-600 to-gray-700 px-8
                                               py-2 font-semibold text-white
                                               transition duration-200
                                               ease-in-out hover:from-gray-500 hover:to-gray-600
                                               focus:outline-none focus:ring-2 focus:ring-gray-500
                                               disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Resend OTP
                </button>
              )}
            </div>

            {loading && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <span>Loading, please wait...</span>
              </div>
            )}
          </div>
        </div>

        {/* Decorative image on the right (hidden on smaller screens) */}
        <div className="max-md:hidden md:ml-32">
          <img src={LinthImage} alt="decorative" style={{ height: '80vh' }} />
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
