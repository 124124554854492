import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import LoadingSplashScreen from '../screens/LoadingSplashScreen';
import { RESTAURANT_ROLE } from '../utilities/Constants';

interface RestaurantPrivateRouteProps {
  children: JSX.Element;
}

export const RestaurantPrivateRoute: React.FC<RestaurantPrivateRouteProps> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  const { isAuthenticated, user, isLoading } = authContext;

  if (isLoading) {
    return <LoadingSplashScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Check if the user is a Restaurant role
  if (user?.userType === RESTAURANT_ROLE) {
    const currentPath = window.location.pathname;

    // Redirect logic based on subRole
    switch (user.subRole) {
      case 'Valet':
        // Allow only /scanner and /valet for Valet subrole
        if (!['/scanner', '/valet'].includes(currentPath)) {
          return <Navigate to="/valet" replace />;
        }
        break;

      case 'Reception':
        // Bar Reception from accessing /valet
        if (currentPath === '/valet') {
          return <Navigate to="/reservations" replace />;
        }
        break;

      case 'Admin':
        // Admin has unrestricted access; do nothing
        break;

      default:
        // If no specific rules are defined, redirect to reservations
        return <Navigate to="/reservations" replace />;
    }

    // Restrict /staff page to Admin subRole
    if (currentPath === '/staff' && user.subRole !== 'Admin') {
      return user.subRole === 'Reception' ? (
        <Navigate to="/reservations" replace />
      ) : (
        <Navigate to="/valet" replace />
      );
    }

    // If all conditions are satisfied, render the child components
    return children;
  }

  // If not a Restaurant role, redirect to customer dashboard
  return <Navigate to="/customer_dashboard" replace />;
};
