import React from 'react';

import { RestaurantHeader } from '../components/Hamburger/RestaurantHeader';
import AvailableTables from '../components/AvailableTables';

const AvailableTablesListing: React.FC = () => {
  return (
    <div className="grow bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      {/* Header */}
      <RestaurantHeader />
      {/* Content */}
      <AvailableTables />
    </div>
  );
};

export default AvailableTablesListing;
