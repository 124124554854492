import React, { useRef, useEffect, useState, useContext } from 'react';
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Reservation, ReservationContext } from '../context/ReservationContext'; // Adjust the path as needed
import {
  API_GATEWAY_ENDPOINT,
  INVALID_CODE_TOAST_ID,
} from '../utilities/Constants';
import { AuthContext } from '../context/AuthContext';
import { ValetParkingContext } from '../context/ValetParkingContext';
import { toast } from 'react-toastify';
import { RestaurantContext } from '../context/RestaurantContext';
import { CheckInItemContext } from '../context/CheckInContext';

export interface CustomerScannable {
  type: string;
  customerId: string;
}

export interface ValetReleaseScannable {
  type: string;
  parkingToken: string;
  secretToken: string;
  customerId: string;
}

export interface InvalidScannable {
  type: string;
  action: string;
}

enum ModalMode {
  CheckingInReservation = 'CheckingInReservation',
  CheckingInVehicle = 'CheckingInVehicle',
  CheckedInVehicle = 'CheckedInVehicle',
  ReleasingVehicle = 'ReleasingVehicle',
  ReleasedVehicle = 'ReleasedVehicle',
  CustomerScan = 'CustomerScan',
  None = 'None',
}

const QRScanner: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [vehicleSubmitted, setVehicleSubmitted] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.None);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string | undefined>(undefined);
  const [reservation, setReservation] = useState<Reservation | undefined>(
    undefined,
  );
  const [isCheckInEligible, setIsCheckInEligible] = useState<boolean>(false);
  const [vehicleToken, setVehicleToken] = useState<string | undefined>(
    undefined,
  );
  const [secretToken, setSecretToken] = useState<string | undefined>(undefined);

  const authContext = useContext(AuthContext);
  const valetContext = useContext(ValetParkingContext);
  const { reservations } = useContext(ReservationContext)!;
  const { restaurant } = useContext(RestaurantContext)!;
  const { checkIns, setCheckIns } = useContext(CheckInItemContext)!;
  const valet = valetContext?.valetParkings;
  const navigate = useNavigate();

  const handleScan = async (result: QrScanner.ScanResult) => {
    if (modalMode != ModalMode.None) return;
    if (result?.data) {
      const payload = decodeURIComponent(result?.data);
      const parsed = parseQRCode(payload);
      if (parsed.type === 'InvalidCode')
        if (!toast.isActive(INVALID_CODE_TOAST_ID))
          toast.error('Invalid QR Code Scanned', {
            toastId: INVALID_CODE_TOAST_ID,
          });
      switch (parsed.type) {
        case 'ReleaseVehicle': {
          // Show Release Vehicle Modal
          setModalMode(ModalMode.ReleasingVehicle);
          setCustomerId(parsed.customerId!);
          setVehicleToken(parsed.parkingToken!);
          setSecretToken(parsed.secretToken!);
          setIsModalOpen(true);
          break;
        }
        case 'CustomerID': {
          setModalMode(ModalMode.CustomerScan);
          getCustomerValetDetails(parsed.customerId!);
          setCustomerId(parsed.customerId!);
          // Check if this customer has a reservation
          const reservation = reservations.find(
            (res) => res.userId === parsed.customerId,
          );
          if (reservation) {
            const isCheckInEligible =
              reservations &&
              (!restaurant!.isWaitingEnabled ||
                reservation!.isEligibleForCheckIn);
            setReservation(reservation);
            setIsCheckInEligible(isCheckInEligible);
          }
          setIsModalOpen(true);
          break;
        }
      }
    }
  };
  const handleScanRef = useRef(handleScan); // Ref to hold the latest handleScan function

  // Update the ref when handleScan or its dependencies change
  useEffect(() => {
    console.log('Caught Restaurant: ', restaurant);

    handleScanRef.current = handleScan;
  }, [restaurant, reservations, checkIns, valet]);

  useEffect(() => {
    let qrScanner: QrScanner;

    if (videoRef.current) {
      qrScanner = new QrScanner(
        videoRef.current,
        (result) => handleScanRef.current(result),
        { preferredCamera: 'environment' },
      );
      qrScanner
        .start()
        .catch((err) => console.error('Error starting QR scanner', err));
    }

    return () => {
      qrScanner?.stop();
    };
  }, [modalMode, setModalMode]);

  const parseQRCode = (urlString: string) => {
    try {
      const url = new URL(urlString);
      // Validate domain: accept quforia.com, www.quforia.com, and subdomains
      const domainPattern = /(\w+\.)*quforia\.com$/;
      if (!domainPattern.test(url.hostname)) {
        return { action: 'Unknown', type: 'InvalidCode' };
      }
      // Check query parameters
      const action = url.searchParams.get('action');
      if (!action) return { action: 'Unknown', type: 'InvalidCode' };

      if (action === 'ReleaseVehicle') {
        const customerId = url.searchParams.get('customerId');
        const secretToken = url.searchParams.get('secretToken');
        const parkingToken = url.searchParams.get('parkingToken');
        if (!customerId || !secretToken || !parkingToken)
          return { action, type: 'InvalidCode' };
        return {
          type: 'ReleaseVehicle',
          customerId,
          secretToken,
          parkingToken,
        };
      }

      if (action === 'CustomerID') {
        const customerId = url.searchParams.get('customerId');
        if (!customerId) return { action, type: 'InvalidCode' };
        return { type: 'CustomerID', customerId };
      }

      return { action, type: 'InvalidCode' };
    } catch (error) {
      console.error('Error routing the scanned content', error);
      return { action: 'Unknown', type: 'InvalidCode' };
    }
  };

  const closeModal = () => {
    setCustomerId(undefined);
    setVehicleToken(undefined);
    setSecretToken(undefined);
    setReservation(undefined);
    setIsCheckInEligible(false);
    setIsModalOpen(false);
    setModalMode(ModalMode.None);
  };

  const handleCheckInReservation = async () => {
    // Handle your "Check In Reservation" logic here
    // For example, call an API to mark the reservation as checked in
    console.log('Check In Reservation clicked for customerId:', customerId);
    // On success you could close the modal or show a success message

    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/checkInReservation/${customerId}/${reservation?.reservationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
          body: JSON.stringify({
            userName: reservation?.userName,
            noOfPeople: reservation?.noOfPeople,
          }),
        },
      );

      if (response.ok) {
        // Update the context to remove the checked-in entry
        setCheckIns(checkIns!.filter((entry) => entry.userId !== customerId));
        toast.success('User checked in successfully!');
      } else {
        toast.error('Failed to check in the entry.');
      }
      navigate('/reservations');
    } catch (error) {
      console.error('[CRITICAL] Error checking in:', error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleCheckInVehicle = async () => {
    // Toggle vehicle form
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/createValetParking/${customerId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to create a valet parking.');
      }
      const data = await response.json();
      setVehicleToken(data.uniqueValetParkingCode);
      setModalMode(ModalMode.CheckedInVehicle);
      toast.success('Vehicle checked in successfully');
    } catch (error) {
      console.error(
        '[CRITICAL] Error checking in the vehicle at valet:',
        error,
      );
      toast.error('Error checking in the vehicle');
      closeModal();
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  const releaseVehicle = async (
    customerId: string,
    uniqueValetParkingCode: string,
    secretToken: string,
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/releaseValetParking/${customerId}/${secretToken}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (response.ok) {
        // Update the context to remove the valet entry
        valetContext?.setValetParkings(
          valet!.filter(
            (entry) =>
              entry.customerId + entry.uniqueValetParkingCode !==
              customerId + uniqueValetParkingCode,
          ),
        );
        toast.success(
          'Valet released successfully! Please handover the vehicle',
          { toastId: 'RELEASED_VEHICLE_SUCCESSFULLY' },
        );
      } else {
        throw new Error('Response not okay');
      }
    } catch (error) {
      if (!toast.isActive('RELEASED_VEHICLE_SUCCESSFULLY')) {
        console.error('[CRITICAL] Error releasing the vehicle: ', error);
        toast.error('Failed to release the entry. Please try again!');
      }
    } finally {
      setIsLoading(false);
      setModalMode(ModalMode.ReleasedVehicle);
    }
  };

  const getCustomerValetDetails = async (customerId: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/validateCustomerValetParking/${customerId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (response.ok) {
        setVehicleSubmitted(true);
      }
    } catch (error) {
      console.error(
        'An error occured while trying to get the CustomerValet details',
        error,
      );
      setVehicleSubmitted(false);
    } finally {
      setIsLoading(false);
    }
  };

  const modalContent = () => {
    switch (modalMode) {
      case ModalMode.CustomerScan: {
        const isVehicleSubmittedHere = valetContext?.valetParkings.some(
          (entry) => entry.customerId === customerId,
        );
        return (
          <div className="relative p-4">
            {isLoading ? (
              // Loading Animation
              <div className="flex h-64 items-center justify-center">
                <div className="size-10 animate-spin rounded-full border-4 border-gray-900 border-t-transparent"></div>
              </div>
            ) : (
              <>
                {/* Cancel button in the top-right corner */}
                <button
                  className="absolute right-0 top-0 mr-2 mt-2 px-2 py-1 text-gray-500 hover:text-gray-700"
                  onClick={closeModal}
                  aria-label="Close Modal"
                >
                  ✕
                </button>

                {/* Modal Content */}
                {reservation && (
                  <>
                    <h3 className="mb-4 text-xl font-semibold">
                      Customer Found
                    </h3>
                    <p className="mb-4">
                      Name: <b>{reservation?.userName}</b> <br />
                      Number of Guests: <b>{reservation?.noOfPeople}</b>
                    </p>
                  </>
                )}
                {authContext?.user?.subRole != 'Valet' && !reservation && (
                  <p className="mb-4 text-red-500">
                    No reservation found for this customer.
                  </p>
                )}
                <div className="flex flex-col space-y-4">
                  {authContext?.user?.subRole != 'Valet' && reservation && (
                    <button
                      className={`rounded-md px-4 py-2 ${
                        isCheckInEligible
                          ? 'bg-blue-500 text-white hover:bg-blue-600'
                          : 'cursor-not-allowed bg-gray-300 text-gray-500'
                      }`}
                      onClick={handleCheckInReservation}
                      disabled={!isCheckInEligible}
                    >
                      {isCheckInEligible
                        ? 'Check In Reservation'
                        : 'Wait for Check In'}
                    </button>
                  )}
                  {/* The valet check-in button is always shown */}
                  {authContext?.user?.subRole != 'Reception' &&
                    !vehicleSubmitted && (
                      <button
                        className={`rounded-md px-4 py-2 ${
                          !isVehicleSubmittedHere
                            ? 'bg-green-500 text-white hover:bg-green-600'
                            : 'cursor-not-allowed bg-gray-300 text-gray-500'
                        }`}
                        onClick={handleCheckInVehicle}
                        disabled={isVehicleSubmittedHere}
                      >
                        {!isVehicleSubmittedHere
                          ? 'Check In Vehicle'
                          : 'Vehicle checked in'}
                      </button>
                    )}
                </div>
              </>
            )}
          </div>
        );
      }
      case ModalMode.CheckingInVehicle: {
        return (
          <>
            <h3 className="mb-4 text-xl font-semibold">Customer Found</h3>
            <p className="mb-4">Customer ID: {customerId}</p>
            <div className="rounded-md bg-gray-200 px-4 py-2 text-gray-800">
              Please wait while we check in the vehicle!
            </div>
          </>
        );
      }
      case ModalMode.ReleasedVehicle: {
        return (
          <div className="text-black">
            {/* Cancel button in the top-right corner */}
            <button
              className="absolute right-0 top-0 mr-2 mt-2 px-2 py-1 text-gray-500 hover:text-gray-700"
              onClick={() => {
                closeModal();
                navigate('/valet');
              }}
              aria-label="Close Modal"
            >
              ✕
            </button>
            <h3 className="mb-4 text-xl font-semibold">Vehicle Released</h3>
            <p className="mb-4">Customer ID: {customerId}</p>
            <p className="mb-4 font-bold text-green-500">
              Token Number: {vehicleToken}.
            </p>
            <button
              className="rounded-md bg-red-500 px-4 py-2 text-white"
              onClick={() => {
                closeModal();
                navigate('/valet');
              }}
            >
              Close
            </button>
          </div>
        );
      }
      case ModalMode.ReleasingVehicle: {
        if (authContext?.user?.subRole !== 'Reception')
          return (
            <div className="text-black">
              <h3 className="mb-4 text-xl font-semibold">Customer Found</h3>
              <p className="mb-4">Customer ID: {customerId}</p>
              <p className="mb-4 font-bold text-green-500">
                Token Number: {vehicleToken}.
              </p>
              <button
                className="rounded-md bg-yellow-500 px-4 py-2 text-white"
                onClick={() =>
                  releaseVehicle(customerId!, vehicleToken!, secretToken!)
                }
              >
                Handover
              </button>
            </div>
          );
        else
          return (
            <div className="text-black">
              <h3 className="mb-4 text-xl font-semibold">Customer Found</h3>
              <p className="mb-4">Customer ID: {customerId}</p>
              <p className="mb-4 font-bold text-green-500">
                Token Number: {vehicleToken}.
              </p>
              <p className="mb-4">
                This should be scanned by a Valet to handover the vehicle
              </p>
            </div>
          );
      }
      case ModalMode.CheckingInReservation: {
        return (
          <div className="text-black">
            <h3 className="mb-4 text-xl font-semibold">Customer Found</h3>
            <p className="mb-4">Customer ID: {customerId}</p>
            <div className="rounded-md bg-gray-200 px-4 py-2 text-gray-800">
              Please wait while we check in the customer!
            </div>
          </div>
        );
      }
      case ModalMode.CheckedInVehicle: {
        return (
          <div className="text-black">
            <h3 className="mb-4 text-xl font-semibold">Customer Found</h3>
            <p className="mb-4">Customer ID: {customerId}</p>
            <p className="mb-4 font-bold text-green-500">
              Token Number: {vehicleToken}.
            </p>
            <button
              className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        );
      }
      case ModalMode.None:
      default: {
        return <div>This is just a pop-up, you can close it.</div>;
      }
    }
  };

  useEffect(() => {
    console.log('Current Modal Mode: ', modalMode);
  }, [modalMode, setModalMode]);

  const renderModal = () => {
    if (!isModalOpen) return null;
    return ReactDOM.createPortal(
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
        <div className="relative mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
          {modalContent()}
        </div>
      </div>,
      document.body,
    );
  };

  return (
    <div className="relative flex h-screen w-screen items-center justify-center overflow-hidden bg-gray-900">
      {/* Video Feed */}
      {modalMode === ModalMode.None && (
        <video
          ref={videoRef}
          className="absolute left-0 top-0 size-full object-cover"
          autoPlay
          muted
        />
      )}

      {/* Blurred Overlay */}
      <div className="absolute inset-0 z-10">
        <svg className="size-full">
          <defs>
            <mask id="scanner-mask">
              <rect width="100%" height="100%" fill="white" />
              <rect
                x="50%"
                y="50%"
                width="260"
                height="260"
                fill="black"
                rx="12"
                transform="translate(-130, -130)"
              />
            </mask>
          </defs>
          <rect
            width="100%"
            height="100%"
            fill="rgba(0, 0, 0, 0.6)"
            mask="url(#scanner-mask)"
          />
        </svg>
      </div>

      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="absolute left-6 top-6 z-50 flex items-center rounded-lg bg-gray-800/90 px-4 py-2 text-sm font-semibold text-white shadow-lg transition duration-200 hover:bg-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="mr-2 size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>

      {/* Scanner Box */}
      <div
        className="absolute size-64 rounded-lg border-4 border-pink-500"
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="absolute left-0 top-1/2 h-1 w-full animate-pulse bg-pink-500"></div>
      </div>

      {/* Header Text */}
      <div className="absolute top-12 z-20 text-center text-white">
        <p className="text-2xl font-bold">Quforia Scanner</p>
        <p className="text-sm text-gray-300">
          Scan the QR code to manage Customer.
        </p>
      </div>

      {/* Instruction Text */}
      <p className="absolute bottom-28 z-20 font-semibold text-white">
        Position the QR code within the frame.
      </p>

      {/* "Not Working?" Button */}
      <button className="absolute bottom-10 z-20 rounded-lg bg-gray-800 px-4 py-2 text-sm text-white">
        Not Working?
      </button>

      {/* Modal */}
      {renderModal()}
    </div>
  );
};

export default QRScanner;
