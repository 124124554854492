// restaurantMenus.ts
import Teokbokki_Hyd from '../assets/teokbokki_hyd.jpg';
import Seven_Sisters_Hitec_Hyd from '../assets/seven_sisters_hitec_hyd_logo.jpg';
import MerchantLogo from '../assets/merchantLogo.png';
// import Roastery_Jubilee_Hyd from '../assets/roastery_banjara_hyd.jpg';
// import Roastery_Kokapet_Hyd from '../assets/roastery_kokapet_hyd.webp';

export interface Category {
  id: number;
  name: string;
  icon: string;
}

export interface MenuItem {
  id: number;
  name: string;
  price: string;
  category: string;
  description: string;
}

export interface MenuData {
  categories: Category[];
  items: MenuItem[];
}

export interface RestaurantMenu {
  restaurantName: string;
  logo: string;
  description: string;
  menuData: MenuData;
}

// Use URL‑friendly keys (e.g., all lowercase with hyphens instead of spaces)
export const restaurantMenus: Record<string, RestaurantMenu> = {
  // Key: "tteokbokki" for the restaurant “Tteokbokki”
  '+919988776655': {
    restaurantName: "Harsha's Kitchen",
    logo: MerchantLogo,
    description: 'Best food anywhere in this world',
    menuData: {
      categories: [
        { id: 0, name: 'All', icon: '🔥' },
        { id: 1, name: 'Soups', icon: '🔥' },
        { id: 2, name: 'Salads', icon: '⭐' },
        { id: 3, name: 'Veg Starters', icon: '👍' },
      ],
      items: [
        {
          id: 1,
          name: 'KHOW SUEY',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'Traditional Burmese soups noodles cooked in coconut creams and vegetables',
        },
        {
          id: 2,
          name: 'NYONYA LAKSHA',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'Singapore style thick wheat noodles cooked in rich coconut milk soup vegetables and choice of proteins',
        },
        {
          id: 3,
          name: 'VIETNAMESE PHO SOUP',
          price: '140',
          category: 'Soups',
          description:
            'Delicate aromitized soup served with vegetables and rice noodles',
        },
        {
          id: 4,
          name: 'TOM YUM SOUP',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'This soup has specific ingredients special cooking methods from origin of the Thailand',
        },
        {
          id: 5,
          name: 'SOBA / UDON NOODLES SOUP',
          price: '350 / 400',
          category: 'Soups',
          description: 'This soup origin of the Japan made with soya broth',
        },
        {
          id: 6,
          name: 'MISO SOUP',
          price: '350',
          category: 'Soups',
          description:
            'Traditional Japanese soup consisting of a dashi stock served tofu and wakame',
        },
        {
          id: 7,
          name: 'INDONESIA GADO GADO',
          price: '150',
          category: 'Salads',
          description: 'Traditional salad with peanut dressing',
        },
        {
          id: 8,
          name: 'SOM TOM SALAD',
          price: '150',
          category: 'Salads',
          description:
            'Thai style row papaya salad Plumb sugar thai chilli peanut dressing',
        },
        {
          id: 9,
          name: 'ICEBERG CRAB STICK SALAD',
          price: '250',
          category: 'Salads',
          description: 'Japanese style lettuce crabs stick with yu zu dressing',
        },
        {
          id: 10,
          name: 'SAGECCHU GEO TIJEORI',
          price: '150 / 200 / 250',
          category: 'Salads',
          description:
            'A traditional korean style lettuce salad spicy Korean dressing',
        },
        {
          id: 11,
          name: 'HONEY GLAZED SPICY LOTUS STEM',
          price: '250',
          category: 'Veg Starters',
          description: 'Crispy fried lotus stems tossed with spicy honey sauce',
        },
        {
          id: 12,
          name: 'SALT N PEPPER WATER CHESTNUTS',
          price: '250',
          category: 'Veg Starters',
          description: 'Fried water chestnuts with black pepper and celery',
        },
        {
          id: 13,
          name: 'CHILLI BABY CORN',
          price: '250',
          category: 'Veg Starters',
          description: 'Crispy fried baby corn tossed in chilly and soya',
        },
        {
          id: 14,
          name: 'WOOK TOSSED WILD MUSHROOMS',
          price: '350',
          category: 'Veg Starters',
          description: 'Wild mushrooms tossed with chilli oil garlic and soya',
        },
        {
          id: 15,
          name: 'BLACK PEPPER SLICKEN TOFU',
          price: '350',
          category: 'Veg Starters',
          description: 'Slicken tofu tossed in spicy tangy black pepper sauce',
        },
        {
          id: 16,
          name: 'CRISPY POTATO BUTTER GARLIC/CHILLI HONEY',
          price: '200',
          category: 'Veg Starters',
          description: 'Crispy potato butter garlic/chilli honey',
        },
        {
          id: 17,
          name: 'HOT PEPPER BASIL CHICKEN',
          price: '350',
          category: 'Non-Veg Starters',
          description: 'Basil flavour black pepper chicken',
        },
        {
          id: 18,
          name: 'KOREAN FRIED CHICKEN',
          price: '350',
          category: 'Non-Veg Starters',
          description:
            'Korean style fried chicken served with sweet chilli sauce',
        },
        {
          id: 19,
          name: 'SHRIMP TEMPURA',
          price: '550',
          category: 'Non-Veg Starters',
          description: 'Japanese style fried prawn with teriyaki mayo sauce',
        },
        {
          id: 20,
          name: 'SINGAPORE BLACK PEPPER SHRIMP',
          price: '450',
          category: 'Non-Veg Starters',
          description:
            'Crispy fried prawns with Singapore style black pepper sauce',
        },
        {
          id: 21,
          name: 'MALAYSIAN SAMBAL FISH',
          price: '400',
          category: 'Non-Veg Starters',
          description: 'Malaysian style crispy fried sambal sauce fish',
        },
        {
          id: 22,
          name: 'THAI COCONUT SHRIMP',
          price: '450',
          category: 'Non-Veg Starters',
          description: 'Fried shrimp tossed in thai chilli coconut cream',
        },
        {
          id: 23,
          name: 'CHILLI BASIL FISH',
          price: '400',
          category: 'Non-Veg Starters',
          description: 'Chilli basil fish',
        },
        {
          id: 24,
          name: 'STEAM FISH',
          price: '500',
          category: 'Non-Veg Starters',
          description: 'Basa fleet fish steamed top up mushroom pepper',
        },
        {
          id: 25,
          name: 'LOBSTER BUTTER GARLIC / CHILLY',
          price: '999',
          category: 'Non-Veg Starters',
          description: 'Lobster butter garlic / chilly',
        },
        {
          id: 26,
          name: 'BAO BUNS',
          price: '300',
          category: 'Non-Veg Starters',
          description:
            'Asian style steam bao buns stuffing choice your meat/veg',
        },
        {
          id: 27,
          name: 'KOREAN STYLE FRIED CHICKEN',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Korean style fried chicken',
        },
        {
          id: 28,
          name: 'CHICKEN TERIYAKI',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Chicken teriyaki',
        },
        {
          id: 29,
          name: 'BAMBOO SHOOT CHICKEN WITH BLACK PEPPER',
          price: '550',
          category: 'Non-Veg Starters',
          description: 'Bamboo shoot chicken with black pepper',
        },
        {
          id: 30,
          name: 'GRILLED PRAWN WITH MAYONNAISE',
          price: '350',
          category: 'Non-Veg Starters',
          description: 'Grilled prawn with mayonnaise',
        },
        {
          id: 31,
          name: 'ROASTED PORK',
          price: '250',
          category: 'Non-Veg Starters',
          description: 'Roasted pork',
        },
        {
          id: 32,
          name: 'TOFU AND RED YELLOW PEPPERS',
          price: '250',
          category: 'Non-Veg Starters',
          description: 'Tofu and red yellow peppers',
        },
        {
          id: 33,
          name: 'CRISPY LOTUS STEM',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Crispy lotus stem',
        },
        {
          id: 34,
          name: 'ICE BERG LETTUCE MAYO DRESSING',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Ice berg lettuce mayo dressing',
        },
        {
          id: 35,
          name: 'PIGGI BAO BUN',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Simply Steam Bao Bun served with spicy sauce and soup',
        },
        {
          id: 36,
          name: 'SHAPALEY',
          price: '200 / 250 / 300',
          category: 'Non-Veg Starters',
          description:
            'Authentic gorkhali fried or bake pastry with a saviry filling',
        },
        {
          id: 37,
          name: 'CHOILA',
          price: '299/350',
          category: 'Non-Veg Starters',
          description: 'Authentic nepali style grilled chicken',
        },
        {
          id: 38,
          name: 'SEKUWA',
          price: '299',
          category: 'Non-Veg Starters',
          description: 'Popular in nepali authentic grilled chicken skewed',
        },
        {
          id: 39,
          name: 'LAPING',
          price: '200/250',
          category: 'Non-Veg Starters',
          description:
            'Spicy cold mong been dish in Tibetan cusing / can be served',
        },
        {
          id: 40,
          name: 'NAGA CHILLI PORK',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Pork saturtl with naga vipar chilli',
        },
        {
          id: 41,
          name: 'WAI WAI SADEKO',
          price: '150',
          category: 'Non-Veg Starters',
          description:
            'Wai Wai mixed with tomato masala onion and generous sprinkle of lemon',
        },
        {
          id: 42,
          name: 'SINGJU',
          price: '150',
          category: 'Non-Veg Starters',
          description: 'Singju is a northeast often served asa spicy side dish',
        },
        {
          id: 43,
          name: 'SHAPTAR',
          price: '299/350',
          category: 'Main Course',
          description:
            'Authentic Tibetan flavoured meat served with sticky rice /noodles / tingmo',
        },
        {
          id: 44,
          name: 'YAMADASHI',
          price: '299/350',
          category: 'Main Course',
          description:
            'Traditional Bhutanese flavoured spicy creamy cheesey sauce served with sticky rice',
        },
        {
          id: 45,
          name: 'THUPKA',
          price: '200/250/300',
          category: 'Main Course',
          description: 'Authentic suppy noodles',
        },
        {
          id: 46,
          name: 'CHOWMEIN',
          price: '200/250/300',
          category: 'Main Course',
          description: 'Homely style spicy noodles',
        },
        {
          id: 4600,
          name: 'Golden Lobster Sushi',
          price: '2555',
          category: 'Main Course',
          description: 'Crispy roll sushi topup creamy lobster with gold flake',
        },
        {
          id: 47,
          name: 'GOLDEN BOAT SUSHI PLATTERS',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 48,
          name: 'SASHIMI CRUST TUNA SASHIMI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 49,
          name: 'SALMON SASHIMI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 50,
          name: 'JAPANESE EGG ROLL',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
      ],
    },
  },
  '+917671913062': {
    restaurantName: 'Tteokbokki',
    logo: Teokbokki_Hyd,
    description: 'A new Pan Asian and Korean restaurant',
    menuData: {
      categories: [
        { id: 0, name: 'All', icon: '🔥' },
        { id: 1, name: 'Soup', icon: '🔥' },
        { id: 2, name: 'Small Plate', icon: '⭐' },
        { id: 3, name: 'Noodles', icon: '👍' },
        { id: 4, name: 'Rice Bowls', icon: '✨' },
        { id: 5, name: 'Kimbap', icon: '⭐' },
        { id: 6, name: 'Ramen', icon: '🍜' },
        { id: 7, name: 'Dimsum', icon: '🥟' },
        { id: 8, name: 'Himalayan On The Table', icon: '🏔️' },
        { id: 9, name: 'Starter Veg', icon: '🫑' },
        { id: 10, name: 'Starter Non-Veg', icon: '🍗' },
        { id: 11, name: 'Bao-Bun', icon: '🍔' },
        { id: 12, name: 'Rice Bowls', icon: '🍚' },
        { id: 13, name: 'Noodles Bowls', icon: '🍜' },
        { id: 14, name: 'Wok Tossed Rice', icon: '🍚' },
        { id: 15, name: 'Wok Noodles Bowls', icon: '🍜' },
        { id: 16, name: 'From the Wok', icon: '🍳' },
        { id: 17, name: 'SUSHI VEG', icon: '🍣' },
        { id: 18, name: 'SUSHI NON-VEG', icon: '🍣' },
        { id: 19, name: 'DESSERT', icon: '🍨' },
        { id: 20, name: 'BEVERAGE', icon: '🥤' },
        { id: 21, name: 'BUBBLE TEA', icon: '🧋' },
        { id: 22, name: 'MOCKTAILS', icon: '🍹' },
      ],
      items: [
        {
          id: 1,
          name: 'Tteokguk',
          price: '179/199/219',
          category: 'Soup',
          description:
            'Delicious sliced tteokbokki in a clearest broth, a Koreans',
        },
        {
          id: 2,
          name: 'Dakgaejang Jigae (Korean Spicy Soup)',
          price: '179/199/219',
          category: 'Soup',
          description:
            'Corean seasoning with a soy sauce & garlic to give it a bold and spicy taste',
        },
        {
          id: 3,
          name: 'Kimchi Jigae',
          price: '179/199/219',
          category: 'Soup',
          description:
            'A delicious and healthy version of the classic Korean dish, balances spicy, savory, and sweet flavors',
        },
        {
          id: 4,
          name: 'Jjamppong',
          price: '219',
          category: 'Soup',
          description: 'Spicy soup with noodles and seafood and vegetables',
        },
        {
          id: 5,
          name: 'Tteokbokki',
          price: '249/279/299',
          category: 'Small Plate',
          description: 'Chewy rice cakes in a sweet and spicy sauce',
        },
        {
          id: 6,
          name: 'Grilled Tteokbokki',
          price: '279/299/329',
          category: 'Small Plate',
          description:
            'Pan-grilled and then brushed with a sweet, tangy, and spicy seuce',
        },
        {
          id: 7,
          name: 'Fried Tteokbokki',
          price: '279/299/329',
          category: 'Small Plate',
          description:
            'Deep-fried, and then brushed with a sweet, tangy, and spicy sauce.',
        },
        {
          id: 8,
          name: 'Cheese Corn Dog',
          price: '299',
          category: 'Small Plate',
          description:
            'Cheese or sausage on a stick is coated in a layer of cornmeal better and deep fried served 2 pc',
        },
        {
          id: 9,
          name: 'Chicken Corn Dog',
          price: '329',
          category: 'Small Plate',
          description:
            'Cheese or sausage on a stick is coated in a layer of cornmeal better and deep fried served 2 pc',
        },
        {
          id: 10,
          name: 'Chicken Sausage',
          price: '329',
          category: 'Small Plate',
          description:
            'Cheese or sausage on a stick is coated in a layer of cornmeal better and deep fried served 2 pc',
        },
        {
          id: 11,
          name: 'Japchea',
          price: '329/349/379',
          category: 'Noodles',
          description:
            'Savory and slightly sweet dish of stir-fried glass noodles and repetahies',
        },
        {
          id: 12,
          name: 'Jjajangmyeon',
          price: '329/349/379',
          category: 'Noodles',
          description:
            'Popular Koreen dish of shewy ows covered in a thick, savory chase sauce, vegetables',
        },
        {
          id: 13,
          name: 'Bibim Naengmyeon',
          price: '329/349/379',
          category: 'Noodles',
          description:
            'ep Inoped with cucumber dish and bolled egg Ree wheat noodles with sweet and',
        },
        {
          id: 14,
          name: 'Bibimbap',
          price: '329/349/379',
          category: 'Rice Bowls',
          description:
            'a bowl of warm white rice topped with namul and gochujang sauce',
        },
        {
          id: 15,
          name: 'Bulgogi',
          price: '349/379',
          category: 'Rice Bowls',
          description:
            'Soyo based marinated Korean bbq chicken & vegetables served over rice',
        },
        {
          id: 16,
          name: 'Bakkum Bap',
          price: '329/349/379',
          category: 'Rice Bowls',
          description: 'Fried rice with kimches',
        },
        {
          id: 18,
          name: 'Yachae Kimbap',
          price: '379',
          category: 'Kimbap',
          description:
            'Vegetables, Korean dish made from cooked rice and other ingredients, rolled in gim sheets of dried seaweed, and served in bite size slices',
        },
        {
          id: 19,
          name: 'Cheese Kimbap',
          price: '399',
          category: 'Kimbap',
          description:
            'Cheese & vegetables - Korean dish made from cooked rice and other ingredients, rolled in gim sheets of dried seaweed, and served in bite size slices',
        },
        {
          id: 20,
          name: 'Chicken Sausage Kimbap',
          price: '429',
          category: 'Kimbap',
          description:
            'Sausage, omelet roll & vegetables - Korean dish made from cooked rice and other ingredients, rolled in gim sheets of dried seaweed, and served in bite size slices',
        },
        {
          id: 22,
          name: 'Hot Korean Ramen',
          price: '649 / 699 / 749',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },
        {
          id: 23,
          name: 'Korean Spicy Ramen',
          price: '349 / 399 / 449',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },
        {
          id: 24,
          name: 'Kimchi Korean Ramen',
          price: '349 / 399 / 449',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },
        {
          id: 25,
          name: 'Japanese Black Bean Ramen',
          price: '349 / 399 / 449',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },
        {
          id: 26,
          name: 'Japanese Shoyu Ramen',
          price: '349 / 399 / 449',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },
        {
          id: 27,
          name: 'Japanese Miso Ramen',
          price: '349 / 399 / 449',
          category: 'Ramen',
          description:
            'Noodles & exotic veg served in a broth with different flavors',
        },

        {
          id: 29,
          name: 'Water Chestnut and Broccoli',
          price: '329',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 30,
          name: 'Datshi Dimsum',
          price: '399',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 31,
          name: 'Corn & Spinach Dimsum',
          price: '329',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 32,
          name: 'Chili Basil Chicken',
          price: '369',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 33,
          name: 'Chicken Sumai',
          price: '369',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 34,
          name: 'Chicken Mustang Dimsum',
          price: '369',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 35,
          name: 'Shrimp Hargow',
          price: '399',
          category: 'Dimsum',
          description:
            'Rice flour dough and stuffed with minced vegetables, spices, herbs or chicken',
        },
        {
          id: 36,
          name: 'Tom Yum',
          price: '159 / 179 / 199',
          category: 'Soup',
          description:
            'Hot & sour clear soup gives you aromatic & spicy flavors with eye bird chilli and cherry tomato',
        },
        {
          id: 37,
          name: 'Tom Kha',
          price: '159 / 179 / 199',
          category: 'Soup',
          description: 'Spicy coconut base soup with Thai herbs',
        },
        {
          id: 38,
          name: 'Vietnamese Pho',
          price: '159 / 179 / 199',
          category: 'Soup',
          description:
            'Deeply flavored clear broth from fresh herbs & spices with rice noodle',
        },
        {
          id: 39,
          name: 'Crab Meat Soup',
          price: '199',
          category: 'Soup',
          description:
            'Rich chowder made with cracked crab meat and fresh herbs and spices',
        },
        {
          id: 40,
          name: 'Chilli Garlic Miso (Tofu/Crab)',
          price: '179 / 199',
          category: 'Soup',
          description: 'Japanese soup miso shiro, wakame, green onion',
        },
        {
          id: 42,
          name: 'Steamed Momo - 8 Pcs ',
          price: '199 / 219',
          category: 'Himalayan On The Table',
          description:
            'HERBS OF CHICKEN STUFFED WITH MINCED VEGETABLES, SPICES.',
        },
        {
          id: 43,
          name: 'Deep Fried Momo - 8 Pcs',
          price: '209 / 229',
          category: 'Himalayan On The Table',
          description:
            'HERBS OF CHICKEN STUFFED WITH MINCED VEGETABLES, SPICES.',
        },
        {
          id: 44,
          name: 'Pan Fried Momo - 8 Pcs',
          price: '209 / 229',
          category: 'Himalayan On The Table',
          description:
            'HERBS OF CHICKEN STUFFED WITH MINCED VEGETABLES, SPICES.',
        },
        {
          id: 45,
          name: 'Schezwan Chilli Momo - 8 Pcs',
          price: '209 / 229',
          category: 'Himalayan On The Table',
          description:
            'HERBS OF CHICKEN STUFFED WITH MINCED VEGETABLES, SPICES.',
        },
        {
          id: 46,
          name: 'Jhol Momo - 8 Pcs',
          price: '209 / 229',
          category: 'Himalayan On The Table',
          description:
            'HERBS OF CHICKEN STUFFED WITH MINCED VEGETABLES, SPICES.',
        },
        {
          id: 47,
          name: 'Laphing',
          price: '209 / 229',
          category: 'Himalayan On The Table',
          description:
            'Mungbean noodle with spicy mixture of soy sauce, red chilli, wai wai',
        },
        {
          id: 48,
          name: 'Thukpa',
          price: '249 / 299',
          category: 'Himalayan On The Table',
          description:
            'Favourite Himalayan street style noodle soup. Noodles, vegetables, and spices in a broth',
        },
        {
          id: 49,
          name: 'Keema Noodles',
          price: '229 / 249',
          category: 'Himalayan On The Table',
          description:
            'Wok tossed Chicken keema & noodle with Himalayan spices',
        },
        {
          id: 50,
          name: 'Phaley',
          price: '229 / 249',
          category: 'Himalayan On The Table',
          description:
            'Tibetan dish of bread stuffed with seasoned vegetables / chicken',
        },
        {
          id: 51,
          name: 'Chicken Choila',
          price: '319',
          category: 'Himalayan On The Table',
          description: 'Newari dish from Nepal made with spiced smoked chicken',
        },
        {
          id: 52,
          name: 'Sekuwa Chicken',
          price: '329',
          category: 'Himalayan On The Table',
          description:
            "Nepalese dish of meat that's marinated, skewered, and grilled over an open flame",
        },
        {
          id: 53,
          name: 'Shapta Chicken',
          price: '369',
          category: 'Himalayan On The Table',
          description:
            'Stir-fried semi gravy chicken dish originating from Tibet served with tingmo',
        },
        {
          id: 54,
          name: 'Datshi',
          price: '399 / 429',
          category: 'Himalayan On The Table',
          description:
            'Traditional spicy Bhutanese stew dish made with cheese and pepper served with rice or tingmo.',
        },
        {
          id: 55,
          name: 'HONEY CHILI POTATO',
          price: '229',
          category: 'Starter Veg',
          description: 'Crispy fried potato tossed in sweet spicy chilly sauce',
        },
        {
          id: 56,
          name: 'CRISPY CORN',
          price: '249',
          category: 'Starter Veg',
          description:
            'Crispy fried sweated corn tossed with onion and bell pepper',
        },
        {
          id: 57,
          name: 'BLACK PEPPER KINOKO',
          price: '279',
          category: 'Starter Veg',
          description: 'Wok tossed mushroom in black pepper sauce',
        },
        {
          id: 58,
          name: 'WATER-CHESTNUT',
          price: '299',
          category: 'Starter Veg',
          description:
            'Sweet (Chilli/Black Pepper and Celery) Crispy battered fried tossed in',
        },
        {
          id: 59,
          name: 'CRISPY REKON',
          price: '299',
          category: 'Starter Veg',
          description:
            'Crispy fried lotus root tossed with honey chili sriracha sauce',
        },
        {
          id: 60,
          name: 'THAI BASIL COTTAGE CHEESE',
          price: '299',
          category: 'Starter Veg',
          description: 'Cottage cheese wok tossed in holy basil',
        },
        {
          id: 61,
          name: 'STIR FRY ASIAN GREEN',
          price: '289',
          category: 'Starter Veg',
          description: 'Green vegetable tossed in minced garlic sauce',
        },
        {
          id: 62,
          name: 'STIR FRY BOKCHOI MUSHROOM BROCOLI',
          price: '299',
          category: 'Starter Veg',
          description: '',
        },
        {
          id: 63,
          name: 'WOK TOSSED WILD MUSHROOM',
          price: '279',
          category: 'Starter Veg',
          description:
            'Assorted mix mushroom tossed with chilly oil, garlic with soya',
        },
        {
          id: 64,
          name: 'BUTTER GARLIC POTATO',
          price: '249',
          category: 'Starter Veg',
          description: 'Crispy fried potato in butter garlic sauce',
        },
        {
          id: 65,
          name: 'DOUFU LAJJAO',
          price: '279',
          category: 'Starter Veg',
          description: 'silken tofu in hot pepper sauce',
        },
        {
          id: 66,
          name: 'KOREAN CHICKEN WINGS',
          price: '289',
          category: 'Starter Non-Veg',
          description:
            'Spicy chicken wings are coated in a sweet, spicy and sticky sauce',
        },
        {
          id: 67,
          name: 'KOREAN FRIED CHICKEN',
          price: '399',
          category: 'Starter Non-Veg',
          description:
            'Deep fried chicken that is coated with sticky Korean sauce',
        },
        {
          id: 68,
          name: 'DAK KKANPUNGGI',
          price: '299',
          category: 'Starter Non-Veg',
          description: 'Deep fried chicken in garlic sauce',
        },
        {
          id: 69,
          name: 'MONGOLIAN CHICKEN',
          price: '279',
          category: 'Starter Non-Veg',
          description:
            'Crispy chicken coated in an aromatic, savory, and sweet sauce',
        },
        {
          id: 70,
          name: 'THAI BASIL CHICKEN',
          price: '299',
          category: 'Starter Non-Veg',
          description: 'Chicken marinated in holy basil and thai herbs',
        },
        {
          id: 71,
          name: 'KUNG-PAO CHICKEN',
          price: '299',
          category: 'Starter Non-Veg',
          description: 'Chinese spicy diced chicken with cashew nut',
        },
        {
          id: 72,
          name: 'SATAY AYAM',
          price: '329',
          category: 'Starter Non-Veg',
          description: 'Chicken Skewers with a peanut dipping sauce',
        },
        {
          id: 73,
          name: 'LEMON BASIL PRAWN',
          price: '349',
          category: 'Starter Non-Veg',
          description: 'Deep fried prawn in spicy lemon basil sauce',
        },
        {
          id: 74,
          name: 'SINGAPORE CHILI PRAWN',
          price: '349',
          category: 'Starter Non-Veg',
          description: 'Crispy prawn in Singapore style chili',
        },
        {
          id: 75,
          name: 'WASABI PRAWN',
          price: '349',
          category: 'Starter Non-Veg',
          description: 'Crispy prawns coated in creamy wasabi sauce',
        },
        {
          id: 76,
          name: 'PRAWN TEMPURA',
          price: '429',
          category: 'Starter Non-Veg',
          description: 'Crispy prawn in Japanese sweet chili sauce',
        },
        {
          id: 77,
          name: 'BASIL PEPPER FISH',
          price: '349',
          category: 'Starter Non-Veg',
          description:
            'Deep fried batter fish tossed in basil bell peppers sauce',
        },
        {
          id: 78,
          name: 'PHUKET FISH',
          price: '399',
          category: 'Starter Non-Veg',
          description: 'Ethnic spicy fillet of fish cooked in spicy sauce',
        },
        {
          id: 79,
          name: 'HONAI TOI CA',
          price: '399',
          category: 'Starter Non-Veg',
          description: 'Basa fish wok tossed in butter garlic',
        },
        {
          id: 80,
          name: 'SPICY PRAWN',
          price: '389',
          category: 'Starter Non-Veg',
          description: 'Crispy fried prawn in Naga chili sauce',
        },
        {
          id: 82,
          name: 'LOTUS STEM BAO BUN IN HONEY GLAZED  3 PCS',
          price: '329',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 83,
          name: 'STIR FRY EXOTIC VEGETABLE IN HOLY BASIL  3 PCS',
          price: '279',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 84,
          name: 'DATSHI BAO BUN  3 PCS',
          price: '299',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 85,
          name: 'TOFU AND BELL PEPPER  3 PCS',
          price: '299',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 86,
          name: 'CRISPY CHICKEN BAO BUN  3 PCS',
          price: '299',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 87,
          name: 'KOREAN CHICKEN BAO BUN  3 PCS',
          price: '349',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 88,
          name: 'CHICKEN BAOZI  3 PCS',
          price: '349',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 89,
          name: 'PRAWN BAO BUN  3 PCS',
          price: '379',
          category: 'Bao-Bun',
          description:
            'Puffy Steamed open bao, folded over a flovourful filling',
        },
        {
          id: 91,
          name: 'THAI CURRY',
          price: '399 / 449 / 499',
          category: 'Rice Bowls',
          description:
            'RED OR GREEN The curry based of coconut milk, veggies and Thai herbs with',
        },
        {
          id: 92,
          name: 'NASI GORENG',
          price: '449 / 499',
          category: 'Rice Bowls',
          description:
            ', getic, ground shrima peste, tamarind and chili desian stir filed rice with kecap manis (sweet soy sauce).',
        },
        {
          id: 93,
          name: 'PAD GAI KRAPOW',
          price: '449',
          category: 'Rice Bowls',
          description:
            'Sür fry Chicken with basil in thai herbs served with rice and bled egg',
        },
        {
          id: 94,
          name: 'DONBURI',
          price: '399 / 449 / 499',
          category: 'Rice Bowls',
          description:
            'Japanese rice bowl served with chicken, vegetable, pickle salad and wou',
        },
        {
          id: 95,
          name: 'TERIYAKI RICE BOWL',
          price: '399 / 449 / 499',
          category: 'Rice Bowls',
          description:
            '(TOFU/CHICKEN/PRAWN) Jxpanese homemade hesithy rice bowl served with veggies',
        },
        {
          id: 96,
          name: 'LAOS RICE BOWLS',
          price: '399 / 449 / 499',
          category: 'Rice Bowls',
          description: 'sauce with bamboo shoot cstry served over the rice',
        },
        {
          id: 98,
          name: 'KHOW SUEY',
          price: '399 / 449 / 499',
          category: 'Noodles Bowls',
          description:
            'Traditional Burmese dish served with noodle and coconut stew',
        },
        {
          id: 99,
          name: 'LAKSA',
          price: '399 / 449 / 499',
          category: 'Noodles Bowls',
          description:
            'conatuts of thick rics naudies, with toppings such as veggies, and non veg, served with coconut stew.',
        },
        {
          id: 100,
          name: 'AMERICAN CHOPSUEY',
          price: '399 / 449 / 499',
          category: 'Noodles Bowls',
          description:
            'Cmay noodles topped with a sweet, spicy, and tangy vegetable the fry gravy',
        },
        {
          id: 101,
          name: 'BAMI GORENG',
          price: '449 / 499',
          category: 'Noodles Bowls',
          description:
            'smani sauca Indonesian style stir-fry chewy noodles tossed in a savory and',
        },
        {
          id: 102,
          name: 'CHICKEN TSUKEMEN',
          price: '449',
          category: 'Noodles Bowls',
          description: 'House noodles clear truth in chilli bean sauce',
        },
        {
          id: 104,
          name: 'Asian Vegetable Fried Rice',
          price: '229 / 259 / 279',
          category: 'Wok Tossed Rice',
          description: 'Assorted vegetables and rice',
        },
        {
          id: 105,
          name: 'Bamboo Shoot Fried Rice',
          price: '239 / 279 / 299',
          category: 'Wok Tossed Rice',
          description: 'Fried rice with bamboo shoot',
        },
        {
          id: 106,
          name: 'Thai Basil Fried Rice',
          price: '239 / 279 / 299',
          category: 'Wok Tossed Rice',
          description: 'Spicy Basil flavor fried rice',
        },
        {
          id: 107,
          name: 'Singapore Fried Rice',
          price: '239 / 279 / 299',
          category: 'Wok Tossed Rice',
          description: 'Fried rice in Singapore style',
        },
        {
          id: 108,
          name: 'Vietnamese Fried Rice',
          price: '259 / 279 / 299',
          category: 'Wok Tossed Rice',
          description: 'Vietnamese style fried rice',
        },
        {
          id: 109,
          name: 'Bai Cha',
          price: '259 / 279 / 299',
          category: 'Wok Tossed Rice',
          description: 'Cambodian style fried rice',
        },
        {
          id: 110,
          name: 'Seafood Fried Rice',
          price: '309',
          category: 'Wok Tossed Rice',
          description: 'Prawn and fish',
        },
        {
          id: 111,
          name: 'Steamed Rice',
          price: '119',
          category: 'Wok Tossed Rice',
          description: '',
        },
        {
          id: 112,
          name: 'Jasmine Rice',
          price: '199',
          category: 'Wok Tossed Rice',
          description: '',
        },
        {
          id: 114,
          name: 'Chow-Mien',
          price: '229 / 249 / 299',
          category: 'Wok Noodles Bowls',
          description: 'Stir-fried noodles with chinese aroma',
        },
        {
          id: 115,
          name: 'Spicy Dragon Noodles',
          price: '229 / 249 / 299',
          category: 'Wok Noodles Bowls',
          description: 'Noodles tossed in spicy chili soya sauce',
        },
        {
          id: 116,
          name: 'Mei Fung Noodles',
          price: '229 / 249 / 299',
          category: 'Wok Noodles Bowls',
          description: 'rice & noodies tossed in hell pepper',
        },
        {
          id: 117,
          name: 'Hong Kong Style Noodles',
          price: '229 / 249 / 299',
          category: 'Wok Noodles Bowls',
          description: 'Wok tossed noodles in hong kong style',
        },
        {
          id: 118,
          name: 'Phad Kee Mao (Drunken Noodles)',
          price: '299 / 319 / 349',
          category: 'Wok Noodles Bowls',
          description:
            'Flat chewy rice noodles wok tossed in a spicy and savery sauce with partic, onions, chili, tofu, and lots of fresh basil',
        },
        {
          id: 119,
          name: 'Pad Thai Noodles',
          price: '299 / 319 / 349',
          category: 'Wok Noodles Bowls',
          description:
            'Stir fry rice noodles with sweet-savory-sour sauce with crushed peanuts',
        },
        {
          id: 120,
          name: 'Soba Noodles',
          price: '299 / 319 / 349',
          category: 'Wok Noodles Bowls',
          description: 'Stir fried buck wheat noodies in soya oyster sauce',
        },
        {
          id: 121,
          name: 'Udon Noodles',
          price: '299 / 319 / 349',
          category: 'Wok Noodles Bowls',
          description: 'Stir fried noodles in black pepper sauce',
        },
        {
          id: 123,
          name: 'Asian Greens / Water Chestnut / Tofu Mushroom',
          price: '249',
          category: 'From the Wok',
          description: 'The Note & Pennut) Paper Peyser Seat Chi & Oyster',
        },
        {
          id: 124,
          name: 'Chicken',
          price: '299',
          category: 'From the Wok',
          description: 'Kung Pao, Black Pepper, Chilli Oyster',
        },
        {
          id: 125,
          name: 'Prawn/Fish',
          price: '349',
          category: 'From the Wok',
          description: 'Hunan, Ginger & Oyster',
        },
        {
          id: 126,
          name: 'KAPPA MAKI',
          price: '269/449',
          category: 'SUSHI VEG',
          description: 'Fresh Cucumber roll',
        },
        {
          id: 127,
          name: 'Yasai Hosomaki',
          price: '269/449',
          category: 'SUSHI VEG',
          description: 'Vegetable roll',
        },
        {
          id: 128,
          name: 'Veg California',
          price: '279/449',
          category: 'SUSHI VEG',
          description:
            'Cucumber, Carrot, Asparagus, avocado, lettuce & sesame seed.',
        },
        {
          id: 129,
          name: 'Yasai Tempura Uramaki',
          price: '279/449',
          category: 'SUSHI VEG',
          description: 'Crispy fired vegatables in tempura flour.',
        },
        {
          id: 130,
          name: 'TOFU TERIYAKI URAMAKI',
          price: '279/449',
          category: 'SUSHI VEG',
          description: 'Crispy fired vegatables in tempura flour.',
        },
        {
          id: 131,
          name: 'SPICY CHEESE AVOCADO URAMAKI',
          price: '279/479',
          category: 'SUSHI VEG',
          description: 'Avocaco cream cheese spicy mayo',
        },
        {
          id: 132,
          name: 'ASPARA TEMPURA URAMAKI',
          price: '299/479',
          category: 'SUSHI VEG',
          description: 'Crispy fried asparagus roll.',
        },
        {
          id: 133,
          name: 'Katsu Uramaki',
          price: '299/449',
          category: 'SUSHI NON-VEG',
          description: 'Fried chicken and spicy mayo',
        },
        {
          id: 134,
          name: 'TERIYAKI CHICKEN',
          price: '299/449',
          category: 'SUSHI NON-VEG',
          description: 'Grilled chicken with teriyaki sauce.',
        },
        {
          id: 135,
          name: 'TEMPURA URAMAKI',
          price: '299/449',
          category: 'SUSHI NON-VEG',
          description: 'Shrimp tempura, avocado, and wasabi mayo',
        },
        {
          id: 136,
          name: 'DRAGON ROLL',
          price: '299/599',
          category: 'SUSHI NON-VEG',
          description: 'Crunchy shrimp tempura & creamy avocado',
        },
        {
          id: 137,
          name: 'VOLCANO ROLL',
          price: '399/599',
          category: 'SUSHI NON-VEG',
          description: 'Spicy tuna green onion mayo',
        },
        {
          id: 138,
          name: 'SPICY SALMON',
          price: '399/599',
          category: 'SUSHI NON-VEG',
          description: 'Salmon togarashi spicy mayo green onion',
        },
        {
          id: 139,
          name: 'SAKE MAKI',
          price: '399/599',
          category: 'SUSHI NON-VEG',
          description: 'Atlantic salmon with carrot and cucumber',
        },
        {
          id: 140,
          name: 'RED DRAGON ROLL',
          price: '399/599',
          category: 'SUSHI NON-VEG',
          description: 'spicy salmon filling with tuna and avocado',
        },
        {
          id: 141,
          name: 'QUEEN OF SALMON',
          price: '599/799',
          category: 'SUSHI NON-VEG',
          description:
            'Unique combo of salmon and crabstick with avocado and cream cheese',
        },
        {
          id: 142,
          name: 'CALIFORNIA ROLL',
          price: '549/799',
          category: 'SUSHI NON-VEG',
          description:
            'Crab, avocado, cucumber, tobiko and cucumber topped with tobiko',
        },
        {
          id: 143,
          name: 'NIGRI (3 PC)',
          price: '499',
          category: 'NIGRI (3 PC)',
          description: 'SALMON\nTUNA\nCRAB STICK',
        },
        {
          id: 144,
          name: 'Sushi Boat (18 Pc) Veg',
          price: '1299',
          category: 'SUSHI VEG',
          description: '',
        },
        {
          id: 14432,
          name: 'Sushi Boat (18 Pc) Non-Veg',
          price: '1799',
          category: 'SUSHI NON-VEG',
          description: '',
        },
        {
          id: 146,
          name: 'SASHIMI PLATTER (12 PCS)',
          price: '1499',
          category: 'SUSHI NON-VEG',
          description: 'SALMON\nYAMAGO',
        },
        {
          id: 148,
          name: 'MOCHI ICE CREAM',
          price: '299',
          category: 'DESSERT',
          description: '',
        },
        {
          id: 149,
          name: 'FRIED NOODLE WITH ICE CREAM',
          price: '199',
          category: 'DESSERT',
          description: '',
        },
        {
          id: 151,
          name: 'WATER BOTTLE',
          price: '',
          category: 'BEVERAGE',
          description: 'Bottled',
        },
        {
          id: 152,
          name: 'AERATED WATER',
          price: '',
          category: 'BEVERAGE',
          description: '',
        },
        {
          id: 153,
          name: 'FRESH LIME WATER (SWEET OR SALT OR MIX)',
          price: '129',
          category: 'BEVERAGE',
          description: '',
        },
        {
          id: 154,
          name: 'FRESH LIME SODA (SWEET OR SALT OR MIX)',
          price: '129',
          category: 'BEVERAGE',
          description: '',
        },
        {
          id: 156,
          name: 'TAPIOCA',
          price: '249',
          category: 'BUBBLE TEA',
          description:
            'Classic / Mango / Berries / Orange / Strawberry / Lichie',
        },
        {
          id: 157,
          name: 'FRUIT JELLY',
          price: '299',
          category: 'BUBBLE TEA',
          description:
            'Classic / Mango / Berries / Orange / Strawberry / Lichie',
        },
        {
          id: 165,
          name: 'PINA COLODA',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Cresty pinapple based',
        },
        {
          id: 166,
          name: 'SHIRLEY TEMPLE',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Orange juice & grenadine',
        },
        {
          id: 167,
          name: 'BARBIE GIRL',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Combination of pineapple & orange',
        },
        {
          id: 168,
          name: 'MANGO MADNESS',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Combination of mango/arange/pineapple',
        },
        {
          id: 169,
          name: 'LITCHI LEMONADE',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Litdil top up with lemonade',
        },
        {
          id: 170,
          name: 'MOJITO',
          price: '199',
          category: 'MOCKTAILS',
          description: 'dessic/ chi/ mango/ orange/ bentes/ strawberry',
        },
        {
          id: 171,
          name: 'REFRESHING ORANGE MINT',
          price: '199',
          category: 'MOCKTAILS',
          description: '',
        },
        {
          id: 172,
          name: 'BLUE LAGOON',
          price: '199',
          category: 'MOCKTAILS',
          description: 'Bime blue curacao and lemonade',
        },
        {
          id: 173,
          name: 'COOL BREEZE',
          price: '199',
          category: 'MOCKTAILS',
          description: 'cranberty juice passion fruit syrup with lime julce',
        },
        {
          id: 174,
          name: 'APPLE SQUEEZER',
          price: '199',
          category: 'MOCKTAILS',
          description: 'apple Juice pession fruit syrup with lime juice',
        },
        {
          id: 175,
          name: 'SOB',
          price: '199',
          category: 'MOCKTAILS',
          description:
            'erange/cranberry/peach syrup/lime juice and sugar synap',
        },
        {
          id: 176,
          name: 'STRAWBERRY LEMONADE',
          price: '199',
          category: 'MOCKTAILS',
          description: '',
        },
      ],
    },
  },
  '+918334068484': {
    restaurantName: 'Seven Sisters',
    logo: Seven_Sisters_Hitec_Hyd,
    description: 'A new Pan Asian and Korean restaurant',
    menuData: {
      categories: [
        { id: 0, name: 'All', icon: '🔥' },
        { id: 1, name: 'Soups', icon: '🔥' },
        { id: 2, name: 'Salads', icon: '⭐' },
        { id: 3, name: 'Veg Starters', icon: '👍' },
        { id: 4, name: 'Non-Veg Starters', icon: '✨' },
        { id: 5, name: 'Malay Satay', icon: '✨' },
        { id: 6, name: 'Robatayaki', icon: '✨' },
        { id: 7, name: 'Main Course', icon: '✨' },
        { id: 8, name: 'Rice', icon: '✨' },
        { id: 9, name: 'Dimsum', icon: '✨' },
        { id: 10, name: 'Noodles', icon: '✨' },
        { id: 11, name: 'Curry', icon: '✨' },
        { id: 12, name: 'Ramen Your Bowl', icon: '✨' },
        { id: 13, name: 'Teriyaki', icon: '✨' },
        { id: 14, name: 'Sesame Platters', icon: '✨' },
        { id: 15, name: 'Sushi Platters', icon: '✨' },
        { id: 15, name: 'Dessert', icon: '✨' },
        { id: 15, name: 'Green Tea', icon: '✨' },
      ],
      items: [
        {
          id: 1,
          name: 'KHOW SUEY',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'Traditional Burmese soups noodles cooked in coconut creams and vegetables',
        },
        {
          id: 2,
          name: 'NYONYA LAKSHA',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'Singapore style thick wheat noodles cooked in rich coconut milk soup vegetables and choice of proteins',
        },
        {
          id: 3,
          name: 'VIETNAMESE PHO SOUP',
          price: '140',
          category: 'Soups',
          description:
            'Delicate aromitized soup served with vegetables and rice noodles',
        },
        {
          id: 4,
          name: 'TOM YUM SOUP',
          price: '350 / 400 / 450',
          category: 'Soups',
          description:
            'This soup has specific ingredients special cooking methods from origin of the Thailand',
        },
        {
          id: 5,
          name: 'SOBA / UDON NOODLES SOUP',
          price: '350 / 400',
          category: 'Soups',
          description: 'This soup origin of the Japan made with soya broth',
        },
        {
          id: 6,
          name: 'MISO SOUP',
          price: '350',
          category: 'Soups',
          description:
            'Traditional Japanese soup consisting of a dashi stock served tofu and wakame',
        },
        {
          id: 7,
          name: 'INDONESIA GADO GADO',
          price: '150',
          category: 'Salads',
          description: 'Traditional salad with peanut dressing',
        },
        {
          id: 8,
          name: 'SOM TOM SALAD',
          price: '150',
          category: 'Salads',
          description:
            'Thai style row papaya salad Plumb sugar thai chilli peanut dressing',
        },
        {
          id: 9,
          name: 'ICEBERG CRAB STICK SALAD',
          price: '250',
          category: 'Salads',
          description: 'Japanese style lettuce crabs stick with yu zu dressing',
        },
        {
          id: 10,
          name: 'SAGECCHU GEO TIJEORI',
          price: '150 / 200 / 250',
          category: 'Salads',
          description:
            'A traditional korean style lettuce salad spicy Korean dressing',
        },
        {
          id: 11,
          name: 'HONEY GLAZED SPICY LOTUS STEM',
          price: '250',
          category: 'Veg Starters',
          description: 'Crispy fried lotus stems tossed with spicy honey sauce',
        },
        {
          id: 12,
          name: 'SALT N PEPPER WATER CHESTNUTS',
          price: '250',
          category: 'Veg Starters',
          description: 'Fried water chestnuts with black pepper and celery',
        },
        {
          id: 13,
          name: 'CHILLI BABY CORN',
          price: '250',
          category: 'Veg Starters',
          description: 'Crispy fried baby corn tossed in chilly and soya',
        },
        {
          id: 14,
          name: 'WOOK TOSSED WILD MUSHROOMS',
          price: '350',
          category: 'Veg Starters',
          description: 'Wild mushrooms tossed with chilli oil garlic and soya',
        },
        {
          id: 15,
          name: 'BLACK PEPPER SLICKEN TOFU',
          price: '350',
          category: 'Veg Starters',
          description: 'Slicken tofu tossed in spicy tangy black pepper sauce',
        },
        {
          id: 16,
          name: 'CRISPY POTATO BUTTER GARLIC/CHILLI HONEY',
          price: '200',
          category: 'Veg Starters',
          description: 'Crispy potato butter garlic/chilli honey',
        },
        {
          id: 17,
          name: 'HOT PEPPER BASIL CHICKEN',
          price: '350',
          category: 'Non-Veg Starters',
          description: 'Basil flavour black pepper chicken',
        },
        {
          id: 18,
          name: 'KOREAN FRIED CHICKEN',
          price: '350',
          category: 'Non-Veg Starters',
          description:
            'Korean style fried chicken served with sweet chilli sauce',
        },
        {
          id: 19,
          name: 'SHRIMP TEMPURA',
          price: '550',
          category: 'Non-Veg Starters',
          description: 'Japanese style fried prawn with teriyaki mayo sauce',
        },
        {
          id: 20,
          name: 'SINGAPORE BLACK PEPPER SHRIMP',
          price: '450',
          category: 'Non-Veg Starters',
          description:
            'Crispy fried prawns with Singapore style black pepper sauce',
        },
        {
          id: 21,
          name: 'MALAYSIAN SAMBAL FISH',
          price: '400',
          category: 'Non-Veg Starters',
          description: 'Malaysian style crispy fried sambal sauce fish',
        },
        {
          id: 22,
          name: 'THAI COCONUT SHRIMP',
          price: '450',
          category: 'Non-Veg Starters',
          description: 'Fried shrimp tossed in thai chilli coconut cream',
        },
        {
          id: 23,
          name: 'CHILLI BASIL FISH',
          price: '400',
          category: 'Non-Veg Starters',
          description: 'Chilli basil fish',
        },
        {
          id: 24,
          name: 'STEAM FISH',
          price: '500',
          category: 'Non-Veg Starters',
          description: 'Basa fleet fish steamed top up mushroom pepper',
        },
        {
          id: 25,
          name: 'LOBSTER BUTTER GARLIC / CHILLY',
          price: '999',
          category: 'Non-Veg Starters',
          description: 'Lobster butter garlic / chilly',
        },
        {
          id: 26,
          name: 'BAO BUNS',
          price: '300',
          category: 'Non-Veg Starters',
          description:
            'Asian style steam bao buns stuffing choice your meat/veg',
        },
        {
          id: 27,
          name: 'KOREAN STYLE FRIED CHICKEN',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Korean style fried chicken',
        },
        {
          id: 28,
          name: 'CHICKEN TERIYAKI',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Chicken teriyaki',
        },
        {
          id: 29,
          name: 'BAMBOO SHOOT CHICKEN WITH BLACK PEPPER',
          price: '550',
          category: 'Non-Veg Starters',
          description: 'Bamboo shoot chicken with black pepper',
        },
        {
          id: 30,
          name: 'GRILLED PRAWN WITH MAYONNAISE',
          price: '350',
          category: 'Non-Veg Starters',
          description: 'Grilled prawn with mayonnaise',
        },
        {
          id: 31,
          name: 'ROASTED PORK',
          price: '250',
          category: 'Non-Veg Starters',
          description: 'Roasted pork',
        },
        {
          id: 32,
          name: 'TOFU AND RED YELLOW PEPPERS',
          price: '250',
          category: 'Non-Veg Starters',
          description: 'Tofu and red yellow peppers',
        },
        {
          id: 33,
          name: 'CRISPY LOTUS STEM',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Crispy lotus stem',
        },
        {
          id: 34,
          name: 'ICE BERG LETTUCE MAYO DRESSING',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Ice berg lettuce mayo dressing',
        },
        {
          id: 35,
          name: 'PIGGI BAO BUN',
          price: '200',
          category: 'Non-Veg Starters',
          description: 'Simply Steam Bao Bun served with spicy sauce and soup',
        },
        {
          id: 36,
          name: 'SHAPALEY',
          price: '200 / 250 / 300',
          category: 'Non-Veg Starters',
          description:
            'Authentic gorkhali fried or bake pastry with a saviry filling',
        },
        {
          id: 37,
          name: 'CHOILA',
          price: '299/350',
          category: 'Non-Veg Starters',
          description: 'Authentic nepali style grilled chicken',
        },
        {
          id: 38,
          name: 'SEKUWA',
          price: '299',
          category: 'Non-Veg Starters',
          description: 'Popular in nepali authentic grilled chicken skewed',
        },
        {
          id: 39,
          name: 'LAPING',
          price: '200/250',
          category: 'Non-Veg Starters',
          description:
            'Spicy cold mong been dish in Tibetan cusing / can be served',
        },
        {
          id: 40,
          name: 'NAGA CHILLI PORK',
          price: '300',
          category: 'Non-Veg Starters',
          description: 'Pork saturtl with naga vipar chilli',
        },
        {
          id: 41,
          name: 'WAI WAI SADEKO',
          price: '150',
          category: 'Non-Veg Starters',
          description:
            'Wai Wai mixed with tomato masala onion and generous sprinkle of lemon',
        },
        {
          id: 42,
          name: 'SINGJU',
          price: '150',
          category: 'Non-Veg Starters',
          description: 'Singju is a northeast often served asa spicy side dish',
        },
        {
          id: 43,
          name: 'SHAPTAR',
          price: '299/350',
          category: 'Main Course',
          description:
            'Authentic Tibetan flavoured meat served with sticky rice /noodles / tingmo',
        },
        {
          id: 44,
          name: 'YAMADASHI',
          price: '299/350',
          category: 'Main Course',
          description:
            'Traditional Bhutanese flavoured spicy creamy cheesey sauce served with sticky rice',
        },
        {
          id: 45,
          name: 'THUPKA',
          price: '200/250/300',
          category: 'Main Course',
          description: 'Authentic suppy noodles',
        },
        {
          id: 46,
          name: 'CHOWMEIN',
          price: '200/250/300',
          category: 'Main Course',
          description: 'Homely style spicy noodles',
        },
        {
          id: 4600,
          name: 'Golden Lobster Sushi',
          price: '2555',
          category: 'Main Course',
          description: 'Crispy roll sushi topup creamy lobster with gold flake',
        },
        {
          id: 47,
          name: 'GOLDEN BOAT SUSHI PLATTERS',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 48,
          name: 'SASHIMI CRUST TUNA SASHIMI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 49,
          name: 'SALMON SASHIMI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 50,
          name: 'JAPANESE EGG ROLL',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 51,
          name: 'TRANS NIGIRI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 52,
          name: 'SALMON NIGIRI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 53,
          name: 'TUNA NIGIRI',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 54,
          name: 'GUN KAN',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 55,
          name: 'PHILADELPHIA',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 5500,
          name: 'CALIFORNIA',
          price: '2555',
          category: 'Main Course',
          description:
            'Special sushi with 24 carats gold work served with roll- sashimi-nigiri-gunkan',
        },
        {
          id: 56,
          name: 'HAMACHI JALAPENO MAKI',
          price: '650',
          category: 'Main Course',
          description:
            'Japanese cheese crabstick top up red snapper fish with propel rice roll',
        },
        {
          id: 57,
          name: 'TUNA ADAGE MAKI',
          price: '650',
          category: 'Main Course',
          description:
            'Tuna with avocado cream cheese with toppings sashimi crust tuna',
        },
        {
          id: 58,
          name: 'QUEEN OF SALMON MAKI',
          price: '600',
          category: 'Main Course',
          description:
            'India fish crab avocado top up salmon cream cheese tob moco dressing red rice salmon raw',
        },
        {
          id: 59,
          name: 'CRUNCHY CRAB STEAK SUSHI',
          price: '550',
          category: 'Main Course',
          description: 'Tempura fried crab stick roll with red rice',
        },
        {
          id: 60,
          name: 'CRUNCHY VELVET MAKI',
          price: '550',
          category: 'Main Course',
          description: 'Inside topping salmon with tuna mayo with red rice',
        },
        {
          id: 61,
          name: 'ANJAL MAKI',
          price: '550',
          category: 'Main Course',
          description: 'Inside topping tuna out side salmon tuna and avocado',
        },
        {
          id: 62,
          name: 'RED DRAGON MAKI',
          price: '550',
          category: 'Main Course',
          description: 'Spicy salmon filling with tuna and avocado',
        },
        {
          id: 63,
          name: 'EBI TEMPURA URAMAKI',
          price: '400',
          category: 'Main Course',
          description:
            'Crispy fried shrimp tempura stuffed rice with pokno crust',
        },
        {
          id: 64,
          name: 'PHILADELPHIA ROLL MAKI',
          price: '500',
          category: 'Main Course',
          description: 'Salmon cucumber with cream cheese',
        },
        {
          id: 65,
          name: 'TAMAGO YAKI MAKI',
          price: '350',
          category: 'Main Course',
          description: 'Special japanese egg roll sushi',
        },
        {
          id: 66,
          name: 'KARAI MOGURO URAMAKI',
          price: '500',
          category: 'Main Course',
          description: 'Spicy toga rashi marinated bluefine tuna roll',
        },
        {
          id: 67,
          name: 'VOLCANO ROLL',
          price: '550',
          category: 'Main Course',
          description:
            'Cucumber stuffing sushi topping with shrimp and cream cheese dressing',
        },
        {
          id: 68,
          name: 'KATSU MAKI',
          price: '400',
          category: 'Main Course',
          description:
            'Japanese style crispy fried chicken and teriyaki dressing',
        },
        {
          id: 69,
          name: 'SAKE MAKI',
          price: '450',
          category: 'Main Course',
          description: 'Atlantic salmon sushi',
        },
        {
          id: 70,
          name: 'CALIFORNIA ROLL',
          price: '450',
          category: 'Main Course',
          description: 'Avocado cucumber crab meat with salmon row',
        },
        {
          id: 71,
          name: 'BROCCOLI SESAME FRIED RICE',
          price: '250 / 300 / 350',
          category: 'Rice',
          description: 'Fried rice with broccoli and sesame seeds rice',
        },
        {
          id: 72,
          name: 'BAKEMIBAP',
          price: '250 / 300 / 350',
          category: 'Rice',
          description: 'Korean style spicy fried rice with chicken sausages',
        },
        {
          id: 73,
          name: 'GOMOKU ITAME',
          price: '250 / 300 / 350',
          category: 'Rice',
          description: 'Soya sauce flavoured fried rice in Japanese style',
        },
        {
          id: 74,
          name: 'INDONESIA SPICY FRIED RICE',
          price: '250 / 300 / 350',
          category: 'Rice',
          description:
            'Indonesian seafood flavoured rice topprd with poasted egg',
        },
        {
          id: 75,
          name: 'THAI STYLE RED CURRY FRIED RICE',
          price: '250 / 300 / 350',
          category: 'Rice',
          description: 'Thai red curry flavoured fried rice with basil leaves',
        },
        {
          id: 76,
          name: 'BIBIM BAP',
          price: '250 / 300 / 350',
          category: 'Rice',
          description:
            'Sticki rice served with sauteed vegetables and choice of proteins',
        },
        {
          id: 77,
          name: 'CHINESE STYLE FRIED RICE (Veg/ Chicken/ Prawn) [Manchow]',
          price: '200 / 250 / 300',
          category: 'Rice',
          description: 'Chinese style fried rice',
        },
        {
          id: 7700,
          name: 'CHINESE STYLE FRIED RICE (Veg/ Chicken/ Prawn) [Schezwan]',
          price: '200 / 250 / 300',
          category: 'Rice',
          description: 'Chinese style fried rice',
        },
        {
          id: 78,
          name: 'CHINESE STYLE FRIED RICE (Veg/Chicken/Prawn) [Butter Garlic]',
          price: '200 / 250 / 300',
          category: 'Rice',
          description: 'Chinese style fried rice',
        },
        {
          id: 79,
          name: 'JASMINE RICE',
          price: '150',
          category: 'Rice',
          description: 'Jasmine rice',
        },
        {
          id: 80,
          name: 'BROWN RICE',
          price: '200',
          category: 'Rice',
          description: 'Brown rice',
        },
        {
          id: 81,
          name: 'STEAMED RICE',
          price: '100',
          category: 'Rice',
          description: 'Steamed rice',
        },
        {
          id: 82,
          name: 'RANDANG CURRY',
          price: '300',
          category: 'Curry',
          description: 'Indonesian style spicy chicken curry',
        },
        {
          id: 83,
          name: 'NYONYA CURRY',
          price: '250 / 300 / 350',
          category: 'Curry',
          description: 'Traditional Malaysian style curry',
        },
        {
          id: 84,
          name: 'BURMESE CURRY',
          price: '250 / 300 / 350',
          category: 'Curry',
          description: 'Southern Flavoured Burmese style curry',
        },
        {
          id: 85,
          name: 'THAI RED CURRY',
          price: '250 / 300 / 350',
          category: 'Curry',
          description: 'Thai herb flavour coconut base curry',
        },
        {
          id: 86,
          name: 'THAI GREEN CURRY',
          price: '250 / 300 / 350',
          category: 'Curry',
          description:
            'Traditional Thai curry flavoured with Kaffir leaves & Basil leaves',
        },
        {
          id: 87,
          name: 'SCHEZWAN CHILLI SAUCE',
          price: '200 / 250 / 300',
          category: 'Curry',
          description: 'Schezwan Chilli Curry',
        },
        {
          id: 88,
          name: 'SHOYU RAMEN',
          price: '300 / 350 / 400',
          category: 'Ramen Your Bowl',
          description: 'Japanese style soya based soupy noodles',
        },
        {
          id: 89,
          name: 'MISO RAMEN',
          price: '300 / 350 / 400',
          category: 'Ramen Your Bowl',
          description: 'Japanese miso based soupy noodles',
        },
        {
          id: 90,
          name: 'TANTANMEN RAMEN NOODLES',
          price: '300 / 350 / 400',
          category: 'Ramen Your Bowl',
          description: 'Korean style sesame soya based spicy noodles soup',
        },
        {
          id: 91,
          name: 'KIMCHI RAMEN NOODLES',
          price: '300 / 350 / 400',
          category: 'Ramen Your Bowl',
          description: 'Spicy kamechi based noodles soup',
        },
        {
          id: 92,
          name: 'SPICY BLACK BEAN RAMEN NOODLES',
          price: '300 / 350 / 400',
          category: 'Ramen Your Bowl',
          description: 'A soupy noodle with spicy black been based',
        },
        {
          id: 93,
          name: 'PATHAI',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description:
            'Old style Thai rice noodle with Plum sugar and Tamarind juice',
        },
        {
          id: 94,
          name: 'BAMI GORENG',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description:
            'Indonesian style soft noodles with Chinese Cabbage Bokcho y Soya based',
        },
        {
          id: 95,
          name: 'THAI DRUNKEN NOODLES',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description:
            'Red Wine Flavoured Thai rice sticks noodles kaffir lime leaves with thai bird chilli',
        },
        {
          id: 96,
          name: 'YAKI UDON NOODLES',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description:
            'Japanese noodles Bokchoy Chinese cabbage and Bell pepper',
        },
        {
          id: 97,
          name: 'KOREAN HOME MADE NOODLES',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description: 'Gluten free Korean home made style spicy noodles',
        },
        {
          id: 98,
          name: 'JAPCHAE',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description:
            'Popular korean glass noodles served with Tangy Spicy Sauce',
        },
        {
          id: 99,
          name: 'SOFT NOODLES (Veg - Chicken - Prawn)',
          price: '180 / 220 / 250',
          category: 'Noodles',
          description: 'Soft noodles',
        },
        {
          id: 100,
          name: 'SOFT NOODLES - BUTTER GARLIC/ SCHEZWAN/ HAKKA',
          price: '250 / 300 / 350',
          category: 'Noodles',
          description: 'Butter garlic/ schezwan/ hakka noodles',
        },
        {
          id: 101,
          name: 'CHILLI BASIL CHICKEN DIMSUM',
          price: '350',
          category: 'Dimsum',
          description: 'Chilli basil chicken dimsum',
        },
        {
          id: 102,
          name: 'SHRIMP HARGO',
          price: '450',
          category: 'Dimsum',
          description: 'Shrimp hargo dimsum',
        },
        {
          id: 103,
          name: 'CHICKEN SUMAI',
          price: '350',
          category: 'Dimsum',
          description: 'Chicken sumai dimsum',
        },
        {
          id: 104,
          name: 'WATER CHESTNUTS & BROCCOLI DIMSUM',
          price: '300',
          category: 'Dimsum',
          description: 'Water chestnuts & broccoli dimsum',
        },
        {
          id: 105,
          name: 'CORN AND SPINACH DIMSUM',
          price: '300',
          category: 'Dimsum',
          description: 'Corn and spinach dimsum',
        },
        {
          id: 106,
          name: 'CRUNCHY VEG DIMSUM',
          price: '300',
          category: 'Dimsum',
          description: 'Crunchy veg dimsum',
        },
        {
          id: 107,
          name: 'SHIITAKE MUSHROOM DIMSUM',
          price: '300',
          category: 'Dimsum',
          description: 'Shiitake mushroom dimsum',
        },
        {
          id: 108,
          name: 'STEAMED MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Steamed momo',
        },
        {
          id: 109,
          name: 'FRIED MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Fried momo',
        },
        {
          id: 110,
          name: 'SCHEZWAN FRIED MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Schezwan fried momo',
        },
        {
          id: 111,
          name: 'JHOL MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Jhol momo',
        },
        {
          id: 112,
          name: 'SOUP MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Soup momo',
        },
        {
          id: 113,
          name: 'SIZZLER MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Sizzler momo',
        },
        {
          id: 114,
          name: 'PORK MOMO',
          price: '170 / 200 / 300',
          category: 'Dimsum',
          description: 'Pork momo',
        },
        {
          id: 115,
          name: 'TAIPO MOMO',
          price: '170 / 200 / 250',
          category: 'Dimsum',
          description: 'Taipo momo',
        },
        {
          id: 116,
          name: 'GREEN DRAGON MAKI',
          price: '400',
          category: 'Robatayaki',
          description: 'Cucumber carrot asparagus spinach with avocado',
        },
        {
          id: 117,
          name: 'TOFU TERIYAKI MAKI',
          price: '400',
          category: 'Robatayaki',
          description: 'Fried tofu with teriyaki sauce',
        },
        {
          id: 118,
          name: 'CHEESE YASAI MAKI',
          price: '400',
          category: 'Robatayaki',
          description: 'Green veg stopping with cheese smoke',
        },
        {
          id: 119,
          name: 'VEG CALIFORNIA',
          price: '400',
          category: 'Robatayaki',
          description: 'Cream cheese avocado with green veg',
        },
        {
          id: 120,
          name: 'AVOCADO URAMAKI',
          price: '350',
          category: 'Robatayaki',
          description: 'A simply roll with avocado and sesame seeds',
        },
        {
          id: 121,
          name: 'YASAI TEMPURA URAMAKI',
          price: '350',
          category: 'Robatayaki',
          description: 'Veg tempura roll with ponko crusts',
        },
        {
          id: 122,
          name: 'KAPPA MAKI',
          price: '300',
          category: 'Robatayaki',
          description: 'Japanese cucumber roll with mayo',
        },
        {
          id: 123,
          name: 'YASAI HOSO MAKI',
          price: '300',
          category: 'Robatayaki',
          description: 'Rice with green leaf veg out side nori set',
        },
        {
          id: 124,
          name: 'SHIITAKE NO MAKI',
          price: '300',
          category: 'Robatayaki',
          description: 'Marinated shiitake mushrooms with mayo',
        },
        {
          id: 125,
          name: 'KIMBAP',
          price: '400',
          category: 'Robatayaki',
          description:
            'Kimbap is korean just like sushi sesame oil flavours Veg /chicken / seafood',
        },
        {
          id: 127,
          name: 'CHICKEN SKEWERED Robatayaki Spizy Honey Miso Glazed',
          price: '350',
          category: 'Robatayaki',
          description:
            'Soya bean glaze with togarashi honey marinated meat grilled veg/grill',
        },
        {
          id: 128,
          name: 'CHICKEN WINGS Robatayaki Spizy Honey Miso Glazed',
          price: '400',
          category: 'Robatayaki',
          description:
            'Soya bean glaze with togarashi honey marinated meat grilled veg/grill',
        },
        {
          id: 129,
          name: 'FISH Robatayaki Spizy Honey Miso Glazed',
          price: '500',
          category: 'Robatayaki',
          description:
            'Soya bean glaze with togarashi honey marinated meat grilled veg/grill',
        },
        {
          id: 130,
          name: 'PRAWNS Robatayaki Spizy Honey Miso Glazed',
          price: '450',
          category: 'Robatayaki',
          description:
            'Soya bean glaze with togarashi honey marinated meat grilled veg/grill',
        },
        {
          id: 131,
          name: 'PORK RIBS Robatayaki Spizy Honey Miso Glazed',
          price: '450',
          category: 'Robatayaki',
          description:
            'Soya bean glaze with togarashi honey marinated meat grilled veg/grill',
        },
        {
          id: 132,
          name: 'TERIYAKI CHICKEN',
          price: '300',
          category: 'Teriyaki',
          description: 'Teriyaki marinated chicken',
        },
        {
          id: 133,
          name: 'TERIYAKI FISH',
          price: '350',
          category: 'Teriyaki',
          description: 'Teriyaki marinated fish',
        },
        {
          id: 134,
          name: 'TERIYAKI PORK BELLY',
          price: '400',
          category: 'Teriyaki',
          description: 'Teriyaki marinated pork belly',
        },
        {
          id: 135,
          name: 'KARAI CHICKEN SKEWRED',
          price: '300',
          category: 'Robatayaki',
          description: 'Karai sauce chicken skewred',
        },
        {
          id: 136,
          name: 'GRILLED PRAWN SAKEWRED',
          price: '500',
          category: 'Robatayaki',
          description: 'Grilled prawn sakewred',
        },
        {
          id: 137,
          name: 'KARAI PORK BELLY',
          price: '550',
          category: 'Robatayaki',
          description: 'Karai pork belly',
        },
        {
          id: 138,
          name: 'MALAY CHICKEN SATAY',
          price: '450',
          category: 'Malay Satay',
          description: 'Malaysian nyonya causing flavour skewers chicken satay',
        },
        {
          id: 139,
          name: 'MALAY PRAWN SATAY',
          price: '500',
          category: 'Malay Satay',
          description: 'Malaysian nyonya causing flavour skewers prawn satay',
        },
        {
          id: 140,
          name: 'VIETNAMESE GRILL PRAWN',
          price: '550',
          category: 'Robatayaki',
          description: 'Vietnamese style marinated grill prawn',
        },
        {
          id: 141,
          name: 'Salmon Sashmi Sesame Platter',
          price: '900',
          category: 'Sesame Platters',
          description: 'Sesame Platter',
        },
        {
          id: 142,
          name: 'Sesame Crust Tuna Sesame Platter',
          price: '900',
          category: 'Sesame Platters',
          description: 'Sesame Platter',
        },
        {
          id: 143,
          name: 'Tomago Yaki Sesame Platter',
          price: '900',
          category: 'Sesame Platters',
          description: 'Sesame Platter',
        },
        {
          id: 144,
          name: 'Red Sniper Sesame Platter',
          price: '900',
          category: 'Sesame Platters',
          description: 'Sesame Platter',
        },
        {
          id: 145,
          name: '7 Sisters Sushi Platter',
          price: '900',
          category: 'Sushi Platters',
          description:
            'Seven types of Sushi Sashmi with Nigri Platter - Sake Nigri (2pc), Tune Nigri (2pc), Tomago Nigri (2pc), Volcaon Roll (4pc), Tune Hashmi (2pc), Sake Sashmi (1pc), Egg Rol (2pc)',
        },
        {
          id: 146,
          name: 'Mint Ice Cream',
          price: '100',
          category: 'Dessert',
          description: 'Dessert',
        },
        {
          id: 147,
          name: 'Chocolate Ice Cream',
          price: '150',
          category: 'Dessert',
          description: 'Dessert',
        },
        {
          id: 148,
          name: 'Wasabi Ice Cream',
          price: '150',
          category: 'Dessert',
          description: 'Dessert',
        },
        {
          id: 149,
          name: 'Pineapple Roll with Ice Cream',
          price: '150',
          category: 'Dessert',
          description: 'Dessert',
        },
        {
          id: 150,
          name: 'Chocolate Lava Cake with Ice Cream',
          price: '200',
          category: 'Dessert',
          description: 'Dessert',
        },
        {
          id: 151,
          name: 'Darjeeling Green Tea',
          price: '149',
          category: 'Green Tea',
          description: 'Green Tea',
        },
        {
          id: 152,
          name: 'Matcha Green Tea',
          price: '149',
          category: 'Green Tea',
          description: 'Green Tea',
        },
        {
          id: 153,
          name: 'Bhutanese Green Tea',
          price: '149',
          category: 'Green Tea',
          description: 'Green Tea',
        },
        {
          id: 154,
          name: 'Korean Buricha',
          price: '149',
          category: 'Green Tea',
          description: 'Green Tea',
        },
        {
          id: 155,
          name: 'Jasmine Tea',
          price: '149',
          category: 'Green Tea',
          description: 'Green Tea',
        },
      ],
    },
  },
  // Add more restaurants here:
  // 'restaurant-key': { restaurantName, logo, description, menuData }
};
