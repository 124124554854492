import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from './AuthContext';

export interface UserRestaurant {
  restaurantId: string;
}

interface UserRestaurantContextProps {
  userRestaurant?: UserRestaurant;
  setUserRestaurant: (restaurant: UserRestaurant) => void;
}

export const UserRestaurantContext = createContext<
  UserRestaurantContextProps | undefined
>(undefined);

interface UserRestaurantProviderProps {
  children: ReactNode;
}

export const UserRestaurantProvider: React.FC<UserRestaurantProviderProps> = ({
  children,
}) => {
  const [userRestaurant, setUserRestaurant] = useState<
    UserRestaurant | undefined
  >(undefined);
  const { user } = useContext(AuthContext)!;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const restaurantIdParam = urlParams.get('restaurantId');
    setUserRestaurant({
      restaurantId: restaurantIdParam != null ? restaurantIdParam : '',
    });
  }, [user]);

  const value = {
    userRestaurant,
    setUserRestaurant,
  };

  return (
    <UserRestaurantContext.Provider value={value}>
      {children}
    </UserRestaurantContext.Provider>
  );
};
