import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CUSTOMER_ROLE, RESTAURANT_ROLE } from '../utilities/Constants';
import useAuthRedirect from '../hooks/AuthRedirect';
import LinthImage from '../assets/Linth.png';

const LoginOptions: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber; // Retrieve phoneNumber from previous page
  const from = location.state?.from || '/';
  const queryString = location.state?.queryString || '';
  useAuthRedirect(); // Redirect if already authenticated

  useEffect(() => {
    if (!location.state?.phoneNumber) {
      navigate('/');
    }
  }, []);

  const handleLoginAsCustomer = () => {
    navigate('/verify_otp', {
      state: { phoneNumber, role: CUSTOMER_ROLE, from, queryString },
    });
  };

  const handleLoginAsRestaurant = () => {
    navigate('/verify_otp', {
      state: { phoneNumber, role: RESTAURANT_ROLE, from, queryString },
    });
  };

  return (
    <>
      <div className="md:flex md:flex-row md:items-center md:justify-around md:align-middle">
        <div className="md:mr-32">
          <button
            onClick={() => navigate(-1)}
            className="absolute left-6 top-6 z-50 flex items-center rounded-lg bg-gray-800/90 px-4 py-2 text-sm font-semibold text-white shadow-lg transition duration-200 hover:bg-gray-700 max-md:left-2 max-md:top-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="mr-2 size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back
          </button>
          <div
            style={{
              maxWidth: '400px',
              margin: '0 auto',
              padding: '20px',
              textAlign: 'center',
            }}
          >
            <h1 className="mt-14">Login Options</h1>
            <p>
              Welcome! You are logging in with phone number:{' '}
              <strong>{phoneNumber}</strong>
            </p>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={handleLoginAsCustomer}
                style={{
                  width: '100%',
                  padding: '10px',
                  margin: '10px 0',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Login as Customer
              </button>
              <button
                onClick={handleLoginAsRestaurant}
                style={{
                  width: '100%',
                  padding: '10px',
                  margin: '10px 0',
                  backgroundColor: '#28a745',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Login as Restaurant
              </button>
            </div>
          </div>
        </div>
        <div className="max-md:hidden md:ml-32">
          <img src={LinthImage} alt="decorative" style={{ height: '80vh' }} />
        </div>
      </div>
    </>
  );
};

export default LoginOptions;
