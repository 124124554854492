import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { CheckInItem, CheckInItemContext } from '../context/CheckInContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import { ReservationContext } from '../context/ReservationContext';

const enum ModalMode {
  Accept,
  Cancel,
  None,
}

const CheckIn: React.FC = () => {
  const { checkIns, isRefreshing, lastRefreshTime, setCheckIns } =
    useContext(CheckInItemContext)!;
  const { reservations } = useContext(ReservationContext)!;
  const authContext = useContext(AuthContext);
  const user = authContext?.user;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState(ModalMode.None);
  const [selectedEntry, setSelectedEntry] = useState<CheckInItem | undefined>(
    undefined,
  );
  const [createLoading, setLoading] = useState(false);

  const openModal = (entry: CheckInItem, mode: ModalMode) => {
    setSelectedEntry(entry);
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEntry(undefined);
    setIsModalOpen(false);
    setModalMode(ModalMode.None);
  };

  const checkInEntry = async () => {
    if (!selectedEntry) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/checkInReservation/${selectedEntry.userId}/${selectedEntry.reservationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + user?.authToken,
          },
          body: JSON.stringify({
            userName: selectedEntry.userName,
            noOfPeople: selectedEntry.noOfPeople,
          }),
        },
      );

      if (response.ok) {
        // Update the context to remove the checked-in entry
        setCheckIns(
          checkIns.filter((entry) => entry.userId !== selectedEntry.userId),
        );
        toast.success('User checked in successfully!');
      } else {
        toast.error('Failed to check in the entry.');
      }
    } catch (error) {
      console.error('[CRITICAL] Error checking in:', error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const cancelEntry = async () => {
    if (!selectedEntry) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/cancelReservation/${selectedEntry.userId}/${selectedEntry.reservationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + user?.authToken,
          },
          body: JSON.stringify({
            userName: selectedEntry.userName,
            noOfPeople: selectedEntry.noOfPeople,
          }),
        },
      );

      if (response.ok) {
        // Update the context to remove the checked-in entry
        setCheckIns(
          checkIns.filter((entry) => entry.userId !== selectedEntry.userId),
        );
        toast.success('Reservation cancelled successfully!');
      } else {
        toast.error('Failed to cancel the entry.');
      }
    } catch (error) {
      console.error('[CRITICAL] Error cancelling reservation in:', error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const renderModal = () => {
    if (!isModalOpen) return null;
    if (modalMode == ModalMode.Accept)
      return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Confirm Check-In</h3>
            <p className="mb-4">
              Are you sure you want to check in this entry for{' '}
              {selectedEntry?.userName}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
                onClick={closeModal}
                disabled={createLoading}
              >
                No
              </button>
              <button
                className="rounded-md bg-green-500 px-4 py-2 text-white"
                onClick={() => checkInEntry()}
                disabled={createLoading}
              >
                {createLoading ? 'Checking In...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>,
        document.body, // Ensures the modal is rendered at the root level
      );
    if (modalMode == ModalMode.Cancel)
      return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Cancel Reservation</h3>
            <p className="mb-4">
              Are you sure you want to cancel this check-in & the corresponding
              reservation for {selectedEntry?.userName}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
                onClick={closeModal}
                disabled={createLoading}
              >
                No
              </button>
              <button
                className="rounded-md bg-red-500 px-4 py-2 text-white"
                onClick={() => cancelEntry()}
                disabled={createLoading}
              >
                {createLoading ? 'Cancelling...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>,
        document.body, // Ensures the modal is rendered at the root level
      );
  };

  return (
    <div className="h-full rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md">
      <div className="mb-2 flex items-center justify-between">
        <div className="text-2xl font-bold max-sm:text-lg">Check Ins</div>
        {/* Loading or Last Refresh Indicator */}
        {isRefreshing ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <div className="text-sm text-gray-300 max-md:text-xs">
            Updated: {lastRefreshTime}
          </div>
        )}
        <div>
          Total: <b>{checkIns.length}</b>
        </div>
      </div>
      <div className="max-h-48 overflow-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-white/20 max-sm:text-sm">
              <th className="px-4 py-2 text-left font-semibold">Name</th>
              <th className="px-4 py-2 text-left font-semibold">Phone</th>
              <th className="px-4 py-2 text-left font-semibold">Guests</th>
              <th className="px-4 py-2 text-left font-semibold">Action</th>
              <th className="px-4 py-2 text-left font-semibold">Delete</th>
            </tr>
          </thead>
          <tbody className="max-sm:text-sm">
            {checkIns.map((checkIn, index) => (
              <tr
                key={checkIn.userId}
                className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
              >
                <td className="px-4 py-2">{checkIn.userName}</td>
                <td className="px-4 py-2">{checkIn.userId}</td>
                <td className="px-4 py-2">{checkIn.noOfPeople}</td>
                <td className="px-4 py-2">
                  <button
                    className="text-green-400 underline transition hover:text-green-500"
                    onClick={() => openModal(checkIn, ModalMode.Accept)}
                  >
                    Check In
                  </button>
                </td>
                <td className="px-4 py-2">
                  <button
                    className="text-red-400 underline transition hover:text-red-500"
                    onClick={() => openModal(checkIn, ModalMode.Cancel)}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {renderModal()}
      {window.innerWidth < 768 && (
        <>
          <div className="mb-2 mt-4 flex items-center justify-between">
            <div className="text-2xl font-bold max-sm:text-lg">
              Reservations
            </div>
            {/* Loading or Last Refresh Indicator */}
            {isRefreshing ? (
              <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
            ) : (
              <div className="text-sm text-gray-300 max-md:text-xs">
                Updated: {lastRefreshTime}
              </div>
            )}
            <div>
              Total:{' '}
              <b>
                {
                  reservations.filter(
                    (reserve) =>
                      reserve.status === 'Pending' &&
                      !reserve.isEligibleForCheckIn,
                  ).length
                }
              </b>
            </div>
          </div>

          {/* Reservation List */}
          <div className="mb-2 max-h-48 overflow-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-white/20 max-sm:text-sm">
                  <th className="px-4 py-2 text-left font-semibold">Name</th>
                  <th className="px-4 py-2 text-left font-semibold">Phone</th>
                  <th className="px-4 py-2 text-left font-semibold">Guests</th>
                  <th className="px-4 py-2 text-left font-semibold">
                    Position in Q
                  </th>
                </tr>
              </thead>
              <tbody className="max-sm:text-sm">
                {reservations &&
                  reservations
                    .filter(
                      (reserve) =>
                        reserve.status === 'Pending' &&
                        !reserve.isEligibleForCheckIn,
                    )
                    .map((reservation, index) => (
                      <tr
                        key={reservation.userId}
                        className={`${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} transition`}
                      >
                        <td className="px-4 py-2">{reservation.userName}</td>
                        <td className="px-4 py-2">{reservation.userId}</td>
                        <td className="px-4 py-2">{reservation.noOfPeople}</td>
                        <td className="px-4 py-2">{reservation.peopleAhead}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckIn;
