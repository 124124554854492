import React, { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const CustomerMenuLayout: React.FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the menu

  const handleMenuOption = (option: string) => {
    switch (option) {
      case 'logout':
        authContext?.logout();
        window.location.reload();
        navigate('/'); // Redirect to login page
        break;
      case 'reservations':
        navigate('/user_reservations', { replace: true });
        break;
      case 'profile':
        navigate('/profile', { replace: true });
        break;
      case 'home':
        navigate('/customer_dashboard', { replace: true });
        break;
      default:
        break;
    }
    setIsMenuOpen(false); // Close the menu
  };

  return (
    <div>
      {/* Header with Menu Button */}
      <div className="absolute right-10 top-10 z-30 rounded-md bg-white px-4 py-2 shadow-lg max-md:right-4 max-md:top-2">
        {/* Hamburger Menu for Mobile */}
        <button
          className="text-[#FF4A00] focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'
              }
            />
          </svg>
        </button>
        {isMenuOpen && (
          <ul className="block w-full py-2 text-left text-black">
            <li
              onClick={() => handleMenuOption('home')}
              style={{ padding: '10px', cursor: 'pointer' }}
              className="hover:bg-gray-100"
            >
              Home
            </li>
            <li
              onClick={() => handleMenuOption('reservations')}
              style={{ padding: '10px', cursor: 'pointer' }}
              className="hover:bg-gray-100"
            >
              My Reservations
            </li>
            <li
              onClick={() => handleMenuOption('profile')}
              style={{ padding: '10px', cursor: 'pointer' }}
              className="hover:bg-gray-100"
            >
              My Profile
            </li>
            <li
              onClick={() => handleMenuOption('logout')}
              style={{ padding: '10px', cursor: 'pointer', color: 'red' }}
              className="hover:bg-gray-100"
            >
              Logout
            </li>
          </ul>
        )}
      </div>

      {/* Main Content */}
      <main>
        <Outlet /> {/* Renders the child route content */}
      </main>
    </div>
  );
};

export default CustomerMenuLayout;
