// src/App.tsx
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { TableProvider } from './context/TableContext';
import { ReservationProvider } from './context/ReservationContext';
import AppRoutes from './routes/Routes';
import { CheckInItemProvider } from './context/CheckInContext';
import { RestaurantProvider } from './context/RestaurantContext';
import { ValetParkingProvider } from './context/ValetParkingContext';
import { UserRestaurantProvider } from './context/UserRestaurantContext';
import { FooterTabsProvider } from './context/FooterTabsContext';
import './App.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <RestaurantProvider>
          <TableProvider>
            <UserRestaurantProvider>
              <CheckInItemProvider>
                <ReservationProvider>
                  <ValetParkingProvider>
                    <FooterTabsProvider>
                      <AppRoutes />
                    </FooterTabsProvider>
                  </ValetParkingProvider>
                </ReservationProvider>
              </CheckInItemProvider>
            </UserRestaurantProvider>
          </TableProvider>
        </RestaurantProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
