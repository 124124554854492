import React from 'react';
import './LoopedBackground.css';

export const LoopedBackground: React.FC = () => {
  return (
    <div className="absolute -z-10">
      <div className="circle-one absolute rounded-full max-md:size-72" />
      <div className="circle-two absolute rounded-full max-md:size-72" />
      <div className="circle-three absolute rounded-full max-md:size-72" />
    </div>
  );
};
