import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RestaurantHeader } from '../components/Hamburger/RestaurantHeader';
import { StaffPersonnel } from '../context/models/StaffPersonnel';
import { toast } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { AuthContext, SubRole } from '../context/AuthContext';

enum ModalMode {
  Create,
  Remove,
  None,
}

const StaffListing: React.FC = () => {
  const authContext = useContext(AuthContext);

  const [staff, setStaff] = useState<StaffPersonnel[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const [isCTALoading, setIsCTALoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.None);
  const [selectedEntry, setSelectedEntry] = useState<
    StaffPersonnel | undefined
  >(undefined);

  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [subRole, setSubRole] = useState<SubRole>(SubRole.Admin);

  const openModal = (entry: StaffPersonnel | undefined) => {
    setSelectedEntry(entry);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setName('');
    setPhone('');
    setSubRole(SubRole.Admin);
    setModalMode(ModalMode.None);
    setSelectedEntry(undefined);
    setIsModalOpen(false);
  };

  const removeStaff = async () => {
    try {
      setIsCTALoading(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/deleteRestaurantLinkedAccount/${selectedEntry?.restaurantAccountId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      if (!response.ok) {
        toast.error(
          'An unknown error happened while removing the user, Please try again later.',
        );
        return;
      }

      setStaff(
        staff.filter(
          (person) =>
            person.restaurantAccountId !== selectedEntry?.restaurantAccountId ||
            person.role !== selectedEntry?.role,
        ),
      );

      toast.success('Successfully removed user.');
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsCTALoading(false);
    }
  };

  const addStaff = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsCTALoading(true);

    if (!name || !name) {
      alert('Please enter a valid name of the person.');
      setIsCTALoading(false);
      return;
    }

    if (!phone || phone.length != 10) {
      alert('Please enter a valid 10 digit phone number of the person.');
      setIsCTALoading(false);
      return;
    }

    const phoneToSend = '+91' + phone;

    const response = await fetch(
      `${API_GATEWAY_ENDPOINT}/addNewRestaurantLinkedAccount`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          role: 'Restaurant',
          Authorization: 'Bearer ' + authContext?.user?.authToken,
        },
        body: JSON.stringify({
          restaurantAccountId: phoneToSend,
          restaurantLinkedAccountRole: subRole,
          restaurantAccountIdName: name,
        }),
      },
    );

    setIsCTALoading(false);

    if (!response.ok) {
      toast.error(
        'An unknown error happened while removing the user, Please try again later.',
      );
      return;
    }

    toast.success('Successfully added user.');
    setStaff([
      ...staff,
      {
        restaurantAccountId: phoneToSend,
        role: subRole,
        restaurantAccountIdName: name,
        restaurantId: authContext?.user?.username,
      },
    ]);
    closeModal();
  };

  const getStaff = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/fetchAllRestaurantLinkedAccounts`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + authContext?.user?.authToken,
          },
        },
      );

      const data = await response.json();
      setStaff(
        data.restaurantLinkedAccountDetailsList.map((entry: StaffPersonnel) => {
          return {
            restaurantId: entry.restaurantId,
            restaurantAccountId: entry.restaurantAccountId,
            restaurantAccountIdName: entry.restaurantAccountIdName,
            role: entry.role,
          };
        }),
      );
      setIsCTALoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaff();
  }, []);

  const renderModal = () => {
    if (!isModalOpen || modalMode === ModalMode.None) return null;

    if (modalMode === ModalMode.Create) {
      return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          {/* White modal container */}
          <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
            {/* Close Button */}
            <button
              onClick={() => closeModal()}
              className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 focus:outline-none"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h3 className="mb-4 text-xl font-semibold text-black">
              Add Personnel
            </h3>
            <form onSubmit={addStaff} className="space-y-4 max-sm:text-sm">
              {/* Name Field */}
              <div>
                <label
                  htmlFor="name"
                  className="mb-2 block font-semibold text-black"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  className="w-full rounded-md border
                                border-gray-300 bg-gray-100 px-4
                                py-2 text-gray-800 shadow-sm transition
                                duration-200 ease-in-out placeholder:text-gray-400
                                focus:outline-none focus:ring-2 focus:ring-pink-500"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                {/* Phone Number Field */}
                <div>
                  <label
                    htmlFor="phone"
                    className="mb-2 block font-semibold text-black"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="9876543210"
                    className="w-full rounded-md border
                               border-gray-300 bg-gray-100 px-4
                               py-2 text-gray-800 shadow-sm transition
                               duration-200 ease-in-out placeholder:text-gray-400
                               focus:outline-none focus:ring-2 focus:ring-pink-500"
                    value={phone.replace('+91', '')}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                {/* Sub Role Selection */}
                <div>
                  <label
                    htmlFor="guests"
                    className="mb-2 block font-semibold text-black"
                  >
                    Sub Role
                  </label>
                  <div className="relative">
                    <select
                      id="guests"
                      name="guests"
                      className="w-full appearance-none rounded-md
                                 border border-gray-300 bg-gray-100 px-4 py-2
                                 text-gray-800 shadow-sm transition duration-200
                                 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-500"
                      value={subRole.toString()}
                      onChange={(e) => setSubRole(e.target.value as SubRole)}
                    >
                      <option value={SubRole.Reception}>Reception</option>
                      <option value={SubRole.Valet}>Valet</option>
                      <option value={SubRole.Admin}>Admin</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <svg
                        className="size-4 fill-current text-gray-600"
                        viewBox="0 0 20 20"
                      >
                        <path d="M5.23 7.21a.75.75 0 00-1.06 1.06L9.485 13.6a.75.75 0 001.06 0L15.83 8.27a.75.75 0 00-1.06-1.06L10 11.02 5.23 7.21z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              {/* CTA */}
              {isCTALoading ? (
                <div className="mx-auto size-6 animate-spin rounded-full border-4 border-gray-300 border-t-blue-500"></div>
              ) : (
                <button
                  type="submit"
                  className="rounded-md bg-gradient-to-r from-pink-500 to-orange-500 px-4
                             py-2 font-bold text-white shadow-md
                             hover:from-pink-600 hover:to-orange-600
                             focus:outline-none focus:ring-2 focus:ring-pink-500"
                >
                  Add Person
                </button>
              )}
            </form>
          </div>
        </div>
      );
    }

    if (modalMode === ModalMode.Remove) {
      return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
            {/* Close Button */}
            <button
              onClick={() => closeModal()}
              className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 focus:outline-none"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h3 className="mb-4 text-xl font-semibold text-black">
              Remove Personnel
            </h3>
            <p className="mb-4 text-gray-800">
              Are you sure you want to remove user phone{' '}
              <span className="font-bold">{selectedEntry?.restaurantId}</span>{' '}
              from staff?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
                onClick={closeModal}
                disabled={isCTALoading}
              >
                No
              </button>
              <button
                className="rounded-md bg-red-500 px-4 py-2 text-white"
                onClick={removeStaff}
                disabled={isCTALoading}
              >
                {isCTALoading ? 'Deleting...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>,
        document.body,
      );
    }
  };

  return (
    <div className="bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      {/* Header */}
      <RestaurantHeader />
      {/* Content */}

      <div className="min-md:px-6 relative h-full rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md max-sm:p-4">
        <div className="mb-2 flex flex-col items-start justify-start">
          <div className="flex w-full justify-between pb-6">
            <div className="text-2xl font-bold max-sm:text-lg">Staff</div>
            <button
              onClick={() => {
                setModalMode(ModalMode.Create);
                openModal(undefined);
              }}
              className="rounded-md bg-gradient-to-r from-pink-500 to-orange-500 px-4
                         py-2 font-bold text-white shadow-md
                         hover:from-pink-600 hover:to-orange-600
                         focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              Add Person
            </button>
          </div>
          {/* Loading or Last Refresh Indicator */}
          {isloading ? (
            <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
          ) : (
            <div className="mb-2 max-h-48 w-full overflow-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-white/20 max-sm:text-sm">
                    <th className="px-4 py-2 text-left font-semibold">Name</th>
                    <th className="px-4 py-2 text-left font-semibold">Phone</th>
                    <th className="px-4 py-2 text-left font-semibold">Role</th>
                    <th className="px-4 py-2 text-left font-semibold">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="max-sm:text-sm">
                  {staff &&
                    staff.map((person, index) => (
                      <tr
                        key={person.restaurantAccountId}
                        className={`${
                          index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'
                        } transition`}
                      >
                        <td className="px-4 py-2">
                          {person.restaurantAccountIdName}
                        </td>
                        <td className="px-4 py-2">
                          {person.restaurantAccountId}
                        </td>
                        <td className="px-4 py-2">{person.role}</td>
                        <td className="px-4 py-2">
                          {!isCTALoading && (
                            <button
                              className="text-red-400 underline transition hover:text-red-500"
                              onClick={() => {
                                setModalMode(ModalMode.Remove);
                                openModal(person);
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default StaffListing;
