// Menu.tsx
import React, { useEffect, useRef, useState } from 'react';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { restaurantMenus, RestaurantMenu } from '../utilities/RestaurantMenus'; // adjust path accordingly

// ... any other necessary imports

const Menu = () => {
  // Get the restaurant name from the URL params.
  const { restaurantName } = useParams();

  // Convert the restaurantName to a URL-friendly key
  const restaurantKey = restaurantName
    ? restaurantName.toLowerCase().replace(/\s+/g, '-')
    : '+917671913062';

  // Look up the restaurant configuration.
  const restaurantMenu: RestaurantMenu | undefined =
    restaurantMenus[restaurantKey];

  // If no configuration is found, you might want to show a "Not Found" page.
  if (!restaurantMenu) {
    console.log('Restaurant Key: ', restaurantKey);
    return <div>Restaurant not found</div>;
  }

  // Destructure the restaurant configuration.
  const {
    menuData,
    logo,
    description,
    restaurantName: displayName,
  } = restaurantMenu;

  // Local state for menu behavior.
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isFabMenuOpen, setFabMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userInteracting, setUserInteracting] = useState(false);
  const [typedPlaceholder, setTypedPlaceholder] = useState('');
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  // Use the categories from the current restaurant's menuData.
  const categoryNames = menuData.categories.map((c) => c.name);

  const addRupeeSymbol = (price: string) => {
    return price
      .split('/')
      .map((p) => `₹${p}`)
      .join('/');
  };

  // Typewriter Effect (runs only if user isn't interacting)
  useEffect(() => {
    if (userInteracting) {
      if (typingIntervalRef.current) {
        clearTimeout(typingIntervalRef.current);
        typingIntervalRef.current = null;
      }
      return;
    }

    const currentCategory = categoryNames[categoryIndex] || '';
    const speed = isDeleting ? 50 : 100;

    typingIntervalRef.current = setTimeout(() => {
      if (!isDeleting) {
        setTypedPlaceholder(currentCategory.slice(0, charIndex + 1));
        setCharIndex(charIndex + 1);
        if (charIndex + 1 === currentCategory.length) {
          setTimeout(() => setIsDeleting(true), 1000);
        }
      } else {
        setTypedPlaceholder(currentCategory.slice(0, charIndex - 1));
        setCharIndex(charIndex - 1);
        if (charIndex - 1 < 0) {
          setIsDeleting(false);
          setCategoryIndex((prev) => (prev + 1) % categoryNames.length);
        }
      }
    }, speed);

    return () => {
      if (typingIntervalRef.current) {
        clearTimeout(typingIntervalRef.current);
      }
    };
  }, [charIndex, categoryIndex, isDeleting, userInteracting, categoryNames]);

  const searchBarWidth = userInteracting ? 'w-2/3' : 'w-1/2';

  // Filter the items based on selected category and search term.
  const filteredItems = menuData.items
    .filter(
      (item) =>
        selectedCategory === 'All' || item.category === selectedCategory,
    )
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const handleFocus = () => setUserInteracting(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!userInteracting) setUserInteracting(true);
    setSearchTerm(e.target.value);
  };

  // The MenuItem component remains similar.
  const MenuItem = ({ item }: { item: (typeof menuData.items)[0] }) => (
    <div className="flex flex-col rounded-lg bg-white p-4 shadow-md transition-shadow hover:shadow-lg">
      <h3 className="text-lg font-semibold">{item.name}</h3>
      <p className="mb-2 text-sm text-gray-500">{item.description}</p>
      <span className="font-bold text-rose-500">
        {addRupeeSymbol(item.price)}
      </span>
    </div>
  );

  return (
    <div className="flex h-screen flex-col justify-start">
      {/* Header with restaurant info */}
      <div className="absolute right-10 top-10 z-30 rounded-md bg-white px-4 py-2 shadow-lg max-md:right-4 max-md:top-2">
        <button
          className="text-[#FF4A00] focus:outline-none"
          onClick={() => navigate('/customer_dashboard')}
        >
          Home
        </button>
      </div>
      <div className="relative w-full bg-rose-500 py-4 pb-16 text-white shadow-lg">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center">
            <img
              src={logo}
              alt={`${displayName} logo`}
              className="mr-3 size-24 rounded-full border border-white/20 object-cover shadow-lg"
            />
            <div className="flex flex-col">
              <div className="text-xl font-bold">{displayName}</div>
              <div className="w-2/3 font-medium">{description}</div>
            </div>
          </div>
        </div>
        {/* Search Bar */}
        <div
          className={`menu-search absolute bottom-0 left-1/2 flex -translate-x-1/2 -translate-y-1/4 justify-between rounded-xl bg-white px-4 py-2 text-black shadow-lg transition-all duration-300 ${searchBarWidth}`}
        >
          <input
            alt="search input"
            className="outline-none placeholder:text-gray-400"
            placeholder={userInteracting ? 'Search...' : typedPlaceholder}
            value={userInteracting ? searchTerm : ''}
            onFocus={handleFocus}
            onChange={handleChange}
          />
          <MagnifyingGlassCircleIcon className="ml-4 size-6 text-rose-500" />
        </div>
      </div>

      <div className="-mt-8 w-full flex-1 rounded-t-3xl bg-gray-50 pt-8 shadow-xl">
        {/* Menu Items Grid */}
        <div className="mt-4 grid grid-cols-2 gap-4 pb-4">
          {filteredItems.map((item) => (
            <MenuItem key={item.id} item={item} />
          ))}
        </div>
      </div>

      {/* Floating Action Button (FAB) for Categories */}
      <div className="fixed bottom-6 right-6">
        <div className="relative">
          <button
            onClick={() => setFabMenuOpen(!isFabMenuOpen)}
            className="flex items-center justify-center rounded-2xl bg-rose-500 p-4 text-sm text-white shadow-lg focus:outline-none"
          >
            {selectedCategory} {/* You could also show an icon if needed */}
          </button>
          {isFabMenuOpen && (
            <div className="absolute bottom-16 right-0 z-10 h-72 w-56 space-y-2 overflow-scroll rounded-lg bg-white p-3 shadow-lg">
              {menuData.categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => {
                    setSelectedCategory(category.name);
                    setFabMenuOpen(false);
                  }}
                  className={`flex w-full items-center gap-2 rounded-sm px-4 py-2 text-sm font-medium ${
                    selectedCategory === category.name
                      ? 'bg-rose-500 text-white hover:bg-pink-800'
                      : 'text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <span>{category.icon}</span>
                  <span>{category.name}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
