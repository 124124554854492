import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  return (
    <div className="relative md:flex md:flex-row md:items-center md:justify-around md:align-middle">
      <div className="flex flex-col justify-center p-12 align-middle text-white max-sm:p-12">
        {/* Main Content */}
        <main>
          <Outlet />{' '}
          {/* This renders the child routes (page-specific content) */}
        </main>

        <div className="mt-4 text-center">Powered by Quforia</div>
      </div>
    </div>
  );
};

export default Layout;
