import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { TableContext } from '../context/TableContext';
import { AuthContext } from '../context/AuthContext';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { toast } from 'react-toastify';
import { useFooterTabs } from '../context/FooterTabsContext';

interface Table {
  tableId: string;
  minimumPeopleAllowed: number;
  maximumPeopleAllowed: number;
}

const AvailableTables: React.FC = () => {
  const { availableTables, setAvailableTables, isRefreshing, lastRefreshTime } =
    useContext(TableContext)!;
  const { user } = useContext(AuthContext)!;
  const listRef = useRef<HTMLUListElement>(null);
  const [modalloading, setModalLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTable, setSelectedTable] = useState<Table | null>(null);

  const { setFooterTabs } = useFooterTabs();

  useEffect(() => {
    console.log('Reservations: useEffect');
    setFooterTabs([
      { label: 'Reservations', path: '/reservations' },
      { label: 'Check-in', path: '/checkIns' },
      { label: 'Add table', path: '/newTable' },
      { label: 'Available Tables', path: '/availableTables' },
    ]);
    console.log('Reservations: useEffect');
  }, [setFooterTabs]);

  const deleteTable = async (tableId: string) => {
    setModalLoading(true);
    try {
      const response = await fetch(
        `${API_GATEWAY_ENDPOINT}/deleteTable/${tableId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            role: 'Restaurant',
            Authorization: 'Bearer ' + user?.authToken,
          },
        },
      );

      if (response.ok) {
        setAvailableTables(
          availableTables.filter((table) => table.tableId !== tableId),
        );
        toast.success('Table successfully removed');
      } else {
        throw new Error('Failed to delete table.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error: Table not removed');
    } finally {
      setModalLoading(false);
      closeModal();
    }
  };

  const openModal = (table: Table) => {
    setSelectedTable(table);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTable(null);
    setIsModalOpen(false);
  };

  const renderModal = () => {
    if (!isModalOpen || !selectedTable) return null;

    return ReactDOM.createPortal(
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="mx-auto w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
          <h3 className="mb-4 text-xl font-semibold">Confirm Deletion</h3>
          <p className="mb-4">Are you sure you want to delete this table?</p>
          <div className="flex justify-end space-x-4">
            <button
              className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
              onClick={closeModal}
            >
              No
            </button>
            <button
              className="rounded-md bg-red-500 px-4 py-2 text-white"
              onClick={() => deleteTable(selectedTable.tableId)}
              disabled={modalloading}
            >
              {modalloading ? 'Deleting...' : 'Yes'}
            </button>
          </div>
        </div>
      </div>,
      document.body, // Render the modal at the root level
    );
  };

  return (
    <div className="relative h-full rounded-2xl bg-white/10 p-6 shadow-xl backdrop-blur-md">
      <div className="mb-4 flex items-center justify-between">
        <div className="text-2xl font-bold max-sm:text-lg">Tables</div>
        {/* Loading or Last Refresh Indicator */}
        {isRefreshing ? (
          <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
        ) : (
          <div className="text-sm text-gray-300 max-md:text-xs">
            Updated: {lastRefreshTime}
          </div>
        )}
      </div>

      <div className="max-h-48 overflow-auto max-md:text-sm">
        <ul ref={listRef} className="space-y-2">
          {availableTables.map((table, index) => (
            <li
              key={table.tableId}
              className={`flex items-center justify-between rounded-md p-2
                ${index % 2 === 0 ? 'bg-white/10' : 'bg-white/5'} text-white
              `}
            >
              <span>
                <strong>Table</strong> - Seats {table.minimumPeopleAllowed} to{' '}
                {table.maximumPeopleAllowed}
              </span>
              <button
                className="text-pink-300 underline transition hover:text-pink-400"
                onClick={() => openModal(table)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      {renderModal()}
    </div>
  );
};

export default AvailableTables;
