import React, { useEffect, useContext, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useFooterTabs } from '../context/FooterTabsContext';
import { RestaurantContext } from '../context/RestaurantContext';

const FootersTabLayout: React.FC = () => {
  const { footerTabs } = useFooterTabs();
  const location = useLocation();
  const restaurantContext = useContext(RestaurantContext);
  const restaurantInfoRef = useRef(restaurantContext?.restaurant);

  console.log('FooterTabs Wihtins: ', footerTabs);

  const { setFooterTabs } = useFooterTabs();

  useEffect(() => {
    restaurantInfoRef.current = restaurantContext?.restaurant;
    if (
      restaurantContext?.restaurant &&
      !restaurantContext.restaurant.isWaitingEnabled
    ) {
      setFooterTabs([
        { label: 'Reservations', path: '/reservations' },
        { label: 'Check-in', path: '/checkIns' },
      ]);
    } else {
      console.log('Reservations: useEffect');
      setFooterTabs([
        { label: 'Reservations', path: '/reservations' },
        { label: 'Check-in', path: '/checkIns' },
        { label: 'Add table', path: '/newTable' },
        { label: 'Available Tables', path: '/availableTables' },
      ]);
      console.log('Reservations: useEffect');
    }
  }, [setFooterTabs, restaurantContext?.restaurant]);

  return (
    <div className="flex min-h-screen flex-col">
      {/* Main Content */}

      <Outlet />
      {footerTabs.length > 0 && (
        <footer className="sticky bottom-0 border-t border-gray-300 bg-white/80 shadow-lg backdrop-blur-md">
          <div className="mx-auto flex w-full items-center justify-between">
            {footerTabs.map((tab, index) => {
              const isActive = location.pathname === tab.path;

              return (
                <button
                  key={tab.label}
                  onClick={
                    tab.onClick
                      ? tab.onClick
                      : () => (window.location.href = tab.path)
                  }
                  className={`flex h-[100px] w-full flex-col items-center justify-center text-sm font-medium transition-all duration-300 focus:outline-none md:w-[100px] ${
                    isActive
                      ? 'bg-blue-100 text-blue-500'
                      : 'text-gray-800 hover:bg-gray-100 hover:text-blue-500'
                  }`}
                  style={{
                    border: '2px solid',
                    borderColor: 'gray',
                    flexGrow: 1, // Allow buttons to grow based on available space
                    marginLeft: index !== 0 ? '-1px' : '0', // Overlap borders slightly
                  }}
                >
                  <span
                    className="flex size-full flex-col items-center justify-center text-center leading-tight"
                    style={{
                      padding: '10%', // Add internal spacing
                      boxSizing: 'border-box', // Ensure padding fits within the button
                      overflowWrap: 'break-word', // Break long words
                      wordBreak: 'break-word', // Ensure proper breaking of words
                    }}
                  >
                    {tab.label
                      .split(' ')
                      .reduce(
                        (acc, word, idx, arr) => {
                          if (idx < Math.ceil(arr.length / 2)) {
                            acc[0] = acc[0] ? `${acc[0]} ${word}` : word;
                          } else {
                            acc[1] = acc[1] ? `${acc[1]} ${word}` : word;
                          }
                          return acc;
                        },
                        ['', ''],
                      )
                      .map((line, i) => (
                        <span key={i} className="block">
                          {line}
                        </span>
                      ))}
                  </span>
                </button>
              );
            })}
          </div>
        </footer>
      )}
    </div>
  );
};

export default FootersTabLayout;
