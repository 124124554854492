import React from 'react';
import { useEffect, useRef, useState } from 'react';

const Landing: React.FC = () => {
  const [activeSection, setActiveSection] = useState('home');
  const homeRef = useRef<HTMLDivElement>(null);
  const restaurantsRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-50% 0px -50% 0px', // Adjust to trigger when half the section is visible
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    if (homeRef.current) observer.observe(homeRef.current);
    if (restaurantsRef.current) observer.observe(restaurantsRef.current);
    if (eventsRef.current) observer.observe(eventsRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="overflow-hidden bg-gray-50 font-sans text-gray-800">
      <LandingHeader activeSection={activeSection} />
      <div ref={homeRef} id="Home" className="pt-32">
        <HeroSection />
      </div>
      <div ref={restaurantsRef} id="Restaurants" className="pt-16">
        <RestaurantsSection />
      </div>
      <div ref={eventsRef} id="Events" className="pt-16">
        <EventsSection />
      </div>
      <div id="Onboard" className="pt-16">
        <OnboardSection />
      </div>
    </div>
  );
};

const LandingHeader = ({ activeSection }: { activeSection: string }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 50); // Adjust the scroll threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed z-50 rounded-3xl border-2 border-slate-600 bg-slate-400 transition-all duration-300 ${
        isSticky ? 'top-8 w-3/5 bg-white shadow-glow-lg' : 'top-20 w-8/12 '
      }`}
      style={{ left: isSticky ? '20vw' : '16.667vw' }}
    >
      <div className="container mx-auto flex items-center justify-between p-4">
        <div className="flex items-center">
          <span
            className={`text-xl font-bold transition-shadow duration-300 ${isSticky ? 'shadow-sm shadow-blue-300' : ''}`}
          >
            Quforia
          </span>
        </div>
        <button disabled={activeSection === 'Restaurants'}>Restaurants</button>
        <button disabled={activeSection === 'Events'}>Events</button>
        <button disabled={activeSection === 'Onboard'}>Onboard Today!</button>
      </div>
    </header>
  );
};

const EventsSection = () => {
  return <></>;
};

const RestaurantsSection = () => {
  return <></>;
};

const OnboardSection = () => {
  return <></>;
};

const HeroSection = () => {
  return (
    <>
      <div className="flex h-screen w-screen flex-col items-center justify-center text-center">
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <span className="hero-brand-title text-8xl">Quforia</span>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <span className="hero-brand-title w-1/4 text-4xl">
          One Stop Solution for all of your restaurant mananagement needs
        </span>
      </div>
    </>
  );
};

export default Landing;
