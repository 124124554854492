import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  AreaChart,
  Area,
} from 'recharts';
import dayjs from 'dayjs';
import { API_GATEWAY_ENDPOINT } from '../utilities/Constants';
import { AuthContext } from '../context/AuthContext';

/** 1) Define the data interfaces */
interface ReservationRecord {
  record_date: string;
  total_reservation: number;
  total_checked_in_reservation: number;
  total_expired_reservation: number;
  total_canceled_reservation: number;
  small_group_count: number;
  large_group_count: number;
}

interface ValetRecord {
  record_date: string;
  total_valet_parking: number;
}

/** 2) CSV parsers */
function parseReservationCsv(csvText: string): ReservationRecord[] {
  const lines = csvText.trim().split('\n');
  if (lines.length < 2) return [];

  const headers = lines[0]
    .split(',')
    .map((h) => h.trim().toLowerCase().replace(/ /g, '_'));
  const rows: ReservationRecord[] = [];

  for (let i = 1; i < lines.length; i++) {
    const columns = lines[i].split(',').map((c) => c.trim());

    const rowObj: ReservationRecord = {
      record_date: columns[headers.indexOf('date')] || '',
      total_reservation: Number(
        columns[headers.indexOf('totalreservation')] || 0,
      ),
      total_checked_in_reservation: Number(
        columns[headers.indexOf('totalcheckedinreservation')] || 0,
      ),
      total_expired_reservation: Number(
        columns[headers.indexOf('totalexpiredreservation')] || 0,
      ),
      total_canceled_reservation: Number(
        columns[headers.indexOf('totalcanceledreservation')] || 0,
      ),
      small_group_count: Number(
        columns[headers.indexOf('smallgroupcount')] || 0,
      ),
      large_group_count: Number(
        columns[headers.indexOf('largegroupcount')] || 0,
      ),
    };

    rows.push(rowObj);
  }
  return rows;
}

function parseValetCsv(csvText: string): ValetRecord[] {
  const lines = csvText.trim().split('\n');
  if (lines.length < 2) return [];

  const headers = lines[0]
    .split(',')
    .map((h) => h.trim().toLowerCase().replace(/ /g, '_'));
  const rows: ValetRecord[] = [];

  for (let i = 1; i < lines.length; i++) {
    const columns = lines[i].split(',').map((c) => c.trim());

    const rowObj: ValetRecord = {
      record_date: columns[headers.indexOf('date')] || '',
      total_valet_parking: Number(
        columns[headers.indexOf('totalvaletparking')] || 0,
      ),
    };

    rows.push(rowObj);
  }
  return rows;
}

const Analytics: React.FC = () => {
  const [resRawData, setResRawData] = useState<ReservationRecord[]>([]);
  const [valetRawData, setValetRawData] = useState<ValetRecord[]>([]);
  const [loading, setLoading] = useState(true);

  const [timeFrame, setTimeFrame] = useState<'week' | 'month'>('week');
  const [offset, setOffset] = useState(0);

  const { user } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const apiResAnalytics = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchRestaurantAnalyticsData/Reservation`,
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + user?.authToken,
              role: 'Restaurant',
            },
          },
        );
        if (!apiResAnalytics.ok) {
          throw new Error('Failed to get presigned URL for Reservations');
        }
        const presignedUrlReservations = await apiResAnalytics.text();

        const apiValetAnalytics = await fetch(
          `${API_GATEWAY_ENDPOINT}/fetchRestaurantAnalyticsData/ValetParking`,
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + user?.authToken,
              role: 'Restaurant',
            },
          },
        );
        if (!apiValetAnalytics.ok) {
          throw new Error('Failed to get presigned URL for Valet');
        }
        const presignedUrlValet = await apiValetAnalytics.text();

        let csvRes;
        try {
          csvRes = await fetch(presignedUrlReservations);
        } catch (err) {
          console.error('Error fetching Reservations CSV: ', err);
        }
        let csvValet;
        try {
          csvValet = await fetch(presignedUrlValet);
        } catch (err) {
          console.error('Error fetching Valet CSV: ', err);
        }

        if (csvRes && csvRes.ok) {
          const csvTextRes = await csvRes.text();
          setResRawData(parseReservationCsv(csvTextRes));
        }

        if (csvValet && csvValet.ok) {
          const csvTextValet = await csvValet.text();
          setValetRawData(parseValetCsv(csvTextValet));
        }
      } catch (err) {
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  // Sort data by date
  const sortedResData = useMemo(() => {
    return [...resRawData].sort(
      (a, b) =>
        new Date(a.record_date).getTime() - new Date(b.record_date).getTime(),
    );
  }, [resRawData]);

  const sortedValetData = useMemo(() => {
    return [...valetRawData].sort(
      (a, b) =>
        new Date(a.record_date).getTime() - new Date(b.record_date).getTime(),
    );
  }, [valetRawData]);

  // Slice data by time frame (7-day or 30-day chunks)
  const resChartData = useMemo(() => {
    const chunkSize = timeFrame === 'week' ? 7 : 30;
    const totalLen = sortedResData.length;
    const startIndex = totalLen - chunkSize * (offset + 1);
    const endIndex = totalLen - chunkSize * offset;
    return sortedResData.slice(Math.max(startIndex, 0), Math.max(endIndex, 0));
  }, [sortedResData, timeFrame, offset]);

  const valetChartData = useMemo(() => {
    const chunkSize = timeFrame === 'week' ? 7 : 30;
    const totalLen = sortedValetData.length;
    const startIndex = totalLen - chunkSize * (offset + 1);
    const endIndex = totalLen - chunkSize * offset;
    return sortedValetData.slice(
      Math.max(startIndex, 0),
      Math.max(endIndex, 0),
    );
  }, [sortedValetData, timeFrame, offset]);

  // ----------- Data Definitions -----------
  // 1. Check-in Pie (Checked vs Not)
  const totalCheckedIn = resChartData.reduce(
    (sum, d) => sum + d.total_checked_in_reservation,
    0,
  );
  const totalReservations = resChartData.reduce(
    (sum, d) => sum + d.total_reservation,
    0,
  );
  const totalNotCheckedIn = totalReservations - totalCheckedIn;
  const checkInPieData = [
    { name: 'Checked In', value: totalCheckedIn },
    { name: 'Not Checked In', value: totalNotCheckedIn },
  ];

  // 2. Reservation Status Breakdown Pie
  const totalCanceled = resChartData.reduce(
    (sum, d) => sum + d.total_canceled_reservation,
    0,
  );
  const totalExpired = resChartData.reduce(
    (sum, d) => sum + d.total_expired_reservation,
    0,
  );
  const totalPendingCheckIn =
    totalReservations - totalCheckedIn - totalCanceled - totalExpired;
  const reservationStatusPieData = [
    { name: 'Checked In', value: totalCheckedIn },
    { name: 'Canceled', value: totalCanceled },
    { name: 'Expired', value: totalExpired },
    {
      name: 'Pending Check-in',
      value: totalPendingCheckIn > 0 ? totalPendingCheckIn : 0,
    },
  ];

  // 3. Total Reservations Bar data
  // (Already in resChartData as "total_reservation")

  // 4. Checked-in Reservations Line data
  const checkedInLineChartData = resChartData.map((item) => ({
    record_date: item.record_date,
    checkedInReservations: item.total_checked_in_reservation,
  }));

  // 6. Valet Parking Line data
  const valetParkingLineChartData = valetChartData.map((item) => ({
    record_date: item.record_date,
    valetParkings: item.total_valet_parking,
  }));

  // 7. Group Size Distribution Pie
  const totalSmallGroups = resChartData.reduce(
    (sum, d) => sum + d.small_group_count,
    0,
  );
  const totalLargeGroups = resChartData.reduce(
    (sum, d) => sum + d.large_group_count,
    0,
  );
  const groupSizePieData = [
    { name: 'Small Groups', value: totalSmallGroups },
    { name: 'Large Groups', value: totalLargeGroups },
  ];

  // 8. Total vs Checked-in Reservation Area
  const reservationAreaChartData = resChartData.map((item) => ({
    record_date: item.record_date,
    totalReservations: item.total_reservation,
    checkedInReservations: item.total_checked_in_reservation,
  }));

  // Additional: Group Size Trend (Small vs Large)
  const groupSizeBarChartData = resChartData.map((item) => ({
    record_date: item.record_date,
    smallGroups: item.small_group_count,
    largeGroups: item.large_group_count,
  }));

  // Helpers
  const noResDataThisChunk = resChartData.length === 0;
  const noValetDataThisChunk = valetChartData.length === 0;
  const noDataThisChunk = noResDataThisChunk && noValetDataThisChunk;

  const handlePrevChunk = () => setOffset((prev) => prev + 1);
  const handleNextChunk = () => offset > 0 && setOffset((prev) => prev - 1);

  const selectedDateRange = useMemo(() => {
    if (!resChartData.length) return null;
    const startDate = dayjs(resChartData[0].record_date).format('MMM D, YYYY');
    const endDate = dayjs(
      resChartData[resChartData.length - 1].record_date,
    ).format('MMM D, YYYY');
    return `${startDate} - ${endDate}`;
  }, [resChartData]);

  return (
    <div className="min-h-screen w-full px-4 py-8 text-gray-800">
      <div className="mx-auto max-w-7xl">
        <div className="mb-6 flex flex-col items-center justify-between gap-4 md:flex-row">
          <h1 className="text-2xl font-bold text-white">
            Restaurant Analytics
          </h1>
          <div className="flex items-center gap-2">
            <button
              onClick={handlePrevChunk}
              className="rounded-md bg-blue-500 px-3 py-1 font-medium text-white hover:bg-blue-400 disabled:bg-gray-400 disabled:text-gray-200"
              title="View older data"
            >
              ←
            </button>
            <select
              value={timeFrame}
              onChange={(e) => {
                setTimeFrame(e.target.value as 'week' | 'month');
                setOffset(0);
              }}
              className="rounded-md border border-gray-300 bg-white px-3 py-1 text-gray-700 shadow-sm"
            >
              <option value="week">By Week</option>
              <option value="month">By Month</option>
            </select>
            <button
              onClick={handleNextChunk}
              className="rounded-md bg-blue-500 px-3 py-1 font-medium text-white hover:bg-blue-400 disabled:bg-gray-400 disabled:text-gray-200"
              disabled={offset === 0}
              title="View more recent data"
            >
              →
            </button>
          </div>
          {selectedDateRange && (
            <p className="text-sm text-white">
              Showing data from{' '}
              <span className="font-semibold">{selectedDateRange}</span>
            </p>
          )}
        </div>

        {loading && <p className="text-sm text-white">Loading data...</p>}
        {!loading && resRawData.length === 0 && valetRawData.length === 0 && (
          <p className="text-sm text-red-600">No data found at all.</p>
        )}

        {!loading && (resRawData.length > 0 || valetRawData.length > 0) && (
          <div className="mt-8 flex flex-col items-center gap-8">
            {noDataThisChunk ? (
              <p className="text-sm text-red-600">
                No data for this selected timeframe.
              </p>
            ) : (
              <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                {/* Chart 1: Checked-in vs. Not Checked-in Pie */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md">
                  <h2 className="mb-2 text-lg font-semibold">
                    Check-in Status
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={checkInPieData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        {/* Adjusted moderate contrast colors */}
                        <Cell fill="#27AE60" /> {/* Checked In */}
                        <Cell fill="#E74C3C" /> {/* Not Checked In */}
                      </Pie>
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F1F1F1',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 2: Reservation Status Breakdown Pie */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md">
                  <h2 className="mb-2 text-lg font-semibold">
                    Reservation Status Breakdown
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={reservationStatusPieData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        <Cell fill="#27AE60" /> {/* Checked In */}
                        <Cell fill="#C0392B" /> {/* Canceled */}
                        <Cell fill="#F39C12" /> {/* Expired */}
                        <Cell fill="#5DADE2" /> {/* Pending */}
                      </Pie>
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F1F1F1',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 3: Total Reservations Bar */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md md:col-span-2 lg:col-span-1">
                  <h2 className="mb-2 text-lg font-semibold">
                    Total Reservations (
                    {timeFrame === 'week' ? '7-Day' : '30-Day'} Window)
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={resChartData}
                      margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#CCC" />
                      <XAxis
                        dataKey="record_date"
                        tick={{ fill: '#333' }}
                        tickFormatter={(dateStr) =>
                          dayjs(dateStr).format('MMM D')
                        }
                      />
                      <YAxis tick={{ fill: '#333' }} />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                      <defs>
                        <linearGradient
                          id="totalResGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#2ECC71"
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor="#27AE60"
                            stopOpacity={0.8}
                          />
                        </linearGradient>
                      </defs>
                      <Bar
                        dataKey="total_reservation"
                        name="Total Reservations"
                        fill="url(#totalResGradient)"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 4: Checked-in Reservations Line */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md md:col-span-2 lg:col-span-1">
                  <h2 className="mb-2 text-lg font-semibold">
                    Checked-in Trend (
                    {timeFrame === 'week' ? '7-Day' : '30-Day'} Window)
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={checkedInLineChartData}
                      margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#CCC" />
                      <XAxis
                        dataKey="record_date"
                        tick={{ fill: '#333' }}
                        tickFormatter={(dateStr) =>
                          dayjs(dateStr).format('MMM D')
                        }
                      />
                      <YAxis tick={{ fill: '#333' }} />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="checkedInReservations"
                        stroke="#27AE60"
                        strokeWidth={2}
                        name="Checked-in Reservations"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 6: Valet Parking Line */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md md:col-span-2 lg:col-span-1">
                  <h2 className="mb-2 text-lg font-semibold">
                    Valet Parking Trend (
                    {timeFrame === 'week' ? '7-Day' : '30-Day'} Window)
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={valetParkingLineChartData}
                      margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#CCC" />
                      <XAxis
                        dataKey="record_date"
                        tick={{ fill: '#333' }}
                        tickFormatter={(dateStr) =>
                          dayjs(dateStr).format('MMM D')
                        }
                      />
                      <YAxis tick={{ fill: '#333' }} />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="valetParkings"
                        stroke="#FFD54F"
                        strokeWidth={2}
                        name="Valet Parkings"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 7: Group Size Distribution Pie */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md">
                  <h2 className="mb-2 text-lg font-semibold">
                    Group Size Distribution
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={groupSizePieData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                      >
                        <Cell fill="#8E44AD" /> {/* Small Groups */}
                        <Cell fill="#2980B9" /> {/* Large Groups */}
                      </Pie>
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F1F1F1',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart 8: Total vs Checked-in Reservations Area */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md md:col-span-2 lg:col-span-1">
                  <h2 className="mb-2 text-lg font-semibold">
                    Total vs Checked-in Reservations Trend
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                      data={reservationAreaChartData}
                      margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#CCC" />
                      <XAxis
                        dataKey="record_date"
                        tick={{ fill: '#333' }}
                        tickFormatter={(dateStr) =>
                          dayjs(dateStr).format('MMM D')
                        }
                      />
                      <YAxis tick={{ fill: '#333' }} />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="totalReservations"
                        stroke="#3498DB"
                        fill="#3498DB"
                        fillOpacity={0.7}
                        name="Total Reservations"
                      />
                      <Area
                        type="monotone"
                        dataKey="checkedInReservations"
                        stroke="#f7a201"
                        fill="#f7a201"
                        fillOpacity={1}
                        name="Checked-in Reservations"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>

                {/* Chart: Small vs Large Groups Trend */}
                <div className="flex flex-col rounded-md bg-white p-4 shadow-md md:col-span-2 lg:col-span-1">
                  <h2 className="mb-2 text-lg font-semibold">
                    Group Size Trend (
                    {timeFrame === 'week' ? '7-Day' : '30-Day'} Window)
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={groupSizeBarChartData}
                      margin={{ top: 20, right: 20, left: 0, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#CCC" />
                      <XAxis
                        dataKey="record_date"
                        tick={{ fill: '#333' }}
                        tickFormatter={(dateStr) =>
                          dayjs(dateStr).format('MMM D')
                        }
                      />
                      <YAxis tick={{ fill: '#333' }} />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#F9F9F9',
                          border: '1px solid #ccc',
                        }}
                        labelStyle={{ color: '#333' }}
                        itemStyle={{ color: '#555' }}
                      />
                      <Legend />
                      <defs>
                        <linearGradient
                          id="smallGroupGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#8E44AD"
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor="#9B59B6"
                            stopOpacity={0.8}
                          />
                        </linearGradient>
                        <linearGradient
                          id="largeGroupGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#2980B9"
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor="#3498DB"
                            stopOpacity={0.8}
                          />
                        </linearGradient>
                      </defs>
                      <Bar
                        dataKey="smallGroups"
                        name="Small Groups"
                        fill="url(#smallGroupGradient)"
                      />
                      <Bar
                        dataKey="largeGroups"
                        name="Large Groups"
                        fill="url(#largeGroupGradient)"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
