import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface User {
  username?: string;
  authToken?: string;
  userType: string; // This represents the main role (e.g., Restaurant, Customer)
  subRole?: string; // SubRole for Restaurant users
  phone?: string; // SubRole for Restaurant users
}

export interface RolesClass {
  roles: string;
  subRole?: string;
  subUserName: string;
}

export enum SubRole {
  Admin = 'Admin',
  Reception = 'Reception',
  Valet = 'Valet',
}

interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | undefined;
  login: (username: string, authToken: string, userType: string) => boolean;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined,
);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Utility to check token validity
  const isTokenValid = (token: string): boolean => {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decoded.exp ? decoded.exp > currentTime : false;
    } catch {
      return false;
    }
  };

  // Login Function
  const login = (
    username: string,
    authToken: string,
    userType: string,
  ): boolean => {
    if (isTokenValid(authToken)) {
      const decoded = jwtDecode<RolesClass & JwtPayload>(authToken);
      // Extract subRole only if the userType is 'Restaurant'
      const subRole =
        userType === 'Restaurant' ? decoded.subRole || 'Admin' : undefined;

      localStorage.setItem('authToken', authToken); // Store token in localStorage
      setIsAuthenticated(true);
      setUser({
        phone: decoded.subUserName,
        username,
        authToken,
        userType,
        subRole,
      });
      return true;
    }
    return false;
  };

  // Logout Function
  const logout = () => {
    localStorage.removeItem('authToken'); // Remove token from storage
    setIsAuthenticated(false);
    setUser(undefined);
  };

  // Restore session on app load
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && isTokenValid(token)) {
      // If token is valid, restore user session
      const decoded = jwtDecode<RolesClass & User & JwtPayload>(token);

      // Extract subRole only if the userType is 'Restaurant'
      const userType = decoded.roles;
      const subRole =
        userType === 'Restaurant' ? decoded.subRole || 'Admin' : undefined;

      setUser({
        phone: decoded.subUserName,
        username: decoded.sub,
        authToken: token,
        userType,
        subRole,
      });
      setIsAuthenticated(true);
    } else if (token) {
      // If token is invalid, remove it
      localStorage.removeItem('authToken');
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
