import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

import ValetParkingPanel from '../components/ValetParking';
import { RestaurantHeader } from '../components/Hamburger/RestaurantHeader';
import CheckIn from '../components/CheckIn';
import Reservations from '../components/Reservations';
import NewTableForm from '../components/NewTableForm';
import AvailableTables from '../components/AvailableTables';
import Analytics from './Analytics';

const DesktopDashboard: React.FC = () => {
  const context = useContext(AuthContext);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-900 text-white">
      <RestaurantHeader />

      {/* Main Content */}
      <div className="p-4 md:p-8">
        {/* Desktop Layout */}
        <div className="hidden space-x-6 md:flex">
          <div className="flex w-2/3 flex-col space-y-6">
            <CheckIn />
            <Reservations />
            <Analytics />
          </div>
          <div className="flex w-1/3 flex-col space-y-6">
            <NewTableForm />
            <AvailableTables />
            {context?.user?.subRole === 'Admin' && <ValetParkingPanel />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopDashboard;
