import React from 'react';
import { LoopedBackground } from '../components/LoopedBackground/LoopedBackground';
import LinthImage from '../assets/Linth.png';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import UserProfileForm from '../components/Profile/UserProfileForm';
import RestaurantProfileForm from '../components/Profile/Restaurant/RestaurantProfileForm';

const Profile: React.FC = () => {
  const { user } = useContext(AuthContext)!;

  return (
    <div className="md:flex md:flex-row md:items-center md:justify-around md:align-middle">
      <div>
        <LoopedBackground />
        <div className="flex flex-col justify-center p-24 align-middle text-white max-sm:p-12">
          {user?.userType === 'Customer' ? (
            <UserProfileForm />
          ) : (
            <RestaurantProfileForm />
          )}
        </div>
      </div>
      <div className="max-md:hidden">
        <img src={LinthImage} alt="decorative" style={{ height: '80vh' }} />
      </div>
    </div>
  );
};

export default Profile;
