import React from 'react';

const LoadingSplashScreen: React.FC = () => {
  return (
    <div className="flex size-full items-center justify-around align-middle">
      <div className="size-6 animate-spin rounded-full border-4 border-white border-t-blue-500"></div>
    </div>
  );
};
export default LoadingSplashScreen;
