import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import LoadingSplashScreen from '../screens/LoadingSplashScreen';

interface AuthenticatedRouteProps {
  children: JSX.Element;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  const { isAuthenticated, isLoading } = authContext;

  return isLoading ? (
    <LoadingSplashScreen />
  ) : isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
};
