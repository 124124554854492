// src/Routes.tsx
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CustomerPrivateRoute } from './CustomerPrivateRoute';
import Profile from '../screens/Profile';
import Landing from '../screens/Landing';
import QRScanner from '../screens/QRScanner';
import UserReservations from '../components/Profile/UserReservations';
import Login from '../screens/Login';
import VerifyOTP from '../screens/VerifyOTP';
import Layout from '../layout/Layout';
import LoginOptions from '../screens/LoginOptions';
import CustomerDashboard from '../screens/CustomerDashboard';
import CustomerMenuLayout from '../layout/CustomerMenuLayout';
import CustomerReservationForm from '../screens/CustomerReservationForm';
import { RestaurantPrivateRoute } from './RestaurantPrivateRoute';
import CheckInsListing from '../screens/CheckInsListing';
import ValetListing from '../screens/ValetListing';
import ReservationsListing from '../screens/ReservationsListing';
import NewTableListing from '../screens/NewTableListing';
import { DashboardRoute } from './DashboardRoute';
import AvailableTablesListing from '../screens/AvailableTablesListing';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import AnalyticsListing from '../screens/AnalyticsListing';
import StaffListing from '../screens/StaffListing';
import Menu from '../screens/Menu';
import FootersTableLayout from '../layout/FooterTabsLayout';
import { FooterTabsProvider } from '../context/FooterTabsContext';
import TimeSlotForm from '../components/Profile/Restaurant/TimeSlotForm';
import OnlineTablesEditForm from '../components/Profile/Restaurant/OnlineTablesEditForm';

const AppRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/verify_otp" element={<VerifyOTP />} />
          <Route path="/login_options" element={<LoginOptions />} />
        </Route>

        {/* Routes with shared Layout */}
        <Route element={<CustomerMenuLayout />}>
          <Route
            path="/customer_dashboard"
            element={
              <CustomerPrivateRoute>
                <CustomerDashboard />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="/user_reservations"
            element={
              <CustomerPrivateRoute>
                <UserReservations />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="/make_reservation"
            element={
              <CustomerPrivateRoute>
                <CustomerReservationForm />
              </CustomerPrivateRoute>
            }
          />
          {/*<Route path="/my-reservations" element={<CustomerReservations/>}/>*/}
          {/*<Route path="/my-valet" element={<Valet/>}/>*/}
        </Route>

        <Route path="/menu" element={<Menu />} />
        <Route path="/menu/:restaurantName" element={<Menu />} />

        {/*<Route*/}
        {/*    path="/dashboard"*/}
        {/*    element={<CustomerPrivateRoute>*/}
        {/*        <Merchant/>*/}
        {/*    </CustomerPrivateRoute>}*/}
        {/*/>*/}

        {/*<Route*/}
        {/*    path="/landing"*/}
        {/*    element={<CustomerPrivateRoute>*/}
        {/*        <Landing/>*/}
        {/*    </CustomerPrivateRoute>}*/}
        {/*/>*/}

        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <Login />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <AuthenticatedRoute>
              <Profile />
            </AuthenticatedRoute>
          }
        />

        <Route element={<FootersTableLayout />}>
          <Route
            path="/newTable"
            element={
              <RestaurantPrivateRoute>
                <NewTableListing />
              </RestaurantPrivateRoute>
            }
          />
        </Route>

        <Route
          path="/edit_online_timeslots"
          element={
            <RestaurantPrivateRoute>
              <TimeSlotForm />
            </RestaurantPrivateRoute>
          }
        />

        <Route
          path="/edit_online_tables"
          element={
            <RestaurantPrivateRoute>
              <OnlineTablesEditForm />
            </RestaurantPrivateRoute>
          }
        />

        <Route element={<FootersTableLayout />}>
          <Route
            path="/availableTables"
            element={
              <RestaurantPrivateRoute>
                <AvailableTablesListing />
              </RestaurantPrivateRoute>
            }
          />
        </Route>

        <Route path="/home" element={<Landing />} />

        <Route element={<FootersTableLayout />}>
          <Route
            path="/reservations"
            element={
              <RestaurantPrivateRoute>
                <ReservationsListing />
              </RestaurantPrivateRoute>
            }
          />
        </Route>

        <Route
          path="/valet"
          element={
            <FooterTabsProvider>
              <RestaurantPrivateRoute>
                <ValetListing />
              </RestaurantPrivateRoute>
            </FooterTabsProvider>
          }
        />

        <Route element={<FootersTableLayout />}>
          <Route
            path="/checkIns"
            element={
              <RestaurantPrivateRoute>
                <CheckInsListing />
              </RestaurantPrivateRoute>
            }
          />
        </Route>

        <Route
          path="/restaurant_dashboard"
          element={
            <RestaurantPrivateRoute>
              <DashboardRoute />
            </RestaurantPrivateRoute>
          }
        />

        <Route
          path="/analytics"
          element={
            <RestaurantPrivateRoute>
              <AnalyticsListing />
            </RestaurantPrivateRoute>
          }
        />

        <Route
          path="/staff"
          element={
            <RestaurantPrivateRoute>
              <StaffListing />
            </RestaurantPrivateRoute>
          }
        />

        <Route path="/scanner" element={<QRScanner />} />
        {/* Catch-all for 404 */}
        <Route path="*" element={<Navigate to={'/login'} />} />
      </Routes>
      <ToastContainer position="top-center" />
    </>
  );
};

export default AppRoutes;
